import React, { useEffect, useState } from 'react';

const InputNumeroEntero = (props) => {
    const { label, tieneSpanFinal, labelSpanFinal, onDiferenciaChange, valorBase, onValorChange, valorInicial, index } = props;
    const [value, setValue] = useState(valorInicial ?? 0);
    const [valorDiferencia, setValorDiferencia] = useState(0);

    const handleKeyDown = (event) => {
        if (event.key === '-' || event.key === 'e' || event.key === '.') {
            event.preventDefault();
        }
    };

    const handleChange = (event) => {
        const valorIngresado = event.target.value;
        if (valorIngresado === '' || /^\d+$/.test(valorIngresado)) {
            setValue(valorIngresado);
            if (valorBase) {
                setValorDiferencia(valorIngresado - valorBase);
            }
        }
    };

    const handleBlur = (event) => {
        const valorIngresado = (parseInt(event.target.value) ? parseInt(event.target.value) : 0);
        if (valorBase) {
            onDiferenciaChange(valorDiferencia);
        }
        if (index >= 0) {
            onValorChange(valorIngresado, index);
        } else {
            onValorChange(valorIngresado);
        }
    }

    useEffect(() => {
        if (valorBase) {
            setValorDiferencia((value - valorBase))
        }
    }, [valorBase]);

    return (
        <div>
            <label className="label text-sm font-medium text-gray-700">{label}</label>
            <span className="input-group w-full md:w-full">
                <input
                    type="number"
                    style={{ height: '40px' }}
                    value={value}
                    placeholder="Solo números positivos"
                    className="input input-bordered w-full"
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {tieneSpanFinal && <span>{labelSpanFinal}</span>}
            </span>

            <label className="label font-medium ">
                {!valorDiferencia ? (
                    <span className="label-text-alt "></span>
                ) : valorDiferencia > 0 ? (
                    <span className="label-text-alt text-accent">
                        Diferencia de {valorDiferencia} Litros
                    </span>
                ) : (
                    <span className="label-text-alt text-error">
                        Diferencia de {valorDiferencia} Litros
                    </span>
                )}
            </label>
        </div>
    );
};

InputNumeroEntero.defaultProps = {
    tieneSpanFinal: true
};

export default InputNumeroEntero;