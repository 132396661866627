import React from 'react';
import { useState, useEffect } from 'react';
const AlertTypes = {
    success: 'bg-green-600',
    error: 'bg-red-600',
    warning: 'bg-orange-600',
    info: 'bg-blue-600'
};

const AlertIconClass = {
    success: 'fa fa-check-square',
    error: 'fa fa-times-rectangle',
    warning: 'fa fa-warning',
    info: 'fa fa-info-circle'
};
const AlertPing = (props) => {
    const { message, type } = props;
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        setIsVisible(false);
        setTimeout(() => {
            setIsVisible(true);
        }, 10);
    }, [type, message]);

    return (
        <div className={`alert alert-${type}  ${isVisible ? 'opacity-0 animate-fade-in' : ''} `}>
            <i className={`${AlertIconClass[type]}`}></i>
            <span> {message}</span>
            <span className="relative flex h-3 w-3">
                <span className={`animate-ping absolute inline-flex h-full w-full rounded-full ${AlertTypes[type]} opacity-75`}></span>
                <span className={`relative inline-flex rounded-full h-3 w-3  ${AlertTypes[type]} `}></span>
            </span>
        </div>
    );
};

export default AlertPing;