import React from 'react'
import AbstractAdminVer from '../utils/AbstractAdminVer'

function VerProductores() {
  return (
    <div className="section h-full mx-2 my-5 md:w-10/12">
      
      <AbstractAdminVer
        objectName={{
          plural: "Productores",
          singular: "productor",
          formal: "productores",
          informal:"Productor"
        }}
        itemName={"nombre_corto"}
        query={"productoresempresadetailed"}
        createQuery={"productores"}
        updateQuery={"productores"}
        configPath={"utils/configFiles/config.json"}
      ></AbstractAdminVer>{" "}
    </div>
  )
}

export default VerProductores