import React, { useEffect, useState, useRef } from "react";
import useAuth from "../hooks/useAuth";
import {
  makeSafeGetRequest,
  makeSafePutRequest,
} from "./utils/Requests";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import { ToastContainer } from "react-toastify";
import { validatePlacaUpdated } from "./utils/CheckPlaca";
import SelectorEmpresa from "./utils/SelectorEmpresa";
import AlertPing from "./AlertPing";
import InputNumeroEntero from "./utils/InputNumeroEntero";
function EditVentas() {
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState();
  const [clientesEmpresa, setClientesEmpresa] = useState();
  const [selAcopio, setSelAcopio] = useState();

  const [ventas, setVentas] = useState();
  const [selVenta, setSelVenta] = useState();
  const [ventaEdit, setVentaEdit] = useState();
  const [placaIsValid, setPlacaIsValid] = useState(true);
  const selectorVenta = useRef(null);


  //Obtiene la lista de clientes de la empresa
  const getClientesEmpresa = async () => {
    try {
      const response = await makeSafeGetRequest(
        "clientesempresa/" + idEmpresa,
        auth.accessToken
      );
      if (response) {
        setClientesEmpresa(response?.data);
      }
    } catch (error) {
      console.log("error al obtener los clientes:" + error);
    }
  };


  //Obtiene la lista de ventas de un acopio seleccionado.
  const getVentas = async () => {
    try {
      const response = await makeSafeGetRequest(
        "totalventasacopiohoy/" + selAcopio,
        auth.accessToken
      );
      if (response && response.data) {
        setVentas(response.data);
      }
    } catch (error) {
      console.log("Error al obtener las ventas" + error);
    }
  };
  //Postea la información actualizada de la venta
  const updateVenta = async () => {
    const notify = notifyPendingNotify();
    if (placaIsValid) {
      try {
        const response = await makeSafePutRequest(
          "updateventa/" + ventaEdit?.id,
          ventaEdit,
          auth.accessToken
        );
        if (response && response.status === 200) {
          setSelAcopio(null);
          popNotification(notify, "success", "Venta actualizada correctamente");
          forgetVentas();
        } else {
          popNotification(notify, "error", "No se pudo actualizar la venta");
        }
      } catch (error) {
        popNotification(notify, "error", "Error al procesar la petición");
      }
    } else {
      popNotification(notify, "error", "Debe digitar una placa válida")
    }
  };
  //borra las ventas obtenidas y la venta seleccionada
  const forgetVentas = () => {
    if (selectorVenta.current) {
      selectorVenta.current.value = "";
    }
    setSelVenta();
    setVentaEdit();
  };  //Cambio cuando se obtiene una venta
  const onSelectedVenta = async (e) => {
    setSelVenta([]);
    setVentaEdit([]);
    const venta = ventas.find((venta) => venta.id === parseInt(e.target.value));
    if (venta) {
      setSelVenta(JSON.parse(JSON.stringify(venta)));
      setVentaEdit(JSON.parse(JSON.stringify(venta)));
      await getClientesEmpresa();
    }
  };
  //CAMBIOS AL SELECCIONAR UN CLIENTE ESPECIFICO
  const onSelectedCliente = (e) => {
    let selCliente = clientesEmpresa.find(
      (cliente) => cliente.id === parseInt(e.target.value)
    );
    let ventaAEditar = { ...ventaEdit };
    ventaAEditar.cliente = selCliente;
    setVentaEdit(ventaAEditar);
  };
  //Valida si la placa es válida
  const valPlaca = (placa) => {
    setPlacaIsValid(validatePlacaUpdated(placa))
  };
  //cambios en la placa del vehiculo de la venta
  const onChangePlaca = (e) => {
    valPlaca(e.target.value)
    const placa = e.target.value.toUpperCase();
    let ventaAEditar = { ...ventaEdit };
    ventaAEditar.placa_vehiculo = placa;
    setVentaEdit(ventaAEditar);
  };
  //Cambios en las observaciones de la venta
  const onChangeObsVenta = (e) => {
    let ventaAEditar = { ...ventaEdit };
    ventaAEditar.observaciones = e.target.value;
    setVentaEdit(ventaAEditar);
  };
  const onChangeVentaNovedad = (e) => {
    let vEdit = { ...ventaEdit };
    vEdit.venta_novedad = e
    setVentaEdit(vEdit);
  };

  //Cambios en la cantidad del compartimento
  const onChangeCantidad = (valor, index) => {
    let ventaAEditar = {
      ...ventaEdit,
      compartimentos: [...ventaEdit.compartimentos],
    };
    //encuentra el indice compartimento
    if (index !== -1) {
      ventaAEditar.compartimentos[index].cantidad_leche = parseInt(
        valor
      );
      setVentaEdit(ventaAEditar);
      setCantidadVenta();
    }
  };
  //Cuando cambia la cantidad en los compartimientos, este metodo ajusta la cantidad de la venta
  const setCantidadVenta = () => {
    const compartimentos = ventaEdit?.compartimentos;
    const suma = compartimentos.reduce(
      (total, comp) => total + comp.cantidad_leche,
      0
    );
    const ventaAEditar = getEditableVenta();
    ventaAEditar.cantidad_leche = suma;
    setVentaEdit(ventaAEditar);
  };
  //Cambios en la Temoperatura del compartimento
  const onChangeTemperatura = (valor, index) => {
    let ventaAEditar = {
      ...ventaEdit,
      compartimentos: [...ventaEdit.compartimentos],
    };
    //encuentra el indice compartimento

    if (index !== -1) {
      ventaAEditar.compartimentos[index].temperatura = parseFloat(
        valor
      );
      setVentaEdit(ventaAEditar);

    }
  };
  const getEditableVenta = () => {
    return {
      ...ventaEdit,
      compartimentos: [...ventaEdit.compartimentos],
    };
  };
  //Cambios en las observaciones del compartimento
  const onChangeObservaciones = (e, index) => {
    let ventaAEditar = {
      ...ventaEdit,
      compartimentos: [...ventaEdit.compartimentos],
    };
    //encuentra el indice compartimento

    if (index !== -1) {
      ventaAEditar.compartimentos[index].observaciones = e.target.value;
      setVentaEdit(ventaAEditar);
    } 
  };
  //Cambio en acopios selecciolnados
  useEffect(() => {
    forgetVentas();
    if (selAcopio) {
      getVentas();
    } else {
      setVentaEdit([])
    }
  }, [selAcopio]);

  return (
    <div className="section w-2/3 h-full">
      <div className="max-w-full p-4">
        <div className="card w-full bg-white shadow-2xl rounded-lg">
          <div className="card-title border-b border-gray-300 p-3 rounded-t-lg">
            <i className="	fa fa-cart-arrow-down text-gray-600 mr-2"></i>
            <h2 className="text-lg font-semibold text-gray-800 text-center"> Edición de Ventas</h2>
          </div>
          <div className="p-4">
            <div className="grid grid-cols-1 my-3">
              <SelectorEmpresa
                onChangeSet={setSelAcopio}
                profundidad={"acopio"}
                valorSeleccion={selAcopio}
                setIdEmpresaPadre={setIdEmpresa}
              >
              </SelectorEmpresa>
              {ventas && selAcopio ? (
                <>
                  <label className="label">Ventas: </label>
                  <select
                    ref={selectorVenta}
                    className="select select-bordered mb-2"
                    onChange={(e) => {
                      onSelectedVenta(e);
                    }}
                  >
                    <option key={"-1"} value={"-1"}>
                      Seleccione una venta
                    </option>
                    {ventas?.map((venta) => (
                      <option key={venta.id} value={venta.id}>
                        {venta?.cliente?.nombre +
                          " - " +
                          venta.cantidad_leche +
                          " litros"}
                      </option>
                    ))}
                  </select>
                  {(ventas.length == 0 && selAcopio) && (
                    <AlertPing
                      message="No hay ventas en el acopio seleccionado"
                      type="warning"
                    />
                  )}
                </>

              ) : (
                <div className="mb-2">
                  {idEmpresa &&
                    (
                      <AlertPing
                        message="Seleccione el acopio"
                        type="info"
                      />
                    )
                  }
                </div>

              )}
              {selVenta && ventaEdit.cliente ? (
                <div className="text-left">
                  <div className="divider mb-0"></div>
                  <div className="card-title border-b border-gray-300 rounded-t-lg p-3 pt-0">
                    <h4 className="text-lg font-semibold text-gray-800 text-center"> Venta encontrada</h4>
                  </div>
                  <div
                    className="grid md:grid-cols-2 p-2"
                  >
                    <p>
                      <span className="font-bold">Fecha: </span>
                      {selVenta?.fecha}
                    </p>
                    <p>
                      <span className="font-bold">Hora: </span>
                      {selVenta?.hora?.split(".")[0]}
                    </p>
                    <p>
                      <span className="font-bold">Cliente: </span>
                      {selVenta?.cliente?.nombre}
                    </p>
                    <p>
                      <span className="font-bold">Cantidad: </span>
                      {selVenta?.cantidad_leche} litros
                    </p>
                  </div>
                  <div className="divider my-0"></div>
                  <div className="card-title border-b border-gray-300 rounded-t-lg p-2 pt-0">
                    <h4 className="text-lg font-semibold text-gray-800 text-center"> Distribución en los compartimientos</h4>
                  </div>

                  {selVenta?.compartimentos ? (
                    <React.Fragment>
                      {selVenta.compartimentos?.map((comp) => (
                        <div
                          key={comp.id}
                          className="grid md:grid-cols-2 p-2"
                        >
                          <p>
                            <span className="font-bold">Compartimento N°: </span>
                            {comp.numero_compartimento}
                          </p>
                          <p>
                            <span className="font-bold">Cantidad: </span>
                            {comp.cantidad_leche} litros
                          </p>
                        </div>
                      ))}
                    </React.Fragment>
                  ) : (
                    <p className="text-lg text-warning font-semibold">
                      La venta no tiene compartimentos
                    </p>
                  )}
                  <div className="grid grid-cols-1">
                    <div className="divider m-0"></div>
                    <div className="card-title border-b border-gray-300 rounded-t-lg p-3">
                      <i className="fa fa-edit text-gray-600 mr-2"></i>
                      <h2 className="text-lg font-semibold text-gray-800 text-center"> Editar venta</h2>
                    </div>

                    <label className="label text-sm font-medium text-gray-700">Cliente:</label>
                    <select
                      className="select select-bordered"
                      onChange={(e) => onSelectedCliente(e)}
                      value={ventaEdit?.cliente.id || "-1"}
                    >
                      <option value="-1">Seleccione un cliente</option>
                      {clientesEmpresa?.map((cliente) => (
                        <option key={cliente.id} value={cliente.id}>
                          {cliente.nombre}
                        </option>
                      ))}
                    </select>
                    <label className="label text-sm font-medium text-gray-700">Placas del vehículo:</label>
                    <input
                      type="text"
                      className="input input-bordered"
                      value={ventaEdit.placa_vehiculo}
                      onChange={(e) => {
                        onChangePlaca(e);
                      }}
                    ></input>
                    <label className="text-error">
                      {placaIsValid ? (
                        <></>
                      ) : (
                        <>La placa debe estar en formato ABC123</>
                      )}
                    </label>
                    <label className="label text-sm font-medium text-gray-700">Obs. venta:</label>

                    <textarea
                      className="textarea textarea-bordered"
                      value={ventaEdit.observaciones}
                      onChange={(e) => {
                        onChangeObsVenta(e);
                      }}
                    ></textarea>

                    <div className="flex justify-start form-group">
                      <label className="label text-sm font-medium text-gray-700">Venta con novedad:</label>
                      <label className="label cursor-pointer">
                        <span className="label-text mr-1">No </span>
                        <input
                          type={"checkbox"}
                          className="toggle toggle-accent"
                          checked={ventaEdit?.venta_novedad}
                          onChange={
                            (e) => onChangeVentaNovedad(e.target.checked)
                          }
                        ></input>
                        <span className="label-text ml-1"> Si</span>
                      </label>
                    </div>
                    {ventaEdit?.compartimentos ? (
                      <React.Fragment>
                        {ventaEdit?.compartimentos.map((compartimento, index) => (
                          <div
                            className="grid grid-cols-1"
                            key={compartimento?.id}
                          >
                            <div className="divider mb-1"></div>
                            <label className="font-semibold mx-1  ">
                              Compartimento N°{" "}
                              {compartimento?.numero_compartimento}
                            </label>

                            <InputNumeroEntero
                              className="m-0"
                              label={"Cantidad de leche:"}
                              labelSpanFinal={"Litros"}
                              valorInicial={compartimento.cantidad_leche}
                              index={index}
                              onValorChange={onChangeCantidad}
                            />
                            <InputNumeroEntero
                              className="m-0"
                              label={"Temperatura:"}
                              labelSpanFinal={"°C"}
                              valorInicial={compartimento.temperatura}
                              index={index}
                              onValorChange={onChangeTemperatura}
                            />

                            <label className="label">Observaciones</label>
                            <textarea
                              className="textarea textarea-bordered"
                              placeholder="Observaciones"
                              defaultValue={compartimento.observaciones}
                              onChange={(e) => {
                                onChangeObservaciones(e, index);
                              }}
                            ></textarea>
                            <div className="divider"></div>
                          </div>
                        ))}
                      </React.Fragment>
                    ) : (
                      <div></div>
                    )}
                    <div className="flex justify-between items-center p-2">
                      <h4 className="text-xl">
                        <span className="font-semibold">
                          Total de la venta a editar:
                        </span>
                        {" "}{ventaEdit?.cantidad_leche + " litros"}
                      </h4>
                      <div className="card-actions">
                        <label className="btn btn-primary" htmlFor="modalConfirm">
                          Editar venta
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            {/*  Modal de confirmación */}
            <input
              type={"checkbox"}
              id="modalConfirm"
              className="modal-toggle"
            ></input>
            <div className="modal">
              <div className="modal-box">
                <h3 className="font-bold text-lg">Aviso</h3>
                <p>
                  ¿Seguro que desea actualizar la venta{" "}
                  {selVenta?.cliente?.nombre +
                    " - " +
                    selVenta?.cantidad_leche +
                    " litros"}{" "}
                  con la siguinte información?
                </p>
                <p>
                  <span className="font-bold">Cliente: </span>
                  {ventaEdit?.cliente?.nombre}
                </p>
                <p>
                  <span className="font-bold">Total: </span>
                  {ventaEdit?.cantidad_leche + " litros"}
                </p>
                <p>
                  <span className="font-bold">Observaciones: </span>
                  {ventaEdit?.observaciones}
                </p>
                <div className="modal-action">
                  <label
                    htmlFor="modalConfirm"
                    className="btn btn-accent"
                    onClick={() => updateVenta()}
                  >
                    Sí
                  </label>
                  <label htmlFor="modalConfirm" className="btn">
                    Aún no
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default EditVentas;
