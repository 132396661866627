import { useEffect, useRef, useState } from "react";
import axios from "../api/axios";
import SearchBar from "./SearchBar";
import {
  useDownloadExcel,
} from "react-export-table-to-excel";
import ReporteRecorridos from "./ReporteRecorridos";
import ReporteIngresosFecha from "./ReporteIngresosFecha";
import ReporteLitrosPorRutas from "./ReporteLitrosPorRutas";
import ReporteProductoresFechas from "./ReporteProductoresFechas";
import ReporteIngresosFechaEmpresa from "./ReporteIngresosFechaEmpresa";
import { makeGetRequest, makeSafeGetRequest } from "./utils/Requests";
import useAuth from "../hooks/useAuth";
import { getEmpresaAdmin } from "../api/RoleInfo";
import ReporteProductoresEmpresa from "./ReporteProductoresEmpresa";

function Reportes() {
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState();
  const [empresaNombre, setEmpresaNombre] = useState();
  const URL = process.env.REACT_APP_BACKEND_BASE_URL;
  const [productores, setProductores] = useState();
  const [searchedProds, setSearchedProds] = useState();
  const [recoleccionesProductor, setRecoleccionesProductor] = useState();
  const [selectedProductor, setSelectedProductor] = useState();
  const [selectedProductorNombre, setSelectedProductorNombre] = useState();
  const [fechaInicial, setFechaInicial] = useState();
  const [fechaFinal, setfechaFinal] = useState();
  //userefs tablas
  const tableRef = useRef(null);
  //Obtiene el id de la empresa
  const [openIndex, setOpenIndex] = useState(null);

  const getIdEmpresa = async () => {
    try {
      const response = await getEmpresaAdmin(auth);
      if (response) {
        setIdEmpresa(response.empresa?.id);
        setEmpresaNombre(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };
  //obtener la lista de todos los productores
  const getProductores = async () => {
    try {
      const myUrl = "productoresempresa/" + idEmpresa; //cambiar por id empresa
      const response = await makeSafeGetRequest(myUrl, auth.accessToken);
      if (response.status === 200) {
        setProductores(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Obtener las recolecciones de un productor en un rango de fechas
  const getRecoleccionesProductor = async () => {
    try {
      if (fechaFinal && fechaInicial) {
        const myUrl =
          "recoleccionesproductor?idproductor=" +
          selectedProductor +
          "&fechainicial=" +
          fechaInicial +
          "&fechafinal=" +
          fechaFinal; //ajustar para que sea dinamico
        const response = await makeGetRequest(`${myUrl}`);
        if (response.status === 200) {
          setRecoleccionesProductor(response.data);
        }
      } 
    } catch (error) {
      console.log(error);
    }
  };
  //identifica un cambio en la fecha inicial y lo guarda en el state
  const onFechaInicialChange = (e) => {
    const newDate = e.target.value;
    setFechaInicial(newDate);
  };
  //identifica un cambio en la fecha y lo guarda en el state
  const onFechaFinalChange = (e) => {
    const newDate = e.target.value;
    setfechaFinal(newDate);
  };
  //on search
  const onsearch = (searchTerm) => {
    if (searchTerm === "" || searchTerm === undefined) {
      setSearchedProds();
    } else {
      const filteredItems = productores?.filter((productor) => {
        return productor.nombre_corto
          .toUpperCase()
          .includes(searchTerm.toUpperCase());
      });
      setSearchedProds(filteredItems.slice(0, 10));
    }
  };
  //pasos a realizar al seleccionar un item buscado
  const onSelectedItem = (e) => {
    setSelectedProductor(e.target.value);
    setSelectedProductorNombre(e.target.innerText);
    setSearchedProds([]);
  };
  //realiza la descarga del elemento table
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "recolecciones_" + selectedProductorNombre + "_" + fechaInicial + "_" + fechaFinal,
    sheet: "recolecciones",
  });

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  
  useEffect(() => {
    if (idEmpresa) {
      getProductores()
    }
  }, [idEmpresa])

  //Useeffect inicial
  useEffect(() => {
    getIdEmpresa();
  }, []);


  return (

    <div className="section md:w-2/3 w-full h-full">
      <div className="max-w-full p-4">
        <div className="card w-full bg-white shadow-2xl rounded-lg">
          <div className="card-title border-b border-gray-300 p-3 rounded-t-lg">
            <i className="fa fa-fax text-gray-600 mr-2"></i>
            <h2 className="text-lg font-semibold text-gray-800 text-center"> Reportes</h2>
          </div>
          <div className="p-4">
            <div className="p-3 bg-blue-100 text-gray-600  text-justify shadow-inner">
              <span className="text-md font-medium">Empresa: <span className="font-bold text-gray-900"> {empresaNombre}</span></span>
            </div>
            <div className="collapse mt-2">
              <input type={"checkbox"} checked={openIndex === 1} onChange={() => handleToggle(1)} />
              <div className="collapse-title btn">
                Recolecciones por productor en un rango de días
              </div>
              <div className="collapse-content">
                <div className="flex flex-col px-5 md:px-20">
                  <div className="dropdown dropdown-bottom w-full">
                    <label className="label">Seleccione un productor</label>
                    <label className="input-group w-full">
                      <input
                        type={"search"}
                        className="input input-bordered  "
                        onChange={(e) => onsearch(e.target.value)}
                      ></input>
                      <span>Buscar</span>
                    </label>
                    {searchedProds ? (
                      <ul
                        tabIndex={0}
                        className="dropdown-content menu p-2 shadow bg-base-100 w-52 "
                      >
                        {searchedProds.map((productor) => (
                          <li
                            className="text-left"
                            key={productor?.id}
                            value={productor?.id}
                            tabIndex={0}
                            onClick={(e) => {
                              onSelectedItem(e);
                            }}
                          >
                            {productor.nombre_corto}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <></>
                    )}
                    {selectedProductorNombre ? (
                      <div className="badge badge-lg my-1 py-1">
                        {selectedProductorNombre}
                        <span
                          onClick={() => {
                            setSelectedProductorNombre();
                            setSelectedProductor();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </span>
                      </div>
                    ) : (
                      <>Seleccione un productor</>
                    )}
                  </div>
                  <label htmlFor="fechainicial" className="label">
                    Fecha Inicial
                  </label>
                  <input
                    type={"date"}
                    className="input input-bordered"
                    id="fechainicial"
                    onChange={(e) => {
                      onFechaInicialChange(e);
                    }}
                  ></input>
                  <label htmlFor="fechafinal" className="label">
                    Fecha final
                  </label>
                  <input
                    type={"date"}
                    className="input input-bordered"
                    id="fechafinal"
                    onChange={(e) => {
                      onFechaFinalChange(e);
                    }}
                  ></input>
                  <button
                    className="btn btn-accent my-5"
                    onClick={(e) => getRecoleccionesProductor()}
                  >
                    Generar reporte
                  </button>
                  <div className="overflow-x-auto">
                    <table className="table table-compact" ref={tableRef}>
                      {recoleccionesProductor &&
                        recoleccionesProductor?.length > 0 &&
                        selectedProductor ? (
                        <>
                          <thead>
                            <tr>
                              <th colSpan={4}>
                                Productor:{" "}
                                {recoleccionesProductor[0]?.productor?.nombre_corto}
                              </th>
                              <th>
                                Estado:{" "}
                                {recoleccionesProductor[0].productor?.activo ===
                                  true ? (
                                  <>Activo</>
                                ) : (
                                  <>Inactivo</>
                                )}
                              </th>
                            </tr>
                            <tr>
                              <th></th>
                              <th>Fecha</th>
                              <th>Cantidad</th>
                              <th>Observaciones</th>
                              <th>Ruta</th>
                            </tr>
                          </thead>
                          <tbody>
                            {recoleccionesProductor?.map((reco, index) => (
                              <tr key={reco.id}>
                                <th>{index + 1}</th>
                                <td>{reco.fecha}</td>
                                <td>{reco.cantidad_leche}</td>
                                <td>{reco.observaciones.substring(0, 30)}</td>
                                <td>{reco.ruta?.nombre}</td>
                              </tr>
                            ))}
                            {/* <tr colSpan={2}>
                        <td></td>
                      </tr> */}
                            <tr>
                              <th colSpan={2}>Total</th>
                              <td colSpan={3}>
                                {recoleccionesProductor.reduce(
                                  (c, reco) => c + reco.cantidad_leche,
                                  0
                                ) + " litros"}
                              </td>
                            </tr>
                          </tbody>
                        </>
                      ) : (
                        <tbody>
                          <tr colSpan={2}>
                            <td>
                              No hay recolecciones en esa fecha o rango para ese
                              productor
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                  {recoleccionesProductor &&
                    recoleccionesProductor?.length > 0 &&
                    selectedProductor ? (
                    <button className="btn w-40" onClick={onDownload}>
                      Exportar excel
                    </button>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
            <div className="collapse">
              <input type={"checkbox"} checked={openIndex === 2} onChange={() => handleToggle(2)} />
              <div className="collapse-title btn">
                Recolecciones detalladas por ruta en un día especifico
              </div>
              <div className="collapse-content">
                <ReporteRecorridos empresa={idEmpresa}></ReporteRecorridos>
              </div>
            </div>
            <div className="collapse">
              <input type={"checkbox"} checked={openIndex === 3} onChange={() => handleToggle(3)} />
              <div className="collapse-title btn">
                Totales ingresados en un rango de días en una ruta
              </div>
              <div className="collapse-content">
                <ReporteIngresosFecha empresa={idEmpresa}></ReporteIngresosFecha>
              </div>
            </div>
            <div className="collapse">
              <input type={"checkbox"} checked={openIndex === 4} onChange={() => handleToggle(4)}></input>
              <div className="collapse-title btn">
                Totales ingresados en un rango de días en la Empresa
              </div>
              <div className="collapse-content">
                <ReporteIngresosFechaEmpresa></ReporteIngresosFechaEmpresa>
              </div>
            </div>

            <div className="collapse">
              <input type={"checkbox"} checked={openIndex === 5} onChange={() => handleToggle(5)}></input>
              <div className="collapse-title btn">
                Litros recibidos por rutas - día o rango
              </div>
              <div className="collapse-content">
                <ReporteLitrosPorRutas></ReporteLitrosPorRutas>
              </div>
            </div>

            <div className="collapse">
              <input type={"checkbox"} checked={openIndex === 6} onChange={() => handleToggle(6)}></input>
              <div className="collapse-title btn">Recolecciones de todos los productores</div>
              <div className="collapse-content">
                <ReporteProductoresEmpresa empresa={idEmpresa} nombre={empresaNombre}></ReporteProductoresEmpresa>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}
export default Reportes;
