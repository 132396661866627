import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { getOperarioInfo } from "../api/RoleInfo";
import useAuth from "../hooks/useAuth";
import Prueba from "./Prueba";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import { makeGetRequest, makePostRequest, postPruebas } from "./utils/Requests";
import {
  dateToColombianZoneRq,
  timeToColombianZonerq,
} from "./utils/timezoneRequests";

function CreateDevolucion() {
  const [operarioInfo, setOperarioInfo] = useState();
  const [clientes, setClientes] = useState();
  const [selectedCliente, setSelectedCliente] = useState();
  const [selectedClienteNombre, setSelectedClienteNombre] = useState();
  const [cantidad, setCantidad] = useState(0);
  const [observaciones, setObservaciones] = useState("");
  const [prueba, setPrueba] = useState();
  const [esNovedad, setEsNovedad] = useState(false);
  const { auth } = useAuth();

  //obtiene la data de la sesion
  const getInfoOperario = async () => {
    const idUser = auth.id;
    const infoOperario = await getOperarioInfo(idUser);
    setOperarioInfo(infoOperario);
  };
  //obtiene la lista de los clientes de la empresa
  const getClientes = async () => {
    console.log("Entrando en la obtencion de clientes");
    try {
      const response = await makeGetRequest("clientesempresa/1");

      if (response?.status === 200) {
        setClientes(response.data);
      } else {
        setClientes(null);
      }
    } catch (error) {
      console.log("Error al obtener los clientes " + error);
    }
  };
  //postea la data de la prueba y la devolucion en la bd
  const postData = async () => {
    const notify = notifyPendingNotify();
    const validated = checkData();
    if (validated) {
      try {
        console.log("probando el boton");
        const idPrueba = await postPrueba(prueba);
        await postDevolucion(idPrueba);
        popNotification(notify, "success", "Proceso finalizado");
      } catch (error) {
        console.log("Error en la dataa: " + error);
        popNotification(notify, "error", "error al guardar");
      }
    } else {
      popNotification(notify, "error", "Complete los datos de la devolución");
    }
  };
  const checkData = () => {
    if (selectedCliente && cantidad >= 0) {
      return true;
    } else {
      return false;
    }
  };
  const clearData = () => {
    setCantidad(0);
    setObservaciones("");
    setEsNovedad(false);
  };
  //Postea la info de la devolucion
  const postDevolucion = async (idPrueba) => {
    const notify = notifyPendingNotify();
    const url = "devoluciones";
    const fechaHoy = await dateToColombianZoneRq();
    const horaHoy = timeToColombianZonerq();
    console.log(
      "cantidad:" +
        cantidad +
        "observaciones:" +
        observaciones +
        "hora" +
        horaHoy
    );
    const data = {
      data: {
        fecha: fechaHoy,
        hora: horaHoy,
        cantidad: cantidad,
        observaciones: observaciones,
        cliente: selectedCliente,
        operario: operarioInfo.idOperario,
        prueba: idPrueba,
        acopio: operarioInfo.idAcopio,
        devolucion_novedad:esNovedad
      },
    };
    const response = await makePostRequest(url, data);
    if (response?.status === 200) {
      popNotification(notify, "success", "Devolucion registrada correctamente");
      clearData();
    } else {
      popNotification(notify, "error", "Error al crear la devolución");
    }
  };
  //guarda la información de la prueba
  const postPrueba = async () => {
    const notify = notifyPendingNotify();
    try {
      const response = await postPruebas(prueba);
      console.log("Respuesta al postear la prueba" + response);
      if (response !== null) {
        popNotification(notify, "success", "Prueba agregada correctamente");
        console.log("Id de la prueba:" + response.data.data.id);
        return response.data.data.id;
      } else {
        popNotification(notify, "Error", "Error al agregar prueba");
      }
    } catch (error) {
      console.log("Error al postear a través del método:" + error);
    }
  };
  //obtiene el cambio en el componente reutilizable de la prueba
  function handlePruebaChange(pruebaE) {
    setPrueba({ ...pruebaE });
  }
  //Effect de inicio
  useEffect(() => {
    console.log("Inicio del render");
    getInfoOperario();
  }, []);
  //Effect al cargar los datos de entrada
  useEffect(() => {
    if (operarioInfo && operarioInfo !== null) {
      console.log(
        "Cambio en la info del operario:" + JSON.stringify(operarioInfo)
      );
      getClientes();
    }
  }, [operarioInfo]);
  useEffect(() => {
    if (clientes) {
      console.log("Lista de clientes de la empresa" + JSON.stringify(clientes));
    }
  }, [clientes]);
  return (
    <section className="h-full">
      <h1 className="text-4xl py-4">Crear Devolución</h1>
      <div className="flex-col pb-2 my-3">
        <label htmlFor="cliente" className="label">
          Seleccione un Cliente
        </label>
        <select
          className="select select-bordered w-full"
          onChange={(e) => {
            setSelectedCliente(e.target.value);
            setSelectedClienteNombre(e.target.selectedOptions[0].text);
          }}
        >
          <option key={"-1"} value={"-1"}>
            Seleccione un Cliente
          </option>
          {!clientes ? (
            <option key={""} value={""}>
              Cargando Clientes
            </option>
          ) : (
            clientes?.map((cliente) => (
              <option key={cliente.id} value={cliente.id}>
                {cliente.nombre}
              </option>
            ))
          )}
        </select>
        <label className="label">Cliente seleccionado: {selectedCliente}</label>

        <label htmlFor="observaciones" className="label">
          Motivo de la devolución
        </label>
        <textarea
          className="input input-bordered w-full"
          placeholder="Escriba algo"
          value={observaciones}
          onChange={(e) => setObservaciones(e.target.value)}
        ></textarea>
        <label htmlFor="cantidad" className="label">
          Cantidad de leche
        </label>
        <label className="input-group w-full">
          <input
            type={"number"}
            min={0}
            className="input input-bordered w-full "
            placeholder="Ingrese la cantidad recibida"
            value={cantidad}
            onChange={(e) => {
              setCantidad(e.target.value);
            }}
          ></input>
          <span>Litros</span>
        </label>
        <label className="label">Devolución con Novedad?</label>
        <div className="flex justify-around">
          <label className="label">No</label>
          <input
            type={"checkbox"}
            className="toggle toggle-accent"
            defaultChecked={esNovedad}
            onChange={() => setEsNovedad((prevCheck) => !prevCheck)}
          ></input>
          <label className="label">Sí</label>
        </div>
      </div>
      <Prueba onPruebaChange={handlePruebaChange} className="my-3 py-5" />
      <label className="btn btn-accent my-3" htmlFor="modalConfirm">
        Guardar Devolución
      </label>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <input type="checkbox" id="modalConfirm" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box relative">
          <label
            htmlFor="modalConfirm"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <h3 className="text-lg font-bold">Aviso</h3>
          <p className="py-4">
            Realmente desea guardar la siguiente devolución?
          </p>
          <p>
            <span className="font-bold">Cliente: </span>
            {selectedClienteNombre}
          </p>
          <p>
            <span className="font-bold">Cantidad: </span>
            {cantidad}
          </p>
          <p>
            <span className="font-bold">Observaciones: </span>
            {observaciones}
          </p>
          <p>
            <span className="font-bold">Devolución con Novedad: </span>
            {esNovedad ? <span className="text-error">Sí</span> : <span className="text-success">No</span>}
          </p>
          <div className="modal-action">
            <label
              htmlFor="modalConfirm"
              className="btn btn-accent"
              onClick={postData}
            >
              Registrar devolución
            </label>
            <label htmlFor="modalConfirm" className="btn">
              Aún no
            </label>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CreateDevolucion;
