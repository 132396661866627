import React from 'react'
import { useRef, useState, useEffect } from 'react'
import axios from './api/axios';
import useAuth from './hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const BASE_URL= process.env.REACT_APP_BACKEND_BASE_URL;
const LOGIN_URL = `${BASE_URL}auth/local`;
const GET_ROLE_URL = `${BASE_URL}rol`

function Login() {
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathName || "/"
    // refs
    const userRef = useRef();
    const errRef = useRef();
    // state
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    //set focus on userInput
    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd]);

    // Submit 
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // todo: IMPROVE THIS USING A SERVICE
            const response = await authenticate(user, pwd);
            const accessToken = response?.data?.jwt;
            const roles = await getRole(accessToken); 
            const userInfo= await getUserInfo(accessToken);
            const info= await 
            setAuth({  roles, accessToken, ...userInfo });
            saveAuth({  roles, accessToken , ...userInfo});
            setPwd('');
            setUser('');
            navigate(from, { replace: true })
            // setSuccess(true);
        } catch (error) {
            if (!error?.response) {
                setErrMsg('No hay Respuesta del Servidor');
            } else if (error.response?.status === 400) {
                setErrMsg('Usuario/Contraseña Invalido');
            } else if (error.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                console.log(error);
                setErrMsg('Fallo en Login');
            }
            errRef.current.focus();
        }
        //clear componentes AFTER LOGIN TRY

    };
    const authenticate = async (usr, pwd) => {
        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({
                    identifier: usr,
                    password: pwd
                }),
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            );
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    };
    const getRole = async (token) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            };
            const response = await axios.get(GET_ROLE_URL,
                config
            );
            return [response?.data?.name];
        } catch (error) {
            console.log(error);
            Promise.reject(error);
        }
    };
    const getUserInfo= async(token)=>{
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            };
            const response = await axios.get(BASE_URL+'users/me',
                config
            );
            const data= response.data;
            console.log(response.data, 'data del  usuario')
            return data;
        } catch (error) {
            console.log(error);
            Promise.reject(error);
        }
    };
    const saveAuth = async (authData) => {
        try {
            localStorage.setItem("auth", JSON.stringify(authData));
        } catch (error) {
            return Promise.reject(error);
        }
    };
    return (
        <>{
            (

                <section className='flex justify-center content-center pt-6 '>
                    <div className='card shadow-xl md:w-1/2 p-6'>

                        <h1 className='text-6xl p-5'>Login</h1>
                        <form onSubmit={handleSubmit}>
                            <div className='form-control'>
                                <label htmlFor='username'>Usuario</label>
                                <input className='input input-bordered'
                                    type="text"
                                    id="username"
                                    ref={userRef}
                                    autoComplete='off'
                                    onChange={(e) => setUser(e.target.value)}
                                    value={user}
                                    required
                                />
                            </div>
                            <div className='form-control'>
                                <label htmlFor='password'>Contraseña</label>
                                <input className='input input-bordered'
                                    type="password"
                                    id="password"
                                    onChange={(e) => setPwd(e.target.value)}
                                    value={pwd}
                                    required
                                />
                            </div>
                            <button className='btn'>Login</button>
                        </form>
                        <p ref={errRef}
                            className={errMsg ? "alert alert-error shadow-lg mt-5" : "offscreen"}
                            aria-live="assertive"> {errMsg}</p>
                    </div>
                </section>

            )}
        </>
    )
}

export default Login