import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import {
  dateToColombianZoneRq,
  timeToColombianZonerq,
} from "./utils/timezoneRequests";
import InputNumeroEntero from "./utils/InputNumeroEntero";

function OpenConsolidado() {
  const URL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { auth } = useAuth();
  const [consolidadoHoy, setConsolidadoHoy] = useState();
  const [consolidadoAnterior, setConsolidadoAnterior] = useState();
  const [cantidadAnterior, setCantidadAnterior] = useState(0);
  const [medicion, setMedicion] = useState(0);
  const [observaciones, setObservaciones] = useState("");
  const [diferencia, setDiferencia] = useState(0);
  const [fecha, setFecha]= useState();
  //states de novedades agregados el 11/11/2022
  const [medicionNovedad, setMedicionNovedad]=useState(0);
  const [diferenciaNovedad, setDiferenciaNovedad] =useState(0);
  const [cantAnteriorNovedad,setCantAnteriorNovedad]= useState(0);
  //STATES DE AUTORIZACION
  const [idOperario, setIdOperario] = useState();
  const [idAcopio, setIdAcopio] = useState();

  //Obtiene el id del operario al que pertenece el usuario logueado
  const getIdOperario = async () => {
    try {
      const idUser = auth.id;
      const url = URL + "operarioporusuario/" + idUser;
      const response = await axios.get(`${url}`);

      if (response?.status === 200) {
        setIdOperario(response?.data?.id);
        getIdAcopio(response.data);
      } 
    } catch (error) {
      console.log("Error al obtener el id del operario: " + error);
    }
  };
  //obtiene el id del acopio del operario
  const getIdAcopio = (dataOperario) => {
    const idAco = dataOperario.acopio.id;
    setIdAcopio(idAco);
  };

  //obtiene el dato del consolidado de hoy , si existe
  const getConsolidadoHoy = async () => {
    try {
      const myUrl = URL + "consolidadohoy/" + idAcopio;
      const response = await axios.get(`${myUrl}`);
      if (response.status === 200) {
        setConsolidadoHoy(response.data);
      } else {
        setConsolidadoHoy();
        await getConsolidadoAnterior();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getConsolidadoAnterior = async () => {
    try {
      const myUrl = URL + "consolidadoanterior/" + idAcopio;
      const response = await axios.get(`${myUrl}`);
      if (response.status === 200) {
        setConsolidadoAnterior(response.data);
        setCantidadAnterior(response.data.cantidad_final_dia);
        setCantAnteriorNovedad(response.data.novedad_final_dia)
      } else {
        setCantidadAnterior(0);
        setCantAnteriorNovedad(0)
      }
    } catch (error) {
      console.log(error);
    }
  };

  //guarda los datos del consolidado
  const postConsolidado = async () => {
    const notify = notifyPendingNotify();
    const myUrl = URL + "apertura-consolidados";
    const fechaHoy = await dateToColombianZoneRq();
    
    const horaHoy = timeToColombianZonerq();
    try {
      const response = await axios.post(myUrl, {
        data: {
          fecha: fechaHoy,
          hora: horaHoy,
          cantidad_dia_anterior: cantidadAnterior,
          cantidad_medida: medicion,
          diferencia: diferencia,
          novedad_dia_anterior: cantAnteriorNovedad,
          novedad_medida: medicionNovedad,
          diferencia_novedad: diferenciaNovedad,
          observaciones: observaciones,
          acopio: idAcopio,
        },
      });
      if (response.status === 200 && response != []) {
        popNotification(notify, "success", "Apertura de Consolidado exitosa");
        //intenta obtener nuevamente el consolidado anterior
        await getConsolidadoHoy();
      } else {
        popNotification(
          notify,
          "error",
          "Algo salió mal al abrir el consolidado.Intente de nuevo"
        );
      }
    } catch (error) {
      console.log(error);
      popNotification(notify, "error", "Error al abrir el consolidado");
    }
  };
  //obtiene la fecha del sistema
  const getFecha=async()=>{
    const fechaHoy= await dateToColombianZoneRq();
    setFecha(fechaHoy);
  }

  //effect para empezar
  useEffect(() => {
    getFecha();
    getIdOperario();
  }, []);
  //Effect al cambio de idAcopio
  useEffect(() => {
    if (idAcopio !== undefined && idOperario !== undefined) {
      getConsolidadoHoy();
    }
  }, [idAcopio, idOperario]);

  return (
    <section className="md:w-2/3 w-full">
      {!consolidadoHoy || consolidadoHoy?.length <= 0 ? (
        <div className="max-w-full p-4">
            {/* Card con ajuste de estilos */}
            <div className="card w-full bg-white shadow-2xl rounded-lg">
              {/* Card title con cambio sutil entre título y body */}
              <div className="card-title border-b border-gray-300 p-3 rounded-t-lg">
                <i className="fa fa-plus-square text-gray-600 mr-2"></i>
                <h2 className="text-lg font-semibold text-gray-800 text-center"> Abrir Consolidado de leche</h2>
              </div>
      
              <div className="p-4">
                <div className="p-3 bg-blue-100 text-gray-600  text-justify shadow-inner">
                  <span className="text-md font-medium">Leche restante del día anterior: <span className="font-bold text-gray-900"> {" " + cantidadAnterior} litros</span></span>
                </div>
                <div className="form-group">
                  <InputNumeroEntero 
                    label = {"Leche medida al empezar el día:"}
                    labelSpanFinal = {"Litros"}
                    valorBase = {cantidadAnterior}
                    onDiferenciaChange = {setDiferencia}
                    onValorChange = {setMedicion}
                  />
                  <div className="divider"></div>
                </div>
                <div className="p-3 bg-blue-100 text-gray-600  text-justify shadow-inner">
                  <span className="text-md font-medium">
                    Leche medida (con novedad) del día anterior: 
                    <span className="font-bold text-gray-900"> {" " + cantAnteriorNovedad} litros</span>
                  </span>
                </div>
                <div className="form-group">
                  <label className="label text-sm font-medium text-gray-700"> </label>
                  <InputNumeroEntero 
                    label = {"Leche medida (con novedad) al empezar el día:"}
                    labelSpanFinal = {"Litros"}
                    valorBase = {cantAnteriorNovedad}
                    onDiferenciaChange = {setDiferenciaNovedad}
                    onValorChange = {setMedicionNovedad}
                  />
                </div>
                <div className="divider"></div>
                <div className="form-group">
                  <label className="label text-sm font-medium text-gray-700">Observaciones </label>
                  <textarea
                    id="observaciones"
                    className="textarea textarea-bordered w-full md:w-full"
                    aria-placeholder="Observaciones"
                    onChange={(e) => setObservaciones(e.target.value)}
                  ></textarea>
                </div>
              </div>

              <footer className="card-footer border-t border-gray-300 p-1 rounded-t-lg">  
                <label htmlFor="modal-confirm" className="btn btn-wide">
                  Abrir consolidado
                </label>
              </footer>  
            </div>
        </div>
      ) : (
        <div className="px-10 grid grid-cols-1">
          <div className="alert alert-success my-5">
            El consolidado ya fue abierto. Puede verificar la información a
            continuación
          </div>
          <div className="card  shadow-xl">
            <div className="card-body">
              <h2 className="card-title">Detalles del Consolidado</h2>
              <p className="text-left">
                <span className="font-bold">Fecha:</span> {consolidadoHoy.fecha}{" "}
              </p>

              <p className="text-left">
                <span className="font-bold">Hora: </span>
                {consolidadoHoy.hora.split(".", [1])}
              </p>

              <p className="text-left">
                <span className="font-bold ">Cantidad del día anterior: </span>
                {consolidadoHoy.cantidad_dia_anterior + " litros"}{" "}
              </p>
              <p className="text-left">
                <span className="font-bold">Cantidad medida:</span>{" "}
                {consolidadoHoy.cantidad_medida + " litros"}{" "}
              </p>
              <p className="text-left">
                <span className="font-bold">Diferencia:</span>{" "}
                {consolidadoHoy.diferencia + " litros"}{" "}
              </p>
              <p className="text-left">
                <span className="font-bold ">Cantidad con Novedad del día anterior: </span>
                {consolidadoHoy.novedad_dia_anterior + " litros"}{" "}
              </p>
              <p className="text-left">
                <span className="font-bold">Cantidad con novedad medida:</span>{" "}
                {consolidadoHoy.novedad_medida + " litros"}{" "}
              </p>
              <p className="text-left">
                <span className="font-bold">Diferencia en novedad:</span>{" "}
                {consolidadoHoy.diferencia_novedad + " litros"}{" "}
              </p>
              <p className="text-left">
                <span className="font-bold">Observaciones:</span>{" "}
                {consolidadoHoy.observaciones}{" "}
              </p>
            </div>
          </div>
        </div>
      )}
      {/* Modal para confirmar la apertura del consolidado */}
      <input type={"checkbox"} className="modal-toggle" id="modal-confirm"></input>
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">
            Aviso
          </h3>
          <p className="py-4">
            Realmente desea realizar la apertura del Cosolidado con los siguientes datos?
          </p>
          <p><span className="font-bold">Fecha: </span>{fecha}</p>
          <p><span className="font-bold">Medición: </span>{medicion}</p>
          <p><span className="font-bold">Cantidad anterior: </span>{cantidadAnterior}</p>
          <p><span className="font-bold">Diferencia: </span>{diferencia}</p>
          <p><span className="font-bold">Medición (con Novedad): </span>{medicionNovedad}</p>
          <p><span className="font-bold">Cantidad anterior: </span>{cantAnteriorNovedad}</p>
          <p><span className="font-bold">Diferencia: </span>{diferenciaNovedad}</p>
          <p><span className="font-bold">Observaciones: </span>{observaciones}</p>
          <div className="modal-action">
            <label htmlFor="modal-confirm" className="btn" onClick={()=>postConsolidado()}>
              Sí
            </label>
            <label htmlFor="modal-confirm" className="btn">
              Aún No
            </label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>
  );
}

export default OpenConsolidado;
