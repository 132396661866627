import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import useAuth from "../hooks/useAuth";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import {
  makeSafeGetRequest,
  makeSafePostRequest,
  makeSafePutRequest,
} from "./utils/Requests";
import {
  dateToColombianZoneRq,
  timeToColombianZonerq,
} from "./utils/timezoneRequests";
import AlertPing from "./AlertPing";
import InputNumeroEntero from "./utils/InputNumeroEntero";
import SelectorEmpresa from './utils/SelectorEmpresa'

function EditIngresos() {
  const { auth } = useAuth();
  const [rutaNombre, setRutaNombre] = useState("");
  const [selRuta, setSelRuta] = useState();
  const [ingresoRuta, setIngresoRuta] = useState();
  const [cantRecorrido, setCantRecorrido] = useState(0);
  const [cantEdit, setCantEdit] = useState(0);
  const [cantNovedadEdit, setCantNovedadEdit] = useState(0);
  const [obsEdit, setObsEdit] = useState("");
  const [difEdit, setDifEdit] = useState(0);
  const [aceptadoEdit, setAceptadoEdit] = useState(true);

  const extraerValores = () => {
    if (ingresoRuta) {
      setCantEdit(ingresoRuta.cantidad_medida);
      setCantNovedadEdit(ingresoRuta.cantidad_rechazada);
      setDifEdit(ingresoRuta.diferencia);
      setAceptadoEdit(ingresoRuta.aceptado);
      setObsEdit(ingresoRuta.observaciones)
      getDiferencia();
      recalcularLitrosIngreso();
    }
  };

  //Obtiene la diferencia segíun el cambio en el nuevo valor
  const getDiferencia = () => {
    const dif = cantEdit + cantNovedadEdit - cantRecorrido;
    setDifEdit(dif);
  };
  //obtiene el ingreso del día de una ruta
  const getIngresoRuta = async (ruta) => {
    try {
      const response = await makeSafeGetRequest(
        "ingresoruta/" + ruta,
        auth.accessToken
      );
      if (response) {
        setIngresoRuta(response.data);
      } else {
        setIngresoRuta();
      }
    } catch (error) {
      console.log("error al obtener el ingreso de la ruta:" + error);
    }
  };
  //toma los litros del recorrido y los suma
  const recalcularLitrosIngreso = () => {
    if (ingresoRuta) {
      const cantidadRecalculada = ingresoRuta?.recorrido?.recolecciones.reduce(
        (acum, obj) => {
          return acum + obj.cantidad_leche;
        },
        0
      );
      setCantRecorrido(cantidadRecalculada);
    }
  };
  //Actualiza los datos del ingreso de la ruta seleccionada con los valores ingresados
  const updateIngreso = async (notify) => {
    try {
      const data = {
        data: {
          cantidad_leche: cantRecorrido,
          cantidad_medida: cantEdit,
          cantidad_rechazada: cantNovedadEdit,
          diferencia: difEdit,
          aceptado: aceptadoEdit,
          observaciones: obsEdit,
        },
      };
      const response = await makeSafePutRequest(
        "ingresos/" + ingresoRuta.id,
        data,
        auth.accessToken
      );
      if (response && response?.status === 200) {
        popNotification(notify, "success", "Ingreso actualizado correctamente");
        setSelRuta()
        setIngresoRuta();
      } else {
        popNotification(
          notify,
          "error",
          "No se pudo actualizar el ingreso. Verifique e intente de nuevo"
        );
      }
    } catch (error) {
      console.log(error);
      popNotification(notify, "error", "Error al actualizar el ingreso");
    }
  };
  //Guarda el historial (log) del ingreso a punto de actualizarse
  const postIngresoLog = async () => {
    const notify = notifyPendingNotify();
    const fechaHoy = await dateToColombianZoneRq();
    const horaHoy = await timeToColombianZonerq();
    try {
      const data = {
        data: {
          cantidad_leche_anterior: ingresoRuta.cantidad_leche,
          cantidad_medida_anterior: ingresoRuta.cantidad_medida,
          cantidad_novedad_anterior: ingresoRuta.cantidad_rechazada,
          diferencia_anterior: ingresoRuta.diferencia,
          aceptado_anterior: ingresoRuta.aceptado,
          obs_anterior: ingresoRuta.observaciones,
          cantidad_leche_actual: cantRecorrido,
          cantidad_medida_actual: cantEdit,
          cantidad_novedad_actual: cantNovedadEdit,
          diferencia_actual: difEdit,
          aceptado_actual: aceptadoEdit,
          fecha_actualizacion: fechaHoy,
          hora_actualizacion: horaHoy,
          obs_actual: obsEdit,
          ingreso: ingresoRuta.id
        },
      };
      const response = await makeSafePostRequest(
        "ingresos-logs",
        data,
        auth.accessToken
      );
      if (response.status === 200) {
        popNotification(notify, "success", "Log guardado correctamente.")
        return notify;
      } else {
        popNotification(notify, "error", "No se pudo guardar.Intente de nuevo.");
      }
    } catch (error) {
      popNotification(notify, "error", "Error al guardar.")
    }
  };
  const postData = async () => {
    const notify = await postIngresoLog();
    if (notify) {
      await updateIngreso(notify);
      setSelRuta(null);
    }
  }
  //cambio en ruta seleccionada
  useEffect(() => {
    if (selRuta) {
      getIngresoRuta(selRuta);
      recalcularLitrosIngreso();
    }else if(selRuta == "") {
      setIngresoRuta(null);
    }
  }, [selRuta]);

  //useeffect cant edit
  useEffect(() => {
    getDiferencia();
  }, [cantEdit, cantNovedadEdit, difEdit, aceptadoEdit]);

  useEffect(() => {
    if (ingresoRuta) {
      setDifEdit(0);
      extraerValores();
      getDiferencia();
      setRutaNombre(ingresoRuta?.recorrido?.ruta?.nombre);
    }
  }, [ingresoRuta]);

  return (
    <div className="section w-2/3 h-full">
      <div className="max-w-full p-4">
        <div className="card w-full bg-white shadow-2xl rounded-lg">
          <div className="card-title border-b border-gray-300 p-3 rounded-t-lg">
            <i className="	fa fa-cart-arrow-down text-gray-600 mr-2"></i>
            <h2 className="text-lg font-semibold text-gray-800 text-center"> Edición de Ingresos</h2>
          </div>
          <div className="p-4">
            <div className="grid grid-cols-1 my-3">
              <SelectorEmpresa
                onChangeSet={setSelRuta}
                profundidad={"ruta"}
                valorSeleccion = {selRuta}
              >
              </SelectorEmpresa>
            </div>
            <div className="grid grid-cols-1 my-3">
              {ingresoRuta && (
                <>
                  <div className="card-title border-b border-gray-300 p-3 rounded-t-lg">
                    <i className="fa fa-cart-arrow-down text-gray-600 mr-2"></i>
                    <h3 className="text-lg font-semibold text-gray-800 text-center"> Ingreso Encontrados</h3>
                  </div>
                  <div className="card-body text-left p-2">
                    <div
                      className="grid md:grid-cols-2"
                    >
                      <p>
                        <span className="font-bold">Fecha: </span>
                        {ingresoRuta?.fecha.split("T")[0]}
                      </p>
                      <p>
                        <span className="font-bold">Hora: </span>
                        {ingresoRuta?.hora.split(".")[0]}
                      </p>

                      <p className="line-through">
                        <span className="font-bold">
                          Cantidad registrada en Sistema:{" "}
                        </span>
                        {ingresoRuta?.cantidad_leche}
                      </p>

                      <p>
                        <span className="font-bold">Cantidad medida: </span>
                        {ingresoRuta?.cantidad_medida}
                      </p>
                      <p>
                        <span className="font-bold">Diferencia: </span>
                        {ingresoRuta?.diferencia >= 0 ? (
                          <span className="text-success">
                            {ingresoRuta.diferencia} litros
                          </span>
                        ) : (
                          <span className="text-error">
                            {ingresoRuta.diferencia} litros
                          </span>
                        )}
                      </p>
                    </div>

                    <div className="divider w-full m-0"></div>
                    {cantRecorrido ? (
                      <p>
                        <span className="font-bold">
                          -Cantidad recalculada sistema:{" "}
                        </span>
                        {" " + cantRecorrido + " litros"}
                      </p>
                    ) : (
                      <p>
                        <span className="font-bold">Cantidad Sistema: </span>
                        { }
                      </p>
                    )}

                    <InputNumeroEntero
                      className="m-0"
                      label={"Cantidad medida"}
                      labelSpanFinal={"Litros"}
                      onValorChange={setCantEdit}
                      valorInicial={ingresoRuta?.cantidad_medidanpm}
                    />
                    {difEdit >= 0 ? (
                      <label className="label text-success">
                        Diferencia de {difEdit} litros
                      </label>
                    ) : (
                      <label className="label text-error">
                        Diferencia de {difEdit} litros
                      </label>
                    )}
                    <div className="flex justify-start form-group">
                      <label className="label">Ingreso aceptado?</label>
                      <label className="label cursor-pointer">
                        <span className="label-text mr-1">No </span>
                        <input
                          type={"checkbox"}
                          className="toggle toggle-accent"
                          checked={aceptadoEdit}
                          onChange={() => setAceptadoEdit((prevCheck) => !prevCheck)}
                        ></input>
                        <span className="label-text ml-1"> Si</span>
                      </label>
                    </div>
                    {aceptadoEdit === false && (
                      <>
                        <InputNumeroEntero
                          className="m-0"
                          label={"Cantidad con novedad"}
                          labelSpanFinal={"Litros"}
                          onValorChange={setCantNovedadEdit}
                        />
                      </>
                    )}
                    <label className="label">Observaciones</label>
                    <textarea
                      className="textarea textarea-bordered"
                      value={obsEdit}
                      onChange={(e) => {
                        setObsEdit(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  <div className="card-actions justify-end">
                    <label className="btn btn-primary" htmlFor="editModal">
                      Editar Ingreso
                    </label>
                  </div>
                </>
              )}
              {(selRuta && !ingresoRuta) && (
                <AlertPing
                  message="No se encontro ingreso para la ruta seleccionada"
                  type="info"
                />
              )}
            </div>
            {/* Modal editar */}
            <input type={"checkbox"} id="editModal" className="modal-toggle"></input>
            <div className="modal">
              <div className="modal-box ">
                <h3 className="font-bold text-lg">Aviso</h3>
                <p>Realmente desea editar el siguiente ingreso?</p>
                <p>
                  <span className="font-bold">Ruta: </span>
                  {rutaNombre}
                </p>
                <p>
                  <span className="font-bold">Cantidad por sistema: </span>
                  {cantRecorrido + " litros"}
                </p>
                <p>
                  <span className="font-bold">Cantidad medida: </span>
                  {cantEdit + " litros"}
                </p>
                <p>
                  <span className="font-bold">Diferencia:</span>
                  {difEdit + " litros"}
                </p>
                <p>
                  <span className="font-bold">Observaciones: </span>
                  {obsEdit}
                </p>
                <div className="modal-action">
                  <label
                    className="btn btn-accent"
                    htmlFor="editModal"
                    onClick={() => {
                      postData();
                    }}
                  >
                    Sí
                  </label>
                  <label className="btn btn-active" htmlFor="editModal">
                    Aún No
                  </label>
                </div>
              </div>
            </div>
            <ToastContainer
              position="bottom-center"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditIngresos;
