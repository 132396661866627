import React, { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import { makeSafeGetRequest, makeSafePutRequest } from "./utils/Requests";
import { ToastContainer } from "react-toastify";
import SelectorEmpresa from "./utils/SelectorEmpresa";
import InputNumeroEntero from "./utils/InputNumeroEntero";
function EditAperturaConsolidado() {
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState();
  const [aperturaConsolidado, setAperturaConsolidado] = useState();
  const [consolidadoAnterior, setConsolidadoAnterior] = useState();
  const [selAcopio, setSelAcopio] = useState();
  const [cantidadMedida, setCantidadMedida] = useState(0);
  const [cantidadMedidaNovedad, setCantidadMedidaNovedad] = useState(0);
  const [diferencia, setDiferencia] = useState(0);
  const [diferenciaNovedad, setDiferenciaNovedad] = useState(0);
  const [observaciones, setObservaciones] = useState("");

  //obtiene la apertura de consolidado del dia y acopio seleccionado
  const getAperturaConsolidado = async () => {
    try {
      const response = await makeSafeGetRequest(
        "consolidadohoy/" + selAcopio,
        auth
      );
      if (response && response.status === 200) {
        setAperturaConsolidado(response.data);
      } else {
        setAperturaConsolidado();
      }
    } catch (error) {
      console.log("error al  obtener la apertura de consolidado: " + error);
    }
  };
  //obtiene el consolidado del día anterior
  const getConsolidadoAnterior = async () => {
    try {
      const response = await makeSafeGetRequest(
        "consolidadoanterior/" + selAcopio,
        auth
      );
      if (response && response.status === 200) {
        setConsolidadoAnterior(response.data);
        setCantidadMedida(response.data?.cantidad_final_dia || 0);
        setCantidadMedidaNovedad(response.data?.novedad_final_dia || 0);
      } else {
        setConsolidadoAnterior();
      }
    } catch (error) {
      console.log(error);
    }
  };
  //actualiza la apertura de consolidado
  const updateAperturaConsolidado = async () => {
    const notify = notifyPendingNotify();
    if (cantidadMedida >= 0 && cantidadMedidaNovedad >= 0) {
      try {
        const data = {
          data: {
            cantidad_dia_anterior: consolidadoAnterior?.cantidad_final_dia || 0,
            cantidad_medida: cantidadMedida,
            diferencia: diferencia,
            novedad_dia_anterior: consolidadoAnterior?.novedad_final_dia || 0,
            novedad_medida: cantidadMedidaNovedad,
            diferencia_novedad: diferenciaNovedad,
            observaciones: observaciones,
          },
        };
        const response = await makeSafePutRequest(
          "apertura-consolidados/" + aperturaConsolidado.id,
          data,
          auth
        );
        if (response && response.status === 200) {
          popNotification(
            notify,
            "success",
            "Apertura de consolidado actualizada correctamente"
          );
          clearObjects();
        } else {
          popNotification(
            notify,
            "error",
            "Error alactualizar la apertura de consolidado. Intente de nuevo"
          );
        }
      } catch (error) { }
    } else {
      popNotification(
        notify,
        "error",
        "No se pudo actualizar la apertura de consolidado. Verifique los datos y vuelva a intentarlo"
      );
    }
  };
  //Limpia los objetos al guardar una apertura de consolidado
  const clearObjects = () => {
    setAperturaConsolidado();
    setConsolidadoAnterior();
    setSelAcopio();
    setCantidadMedida(0);
    setCantidadMedidaNovedad(0);
    setDiferencia(0);
    setDiferenciaNovedad(0);
    setObservaciones("");
  };
  //Cambio en la cantidad medida
  const onCantidadMedidaChange = (e) => {
    setCantidadMedida(e.target.value);
    getDiferencia(e.target.value);
  };
  const onCantidadMedidaNovedadChange = (e) => {
    setCantidadMedidaNovedad(e.target.value);
    getDiferenciaNovedad(e.target.value);
  };
  //OBTIENE LA DIFERENCIA ENTRE CANTIDAD DEL DIA ANTERIOR Y CANTIDAD MEDIDA
  const getDiferencia = (cantidadMedida) => {
    const diferencia =
      cantidadMedida - consolidadoAnterior?.cantidad_final_dia || 0;

    setDiferencia(diferencia);
  };
  //obtiene la diferencia entre cantidad con novedad del dia anterior y cantidad medida con novedad
  const getDiferenciaNovedad = (cantidadMedidaNovedad) => {
    const diferencia =
      cantidadMedidaNovedad - consolidadoAnterior?.novedad_final_dia || 0;
    setDiferenciaNovedad(diferencia);
  };
  //useef|fect obtener apertura consolidado
  useEffect(() => {
    getAperturaConsolidado();
    getConsolidadoAnterior();
  }, [selAcopio]);
  useEffect(() => {
    if (aperturaConsolidado) {
      setDiferencia(0);
      setDiferenciaNovedad(0);
    }
  }, [aperturaConsolidado]);
  return (
    <div className="section w-2/3 h-full">
      <div className="max-w-full p-4">
        <div className="card w-full bg-white shadow-2xl rounded-lg">
          <div className="card-title border-b border-gray-300 p-3 rounded-t-lg">
            <i className="	fa fa-cart-arrow-down text-gray-600 mr-2"></i>
            <h2 className="text-lg font-semibold text-gray-800 text-center"> Editar Apertura de Consolidado</h2>
          </div>
          <div className="p-4">
            <div className="grid grid-cols-1 my-3">
              <SelectorEmpresa
                onChangeSet={setSelAcopio}
                profundidad={"acopio"}
                valorSeleccion={selAcopio}
                setIdEmpresaPadre={setIdEmpresa}
              />
              {aperturaConsolidado && Object.keys(aperturaConsolidado).length > 0 ? (
                <div className="card card-compact card-bordered">
                  <div className="card-body">
                    <h3 className="card-title">Apertura de consolidado encontrada</h3>
                    <div className="card-title border-b border-gray-300 py-3 rounded-t-lg">
                      <i className="fa fa-qrcode text-gray-600 mr-2"></i>
                      <h2 className="text-lg font-semibold text-gray-800 text-center"> Acopio {aperturaConsolidado?.acopio?.nombre}</h2>
                    </div>
                    <div className="overflow-x-auto">
                      <table className="table table-compact table-auto w-full">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Variable</th>
                            <th>Apertura</th>
                            <th>Cierre anterior</th>
                            <th> Variación</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th></th>
                            <th>
                              <span className="font-semibold">
                                Cantidad del día anterior:{" "}
                              </span>
                            </th>
                            <td>
                              {aperturaConsolidado?.cantidad_dia_anterior + " litros"}
                            </td>
                            <td>
                              {consolidadoAnterior?.cantidad_final_dia ||
                                0 + " litros"}
                            </td>
                            <td>
                              {consolidadoAnterior?.cantidad_final_dia -
                                aperturaConsolidado?.cantidad_dia_anterior || 0}
                              {" litros"}
                            </td>
                          </tr>
                          <tr>
                            <th></th>
                            <th className="font-semibold">
                              Cantidad Novedad día anterior
                            </th>
                            <td>
                              {aperturaConsolidado?.novedad_dia_anterior ||
                                0 + " litros"}
                            </td>
                            <td>
                              {consolidadoAnterior?.novedad_final_dia ||
                                0 + " litros"}
                            </td>
                            <td>
                              {consolidadoAnterior?.novedad_final_dia -
                                aperturaConsolidado?.novedad_dia_anterior || 0}
                              {" litros"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="divider"></div>
                    <div
                      className="grid md:grid-cols-2 p-2"
                    >
                      <p className="text-left">
                        <span className="font-semibold">
                          Cantidad del día anterior:{" "}
                        </span>
                        {aperturaConsolidado.cantidad_dia_anterior + " litros"}
                      </p>
                      <p className="text-left">
                        <span className="font-semibold">Cantidad medida: </span>
                        {aperturaConsolidado.cantidad_medida + " litros"}
                      </p>
                      <p className="text-left">
                        <span className="font-semibold">Diferencia: </span>
                        {aperturaConsolidado?.diferencia >= 0 ? (
                          <span className="text-success">
                            {aperturaConsolidado.diferencia + " litros"}
                          </span>
                        ) : (
                          <span className="text-error">
                            {aperturaConsolidado.diferencia + " litros"}
                          </span>
                        )}
                      </p>
                      <p className="text-left">
                        <span className="font-semibold">
                          Cantidad con novedad del día anterior:{" "}
                        </span>
                        {aperturaConsolidado.novedad_dia_anterior + " litros"}
                      </p>
                      <p className="text-left">
                        <span className="font-semibold">
                          Cantidad con novedad medida:{" "}
                        </span>
                        {aperturaConsolidado.novedad_medida + " litros"}
                      </p>
                      <p className="text-left">
                        <span className="font-semibold">Diferencia(novedad): </span>
                        {aperturaConsolidado.diferencia_novedad >= 0 ? (
                          <span className="text-success">
                            {aperturaConsolidado.diferencia_novedad + " litros"}
                          </span>
                        ) : (
                          <span className="text-error">
                            {aperturaConsolidado.diferencia_novedad + " litros"}
                          </span>
                        )}
                      </p>
                    </div>
                    <div className="divider"></div>
                    <h3 className="card-title">Editar apertura de consolidado</h3>
                    <InputNumeroEntero
                      label={"Cantidad medida:"}
                      labelSpanFinal={"Litros"}
                      valorBase={aperturaConsolidado?.cantidad_medida || 0}
                      onDiferenciaChange={setDiferencia}
                      onValorChange={setCantidadMedida}
                      valorInicial={aperturaConsolidado?.cantidad_medida}
                    />

                    <InputNumeroEntero
                      label={"Cantidad medida con novedad:"}
                      labelSpanFinal={"Litros"}
                      valorBase={consolidadoAnterior?.novedad_final_dia || 0}
                      onDiferenciaChange={setDiferenciaNovedad}
                      onValorChange={setCantidadMedidaNovedad}
                      valorInicial={aperturaConsolidado.novedad_medida}
                    />

                    <label className="label">Observaciones</label>
                    <textarea
                      className="textarea textarea-bordered"
                      onChange={(e) => setObservaciones(e.target.value)}
                    ></textarea>
                    <div className="card-actions">
                      <label className="btn btn-primary" htmlFor="modal-confirm">
                        Actualizar apertura de consolidado
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Modal guardar */}
      <input type="checkbox" id="modal-confirm" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box relative">
          <label
            htmlFor="modal-confirm"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <h3 className="text-lg font-bold">Aviso!</h3>
          <p className="py-4">
            ¿Realmente desea actualizar el siguiente consolidado?
          </p>
          <p className="">
            <span className="font-bold">Acopio: </span>
            {aperturaConsolidado?.acopio?.nombre}
          </p>
          <div className="modal-action">
            <label
              className="btn btn-accent"
              htmlFor="modal-confirm"
              onClick={() => updateAperturaConsolidado()}
            >
              Sí
            </label>
            <label className="btn " htmlFor="modal-confirm">
              Aún no
            </label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>

  );
}

export default EditAperturaConsolidado;
