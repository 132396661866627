import React, { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import { getEmpresaAdmin } from "../api/RoleInfo";
import {
  makeSafeGetRequest,
  makeSafePostRequest,
  makeSafePutRequest,
} from "./utils/Requests";
import { ToastContainer } from "react-toastify";
import { notifyPendingNotify, popNotification } from "./utils/Notify";

function EditPedidosProductos() {
  const { auth } = useAuth();
  const [role, setRole] = useState();
  const [empresasList, setEmpresasList] = useState();
  const [idEmpresa, setIdEmpresa] = useState();
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [acopios, setAcopios] = useState();
  const [selAcopio, setSelAcopio] = useState();
  const [rutas, setRutas] = useState();
  const [selRuta, setSelRuta] = useState();
  const [productosEmpresa, setProductosEmpresa] = useState();
  const [productores, setProductores] = useState();
  const [selectedProductor, setSelectedProductor] = useState();
  const [selectedProductorNombre, setSelectedProductorNombre] = useState();
  const [searchedProds, setSearchedProds] = useState();
  const [pedidos, setPedidos] = useState();
  const [selPedido, setPedido] = useState();
  const [selEditedPedido, setEditedPedido] = useState({
    estado_pedido: "-1",
  });

  const [editedProductoPedido, setEditedProductoPedido] = useState();
  const [addedProductosPedidos, setAddedProductosPedidos] = useState([]);
  const [addedProducto, setAddedProducto] = useState({});

  //determina el rol del usuario
  const determineRole = () => {
    console.log("determinando rol:" + auth.roles[0]);
    console.log(auth);
    setRole(auth.roles[0]);
  };
  //obtiene la empresa en caso de ser admin
  const getEmpresa = async () => {
    const response = await getEmpresaAdmin(auth);
    setIdEmpresa(response?.empresa?.id);
    setNombreEmpresa(response?.empresa.nombre);
  };
  //Obtiene la lista de empresas en caso de ser superadmin
  const getEmpresasList = async () => {
    try {
      const response = await makeSafeGetRequest("empresas", auth.accessToken);
      if (response && response.status === 200) {
        console.log("empresas listadas" + JSON.stringify(response.data.data));
        setEmpresasList(response?.data.data);
      }
    } catch (error) {
      console.log("error al obtener la lista de empresas" + error);
    }
  };
  //obtiene la lista de acopios de la empresa
  const getAcopiosEmpresa = async () => {
    try {
      const response = await makeSafeGetRequest(
        `acopiosempresa/${idEmpresa}`,
        auth.accessToken
      );
      if (response && response.status === 200) {
        setAcopios(response.data);
      }
    } catch (error) {
      console.log("error al obtener la lista de acopios" + error);
    }
  };
  //obtiene la lista de productos activos de la empresa
  const getProductosEmpresa = async () => {
    try {
      const response = await makeSafeGetRequest(
        "productosempresa/" + idEmpresa,
        auth.accessToken
      );
      if (response && response.status === 200) {
        setProductosEmpresa(response.data);
      }
    } catch (error) {
      console.log(
        "Error al obtener los productos pertenecientes a la empresa:" + error
      );
    }
  };

  //obtiene las rutas del acopio
  const getRutas = async () => {
    try {
      const response = await makeSafeGetRequest(
        `rutasacopio/${selAcopio}`,
        auth.accessToken
      );
      if (response && response.status === 200) {
        setRutas(response.data);
      }
    } catch (error) {
      console.log("error al obtener la lista de rutas" + error);
    }
  };
  //obtiene los productores de la ruta
  const getProductores = async () => {
    try {
      const response = await makeSafeGetRequest(
        `productoresruta/${selRuta}`,
        auth.accessToken
      );
      if (response && response.status === 200) {
        setProductores(response.data);
      }
    } catch (error) {
      console.log("error al obtener la lista de productores" + error);
    }
  };
  //obtiene la lista de pedidos del productor
  const getPedidos = async () => {
    try {
      const response = await makeSafeGetRequest(
        "pedidosactivosproductor?id=" + selectedProductor,
        auth.accessToken
      );
      if (response && response.status === 200) {
        setPedidos(response.data);
      }
    } catch (error) {}
  };
  //determina el pedido seleccionado
  const getPedido = (e) => {
    console.log("pedido seleccionado: " + JSON.stringify(e.target.value));
    console.log("total pedidos" + JSON.stringify(pedidos));
    const pedido = pedidos.find(
      (pedido) => pedido.id === parseInt(e.target.value)
    );
    console.log("pedido seleccionado: " + JSON.stringify(pedido));
    setPedido(pedido);
    setEditedPedido({...pedido});
  };
  //on search
  const onsearch = (searchTerm) => {
    if (searchTerm === "" || searchTerm === undefined) {
      console.log("el input esta en blanco");
      setSearchedProds();
    } else {
      const filteredItems = productores?.filter((productor) => {
        return productor.nombre_corto
          .toUpperCase()
          .includes(searchTerm.toUpperCase());
      });
      console.log("clientes encontrados: " + JSON.stringify(filteredItems));
      setSearchedProds(filteredItems.slice(0, 10));
    }
  };
  //pasos a realizar al seleccionar un item buscado
  const onSelectedItem = (e) => {
    setSelectedProductor(e.target.value);
    setSelectedProductorNombre(e.target.innerText);
    setSearchedProds([]);
  };
  //determina lo que ocurre al seleccionar una empresa
  const handleEmpresaChange = (e) => {
    setIdEmpresa(e.target.value);
    setNombreEmpresa(e.target.selectedOptions[0].text);
  };

  //determina lo que ocurre al editar un productor
  const onEditProductorPedido = (e) => {
    let newPedido = {...selEditedPedido};
    newPedido.productor.id = e.target.value;
    newPedido.productor.nombre_corto = e.target.innerText;
    setEditedPedido(newPedido);
    setSearchedProds();
  };
  //cambios al editar las observaciones de un pedido
  const ontObservacionesChange = (e) => {
    var newPedido = { ...selEditedPedido, observaciones: e.target.value };
    setEditedPedido(newPedido);
  };
  //cambios al seleccionar un estado de pedido
  const onEstadoChange = (e) => {
    console.log("estado seleccionado" + e.target.value);

    var newPedido = { ...selEditedPedido, estado_pedido: e.target.value };
    console.log(JSON.stringify(newPedido));
    setEditedPedido(newPedido);
  };
  //determina lo que pasa al editar un producto de un pedido
  const onEditedProducto = (e) => {
    var newProducto = { ...editedProductoPedido };
    console.log(JSON.stringify(newProducto) + "\n" + { ...e });
    if (parseInt(e.target.value) > 0) {
      newProducto.producto_agricola = {
        id: e.target.value,
        nombre: e.target.selectedOptions[0].text,
      };
      setEditedProductoPedido(newProducto);
    }
  };
  //determina lo que pasa al editar la cantidad de producto de un pedido
  const onEditedCantProducto = (e) => {
    const cant = e.target.value;
    console.log("cant a editar"+ cant)
    var newProducto;
    if (cant && cant >= 0) {
      newProducto = { ...editedProductoPedido, cantidad: cant };
      setEditedProductoPedido(newProducto);
    }
    else{
      newProducto = { ...editedProductoPedido, cantidad: 0 };

      setEditedProductoPedido(newProducto);
    }
  };
  //determina lo que pasa al editar las observaciones del producto de un pedido
  const onEditedObsProducto = (e) => {
    var newProducto = {
      ...editedProductoPedido,
      observaciones: e.target.value,
    };
    setEditedProductoPedido(newProducto);
  };

  //FUNCIONES PARA AGREGAR PRODUCTOS==================
  const onAddedProducto = (e) => {
    var newProducto = { ...addedProducto };
    console.log(JSON.stringify(newProducto) + "\n" + { ...e });
    if (parseInt(e.target.value) > 0) {
      newProducto = {
        ...addedProducto,
        producto_agricola: {
          id: e.target.value,
          nombre: e.target.selectedOptions[0].text,
        },
      };
      console.log("producto modificado" + JSON.stringify(newProducto));
      setAddedProducto(newProducto);
    }
  };
  const onAddedCantProducto = (e) => {
    const cant = e.target.value;
    var newProducto;
    if (cant && cant >= 0) {
      newProducto = { ...addedProducto, cantidad: cant };
      setAddedProducto(newProducto);
    } else {
      newProducto = { ...addedProducto, cantidad: 0 };
      setAddedProducto(newProducto);
    }
  };

  // Handle changes to the product's observations
  const onAddedObsProducto = (e) => {
    var newProducto = {
      ...addedProducto,
      observaciones: e.target.value,
    };
    setAddedProducto(newProducto);
  };

  //elimina un producto agregado
  const deleteAddedProducto = (prodPedido) => {
    var newAddedProductos = addedProductosPedidos.filter(
      (producto) => producto.id !== prodPedido.id
    );
    setAddedProductosPedidos(newAddedProductos);
  };

  // Add the product to the list of added products
  const addProductoPedido = () => {
    if (
      !addedProducto ||
      Object.keys(addedProducto).length === 0 ||
      addedProducto.cantidad === undefined ||
      addedProducto.cantidad === null ||
      addedProducto.cantidad <= 0 ||
      addedProducto.producto_agricola === undefined
    ) {
      console.log("No product to add");
      return;
    } else {
      // Ensure the product has a unique id
      const newProducto = { ...addedProducto, id: Date.now() };
      const newAddedProductos = [...addedProductosPedidos, newProducto];
      console.log("Productos agregados:" + JSON.stringify(newAddedProductos));
      setAddedProductosPedidos(newAddedProductos);
      setAddedProducto({}); // Clear the addedProducto state after adding
    }
  };
  //Postea la lista de productores agregados
  const postAddedProductos = async () => {
    const notify = notifyPendingNotify();
    try {
      const response = await makeSafePostRequest(
        "postaddedproductos/" + selPedido?.id,
        { data: addedProductosPedidos },
        auth.accessToken
      );
      if (response && response?.status === 200) {
        popNotification(notify, "success", "Productos agregados correctamente");
        //clear productos from addedProductosPedidos
        setAddedProductosPedidos([]);
      } else {
        popNotification(
          notify,
          "error",
          "No se pudieron agregar los productos. Verifique los datos e intente de nuevo"
        );
      }
    } catch (error) {
      popNotification(
        notify,
        "error",
        "Error al agregar los productos. Por favor, intente de nuevo"
      );
    }
  };
  //ACTUALIZA UN PRODUCTO DEL PEDIDO
  const updateProductoPedido = () => {
    var newEditedPedido = { ...selEditedPedido };
    // Find the index of the product to be updated in the productos_pedidos array
    const productIndex = newEditedPedido.productos_pedidos.findIndex(
      (producto) => producto.id === editedProductoPedido.id
    );

    // If the product is found, replace it with the editedProductoPedido
    if (productIndex !== -1) {
      newEditedPedido.productos_pedidos[productIndex] = {
        ...editedProductoPedido,
      };
    }
    // Update the state with the modified object
    setEditedPedido(newEditedPedido);
  };
  const inactiveProductoPedido = () => {
    var newEditedPedido = { ...selEditedPedido };
    const productIndex = newEditedPedido.productos_pedidos.findIndex(
      (producto) => producto.id === editedProductoPedido.id
    );
    if (productIndex !== -1) {
      newEditedPedido.productos_pedidos[productIndex] = {
        ...editedProductoPedido,
        eliminado: !newEditedPedido.productos_pedidos[productIndex].eliminado,
      };
    }
    console.log("pedido editado:" + JSON.stringify(newEditedPedido));
    // Update the state with the modified object
    setEditedPedido(newEditedPedido);
  };
  //Actualiza un pedido en la base de datos
  const updatePedido = async () => {
    const notify = notifyPendingNotify();
    try {
      console.log(
        "probando a editar el pedido" + JSON.stringify(selEditedPedido)
      );
      //sube los productos agregados
      // await postAddedProductos();
      const response = await makeSafePutRequest(
        "updatepedidotransaction/" + selEditedPedido.id,
        { data: { ...selEditedPedido } },
        auth.accessToken
      );
      console.log("verificar respuesta" + JSON.stringify(response));
      if (response && response.status === 200) {
        popNotification(notify, "success", "Pedido editado correctamente");
        clearPedidos();
      } else {
        console.log("error al editar el pedido" + JSON.stringify(response));
        popNotification(
          notify,
          "error",
          "No se pudo editar el pedido. Verifique los datos e intente de nuevo."
        );
      }
    } catch (error) {
      console.log(error);
      popNotification(
        notify,
        "error",
        "Error al editar el pedido. Verifique los datos e intente de nuevo."
      );
    }
  };
   useEffect(() => {
    console.log("selEditedPedido updated: ", selEditedPedido);
  }, [selEditedPedido]);
  //limpia los datos de pedidos
  const clearPedidos = () => {
    setPedido();
    setEditedPedido();
    setPedidos();
  };
  //useeffect productor seleccionado
  useEffect(() => {
    if (selectedProductor) {
      getPedidos();
    }
  }, [selectedProductor]);
  //useEffect CAMBIO RUTA
  useEffect(() => {
    if (selRuta) {
      getProductores();
    }
  }, [selRuta]);
  //useeffect cambio acopio
  useEffect(() => {
    if (selAcopio) {
      getRutas();
    }
  }, [selAcopio]);
  //useeffect CAMBIO EMPRESA
  useEffect(() => {
    if (idEmpresa) {
      getAcopiosEmpresa();
      getProductosEmpresa();
    }
  }, [idEmpresa]);
  //Useeffect role
  useEffect(() => {
    console.log("rol" + role);
    if (role === "administrador") {
      getEmpresa();
    } else if (role === "superadmin") {
      getEmpresasList();
    }
  }, [role]);
  //USEEFFECT INICIAL
  useEffect(() => {
    console.log("Empieza edit pedidos productos");
    determineRole();
  }, []);
  return (
    <div className="h-full md:w-9/12 my-5 py-5">
      <h1 className="text text-3xl">Editar pedidos</h1>
      <div className="grid  grid-cols-1 p-5">
        {role === "administrador" ? (
          <label className="label">{"Empresa: " + nombreEmpresa}</label>
        ) : role === "superadmin" && empresasList && empresasList.length > 0 ? (
          <div className="grid grid-cols-1">
            <label className="label">
              Para empezar, seleccione una empresa
            </label>
            <select
              className="select select-bordered"
              onChange={(e) => setIdEmpresa(e.target.value)}
            >
              <option key="default" value="-1">
                Seleccione una empresa
              </option>
              {empresasList?.map((empresa) => (
                <option key={empresa?.id} value={empresa?.id}>
                  {empresa?.attributes?.nombre}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <></>
        )}
        {idEmpresa ? (
          <>
            <label className="label">Seleccione un acopio</label>
            <select
              className="select select-bordered"
              onChange={(e) => setSelAcopio(e.target.value)}
            >
              <option key={"-1"} value={"-1"}>
                Seleccione un acopio
              </option>
              {acopios &&
                acopios.map((acopio) => (
                  <option key={acopio.id} value={acopio.id}>
                    {acopio.nombre}
                  </option>
                ))}
            </select>
          </>
        ) : (
          <></>
        )}
        {selAcopio ? (
          <>
            <label className="label">Seleccione una ruta</label>
            <select
              className="select select-bordered"
              onChange={(e) => setSelRuta(e.target.value)}
            >
              <option key={"-1"} value={"-1"}>
                Seleccione una ruta
              </option>
              {rutas && rutas.length > 0 ? (
                rutas.map((ruta) => (
                  <option key={ruta.id} value={ruta.id}>
                    {ruta?.nombre}
                  </option>
                ))
              ) : (
                <option key={"-1"} value={"-1"}>
                  No hay rutas disponibles
                </option>
              )}
            </select>
          </>
        ) : (
          <></>
        )}
        {selRuta ? (
          <div className="dropdown dropdown-bottom w-full">
            <label className="label">Seleccione un productor</label>
            <label className="input-group w-max">
              <input
                type={"search"}
                className="input input-bordered  "
                onChange={(e) => onsearch(e.target.value)}
              ></input>
              <span>Buscar</span>
            </label>

            {searchedProds ? (
              <ul
                tabIndex={0}
                className="dropdown-content menu p-2 shadow bg-base-100 w-52 "
              >
                {searchedProds.map((productor) => (
                  <li
                    className="text-left"
                    key={productor?.id}
                    value={productor?.id}
                    tabIndex={0}
                    onClick={(e) => {
                      onSelectedItem(e);
                    }}
                  >
                    {productor.nombre_corto}
                  </li>
                ))}
              </ul>
            ) : (
              <></>
            )}
            {selectedProductorNombre ? (
              <div className="badge badge-lg my-1 py-1">
                {selectedProductorNombre}
                <span
                  onClick={() => {
                    setSelectedProductorNombre();
                    setSelectedProductor();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
              </div>
            ) : (
              <>Seleccione un productor</>
            )}
          </div>
        ) : (
          <></>
        )}
        {selectedProductor ? (
          <>
            <label className="label">Seleccione un pedido</label>
            <select
              className="select select-bordered"
              onChange={(e) => getPedido(e)}
            >
              <option key={"-1"} value={"-1"}>
                Seleccione un pedido
              </option>
              {pedidos && pedidos.length > 0 ? (
                pedidos.map((pedido) => (
                  <option key={pedido.id} value={pedido.id}>
                    {pedido.fecha + " - " + pedido.hora.split(".")[0]}
                  </option>
                ))
              ) : (
                <></>
              )}
            </select>
          </>
        ) : (
          <></>
        )}
        {selPedido ? (
          <div className="card ">
            <div className="card-body">
              <h1 className="card-title">Pedido encontrado</h1>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <p className="text-left">
                  <span className="font-semibold">Fecha:</span>
                  {selPedido?.fecha}{" "}
                </p>

                <p className="text-left">
                  <span className="font-semibold">Hora: </span>
                  {selPedido?.hora}
                </p>
                <p className="text-left">
                  <span className="font-semibold">Nombre del productor: </span>
                  {selPedido?.productor?.nombre_corto}
                </p>
                <span className="text-lg md:col-span-2 font-semibold">
                  Pedido a editar
                </span>

                <div className="dropdown dropdown-bottom w-full">
                  <label className="label">Seleccione un productor</label>
                  <label className="input-group w-max">
                    <input
                      type={"search"}
                      className="input input-bordered  "
                      onChange={(e) => onsearch(e.target.value)}
                    ></input>
                    <span>Buscar</span>
                  </label>

                  {searchedProds ? (
                    <ul
                      tabIndex={0}
                      className="dropdown-content menu p-2 shadow bg-base-100 w-52 "
                    >
                      {searchedProds.map((productor) => (
                        <li
                          className="text-left"
                          key={productor?.id}
                          value={productor?.id}
                          tabIndex={0}
                          onClick={(e) => {
                            onEditProductorPedido(e);
                          }}
                        >
                          {productor.nombre_corto}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <></>
                  )}
                  {selEditedPedido ? (
                    <div className="badge badge-lg my-1 py-1">
                      {selEditedPedido?.productor?.nombre_corto}
                      <span
                        onClick={() => {
                          setSelectedProductorNombre();
                          setSelectedProductor();
                        }}
                      ></span>
                    </div>
                  ) : (
                    <>Seleccione un productor</>
                  )}
                </div>
              </div>
              <label className="label">Estado del pedido</label>
              <select
                className="select select-bordered"
                onChange={(e) => onEstadoChange(e)}
                value={selEditedPedido?.estado_pedido || "-1"}
              >
                <option key={-1} value={-1}>
                  Seleccione un estado
                </option>
                <option value="ACTIVADO" key={"ACTIVADO"}>
                  Activo
                </option>
                <option value="ENTREGADO" key={"ENTREGADO"}>
                  Entregado
                </option>
                <option value="CANCELADO" key={"CANCELADO"}>
                  Cancelado
                </option>
                <option value="CERRADO" key={"CERRADO"}>
                  Cerrado
                </option>
              </select>
              <label className="label">Observaciones del pedido</label>
              <textarea
                className="textarea textarea-bordered"
                value={selEditedPedido.observaciones}
                onChange={(e) => {
                  ontObservacionesChange(e);
                }}
              ></textarea>
              
              {selEditedPedido &&
              selEditedPedido.productos_pedidos.length > 0 ? (
                selEditedPedido.productos_pedidos.map((prodPedido) => (
                  <div
                    className={`card card-side bg-zinc-50 shadow-sm bordered ${
                      prodPedido?.eliminado === true ? " bg-zinc-100" : ""
                    }`}
                    key={prodPedido?.id}
                  >
                    <div className="card-body">
                      <h2 className="card-title">
                        {prodPedido?.producto_agricola?.nombre}
                      </h2>
                      <h2 className="card-title">
                        {prodPedido?.producto_agricola?.marca}
                      </h2>
                      <p className="text-left">
                        <span className="font-semibold">Cantidad: </span>
                        {prodPedido.cantidad}
                      </p>
                      <p className="text-left">
                        <span className="font-semibold">Observaciones: </span>
                        {prodPedido.observaciones}
                      </p>
                      {prodPedido.eliminado === true ? (
                        <span className="font-semibold text-lg text-red-600">
                          ELIMINADO
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="content-center gap-1 mx-2">
                      <label
                        className="btn btn-warning mx-1"
                        htmlFor="modal-update-producto"
                        onClick={() => setEditedProductoPedido(prodPedido)}
                      >
                        Editar
                      </label>
                      <label
                        className="btn btn-error my-1"
                        htmlFor="modal-inactive-producto"
                        onClick={() => setEditedProductoPedido(prodPedido)}
                      >
                        Eliminar
                      </label>
                    </div>
                  </div>
                ))
              ) : (
                <div>No hay productos en el pedido </div>
              )}
            </div>

            <label className="btn btn-link" htmlFor="modal-create-producto">
              Agregar productos
            </label>
            {/* Lista de productos agregados */}
            {addedProductosPedidos && addedProductosPedidos?.length > 0 ? (
              addedProductosPedidos?.map((prodPedido) => (
                <div
                  className={`card card-side bg-zinc-50 shadow-sm bordered ${
                    prodPedido?.eliminado === true ? " bg-zinc-100" : ""
                  }`}
                  key={prodPedido?.id}
                >
                  <div className="card-body">
                    <h2 className="card-title">
                      {prodPedido?.producto_agricola?.nombre}
                    </h2>
                    <h2 className="card-title">
                      {prodPedido?.producto_agricola?.marca}
                    </h2>
                    <p className="text-left">
                      <span className="font-semibold">Cantidad: </span>
                      {prodPedido.cantidad}
                    </p>
                    <p className="text-left">
                      <span className="font-semibold">Observaciones: </span>
                      {prodPedido.observaciones}
                    </p>
                  </div>
                  <div className="content-center gap-1 mx-2">
                    <label
                      className="btn btn-error my-1"
                      onClick={() => deleteAddedProducto(prodPedido)}
                    >
                      Eliminar
                    </label>
                  </div>
                </div>
              ))
            ) : (
              <div> </div>
            )}
            <label className="btn btn-accent" htmlFor="edit-pedido">
              Editar pedido
            </label>
          </div>
        ) : (
          <div className="alert-warning">No hay pedidos disponibles</div>
        )}
      </div>
      {/* Modal de editar producto existente de pedido*/}
      <input
        type="checkbox"
        id="modal-update-producto"
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Editar producto del pedido</h3>
          <div className="grid grid-cols-1">
            <label className="label">Nombre del producto: </label>
            <select
              className="select select-bordered"
              value={editedProductoPedido?.producto_agricola?.id || "-1"}
              onChange={(e) => onEditedProducto(e)}
            >
              <option key={"-1"} value={"-1"}>
                Seleccione un producto
              </option>
              {productosEmpresa?.map((producto) => (
                <option key={producto.id} value={producto.id}>
                  {producto.nombre}
                </option>
              ))}
            </select>
            <label className="label">Cantidad</label>
            <span className=" input input-group ">
              <input
                id="cantidad"
                type="number"
                step={1}
                min={0}
                required
                value={editedProductoPedido?.cantidad }
                className="input input-bordered w-full"
                onChange={(e) => onEditedCantProducto(e)}
              />
              <span>Cantidad</span>
            </span>
            <label className="label">Observaciones</label>
            <textarea
              className="textarea textarea-bordered"
              onChange={(e) => onEditedObsProducto(e)}
              value={editedProductoPedido?.observaciones || ""}
            ></textarea>
          </div>

          <div className="modal-action">
            <label
              htmlFor="modal-update-producto"
              className="btn btn-accent"
              onClick={() => updateProductoPedido()}
            >
              Actualizar Producto
            </label>
            <label htmlFor="modal-update-producto" className="btn">
              Aún no
            </label>
          </div>
        </div>
      </div>

      {/* Modal de crear producto  y asociar al pedido*/}
      <input
        type="checkbox"
        id="modal-create-producto"
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Agregar producto al pedido</h3>
          <div className="grid grid-cols-1">
            <label className="label">Nombre del producto: </label>
            <select
              className="select select-bordered"
              onChange={(e) => onAddedProducto(e)}
              value={addedProducto?.producto_agricola?.id || ""}
            >
              <option key={"-1"} value={"-1"}>
                Seleccione un producto
              </option>
              {productosEmpresa?.map((producto) => (
                <option key={producto.id} value={producto.id}>
                  {producto.nombre}
                </option>
              ))}
            </select>
            <label className="label">Cantidad</label>
            <span className=" input input-group ">
              <input
                id="cantidad"
                type="number"
                step={1}
                min={0}
                required
                className="input input-bordered w-full"
                onChange={(e) => onAddedCantProducto(e)}
                value={addedProducto.cantidad || ""}
              />
              <span>Cantidad</span>
            </span>
            <label className="label">Observaciones</label>
            <textarea
              className="textarea textarea-bordered"
              onChange={(e) => onAddedObsProducto(e)}
              value={addedProducto.observaciones || ""}
            ></textarea>
          </div>

          <div className="modal-action">
            <label
              htmlFor="modal-create-producto"
              className="btn btn-accent"
              onClick={() => addProductoPedido()}
            >
              Agregar Producto
            </label>
            <label htmlFor="modal-create-producto" className="btn">
              Aún no
            </label>
          </div>
        </div>
      </div>

      {/* Modal de inactivar (eliminar logicamente) producto existente de pedido*/}
      <input
        type="checkbox"
        id="modal-inactive-producto"
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Eliminar producto del pedido</h3>
          <div className="">
            <label className="label">
              Realmente desea eliminar o inactivar el siguiente producto del
              pedido?{" "}
            </label>
            <label className="label">
              <span className="font-semibold">Producto: </span>
              {editedProductoPedido?.producto_agricola.nombre}{" "}
            </label>
            <label className="label">
              <span className="font-semibold">Cantidad: </span>
              {editedProductoPedido?.cantidad}{" "}
            </label>
            <label className="label">
              <span className="font-semibold">Observaciones: </span>
              {editedProductoPedido?.observaciones}{" "}
            </label>
          </div>

          <div className="modal-action">
            <label
              htmlFor="modal-inactive-producto"
              className="btn btn-error"
              onClick={() => inactiveProductoPedido()}
            >
              inactivar Producto
            </label>
            <label htmlFor="modal-inactive-producto" className="btn">
              Aún no
            </label>
          </div>
        </div>
      </div>
      {/*Modal de subir a bd los cambios en el pedido*/}
      <input type="checkbox" id="edit-pedido" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Actualizar pedido</h3>
          <div className="grid grid-cols-1">
            <label className="label">
              ¿Realmente desea actualizar el pedido con la información
              ingresada?{" "}
            </label>
            <label>
              <span className="font-semibold">Productor: </span>
              {selEditedPedido?.productor?.nombre_corto}
            </label>
            <label>
              <span className="font-semibold">Observaciones: </span>
              {selEditedPedido?.observaciones}
            </label>
            <table className="table table-compact">
              <thead>
                <tr>
                  <th>Producto</th>
                  <th>Cantidad</th>
                </tr>
              </thead>
              <tbody>
                {selEditedPedido?.productos_pedidos?.map((producto) => (
                  <tr key={producto?.id}>
                    <td>{producto?.producto_agricola?.nombre}</td>
                    <td>{producto.cantidad}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="modal-action">
            <label
              htmlFor="edit-pedido"
              className="btn btn-accent"
              onClick={() => {
                postAddedProductos();
                updatePedido();
              }}
            >
              Actualizar Pedido
            </label>
            <label htmlFor="edit-pedido" className="btn">
              Aún no
            </label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default EditPedidosProductos;
