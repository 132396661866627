import React from 'react'
import AbstractAdminVer from '../utils/AbstractAdminVer'

function VerRutas() {
  return (
     <div className="section h-full mx-2 my-5 md:w-10/12">
      <AbstractAdminVer
        objectName={{
          plural: "Rutas",
          singular: "ruta",
          formal: "rutas",
          informal:"Ruta"
        }}
        itemName={"nombre"}
        query={"rutasempresa"}
        configPath={"utils/configFiles/config.json"}
      ></AbstractAdminVer>{" "}
    </div>
  )
}

export default VerRutas