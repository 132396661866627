import React, { useState } from "react";
import useAuth from "../hooks/useAuth";
import { makeSafePostRequest } from "./utils/Requests";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import { ToastContainer } from "react-toastify";
import { dateToColombianZoneRq, timeToColombianZonerq } from "./utils/timezoneRequests";
import SelectorEmpresa from "./utils/SelectorEmpresa";
import InputNumeroEntero from "./utils/InputNumeroEntero";
function IngresoPorAjuste() {
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState();
  const [selAcopio, setSelAcopio] = useState();
  const [acopioNombre, setAcopioNombre] = useState();
  const [cant, setCant] = useState(0);
  const [observaciones, setObservaciones] = useState("");
  const [aceptado, setAceptado] = useState(false);

  //Realiza el guardado del ingreso por ajuste
  const postIngreso = async () => {
    const notify = notifyPendingNotify();
    if (selAcopio && selAcopio !== "-1" && cant > 0) {
      const fechaHoy = await dateToColombianZoneRq();
      const horaHoy = await timeToColombianZonerq();
      try {
        let data;
        if (aceptado === false) {
          data = {
            fecha: fechaHoy,
            hora: horaHoy,
            cantidad_leche: cant,
            cantidad_medida: cant,
            cantidad_rechazada: 0,
            diferencia: 0,
            observaciones: observaciones,
            aceptado: aceptado,
          };
        } else {
          data = {
            fecha: fechaHoy,
            hora: horaHoy,
            cantidad_leche: cant,
            cantidad_medida: 0,
            cantidad_rechazada: cant,
            diferencia: 0,
            observaciones: observaciones,
            aceptado: aceptado,
          };
        }
        const response = await makeSafePostRequest(
          "ingresoporajuste/" + selAcopio,
          data,
          auth.accessToken
        );
        if (response && response.status === 200) {
          setSelAcopio(null);
          popNotification(
            notify,
            "success",
            "Ingreso por ajuste creado correctamente."
          );
        } else {
          popNotification(
            notify,
            "error",
            "No se pudo realizar el ingreso. Intente de nuevo."
          );
        }
      } catch (error) {
        popNotification(
          notify,
          "error",
          "No se pudo realizar el ingreso. Intente de nuevo."
        );
      }
    } else {
      popNotification(
        notify,
        "error",
        "Seleccione un acopio."
      );
    }
  };
  //cambio al obtener el acopio seleccionado
  const onSelectedAcopio = (e) => {
    setSelAcopio(e.target.value);
    setAcopioNombre(e.target.selectedOptions[0].text);
  };
  //determina lo que ocurre cuando se modifica la cantidad
  const onCantidadChange = (e) => {
    setCant(e.target.value);
  };
  //captura el cambio en observaciones
  const onObsChange = (e) => {
    setObservaciones(e.target.value);
  };
  const onChangeAceptado = (e) => {
    setAceptado(e.target.checked);
  };

  return (
    <div className="section w-2/3 h-full">
      <div className="max-w-full p-4">
        <div className="card w-full bg-white shadow-2xl rounded-lg">
          <div className="card-title border-b border-gray-300 p-3 rounded-t-lg">
            <i className="	fa fa-cart-arrow-down text-gray-600 mr-2"></i>
            <h2 className="text-lg font-semibold text-gray-800 text-center"> Ingreso por ajuste</h2>
          </div>
          <div className="p-4">
            <div className="alert alert-success mt-3">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
                  />
                </svg>
              </span>
              El ingreso por ajuste se usa cuando se requiere tener leche extra para
              completar una venta o un traslado.
            </div>
            <div className="grid grid-cols-1 my-3">
              <SelectorEmpresa
                onChangeSet={setSelAcopio}
                profundidad={"acopio"}
                valorSeleccion={selAcopio}
              />

              {selAcopio && (
                <div className="card">
                  <label className="label">Cantidad a ajustar</label>
                  <InputNumeroEntero
                    label={"Cantidad a ajustar:"}
                    labelSpanFinal={"Litros"}
                    onValorChange={setCant}
                  />

                  <label className="label">Observaciones</label>
                  <textarea
                    className="textarea textarea-bordered"
                    onChange={(e) => {
                      onObsChange(e);
                    }}
                  ></textarea>

                  <div className="flex justify-start form-group">
                    <label className="label text-sm font-medium text-gray-700">Ingreso rechazado:</label>
                    <label className="label cursor-pointer">
                      <span className="label-text mr-1">No </span>
                      <input
                        type="checkbox"
                        className="toggle toggle-accent"
                        checked={aceptado}
                        onChange={onChangeAceptado}
                      />
                      <span className="label-text ml-1"> Si</span>
                    </label>
                  </div>
                  <label className="btn btn-accent" htmlFor="modalConfirm">
                    Confirmar ingreso
                  </label>
                </div>
              )}

              <input
                type={"checkbox"}
                id="modalConfirm"
                className="modal-toggle"
              ></input>
              <div className="modal">
                <div className="modal-box">
                  <h3 className="font-bold text-lg">Aviso</h3>
                  <p>¿Realmente desea realizar el siguiente ingreso por ajuste?</p>
                  <p>
                    <span className="font-bold">Acopio: </span>
                    {acopioNombre}
                  </p>
                  <p>
                    <span className="font-bold">Total: </span>
                    {cant + " litros"}
                  </p>



                  <p>
                    <span className="font-bold">Ingreso rechazado: </span>
                    {aceptado === true ? (
                      <span className="text-error">Si</span>
                    ) : (
                      <span className="text-success">No</span>
                    )}
                  </p>
                  <p>
                    <span className="font-bold">Observaciones: </span>
                    {observaciones}
                  </p>
                  <div className="modal-action">
                    <label
                      htmlFor="modalConfirm"
                      className="btn btn-accent"
                      onClick={() => postIngreso()}
                    >
                      Sí
                    </label>
                    <label htmlFor="modalConfirm" className="btn">
                      Aún no
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer
              position="bottom-center"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default IngresoPorAjuste;
