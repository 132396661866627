import React, { useEffect, useState } from "react";
import { makeSafeGetRequest, makeSafePutRequest } from "./Requests";
import { notifyPendingNotify, popNotification } from "./Notify";
import config from "./configFiles/config.json";
import useAuth from "../../hooks/useAuth";
import { ToastContainer } from "react-toastify";
import { getEmpresaAdmin } from "../../api/RoleInfo";
import { useNavigate } from "react-router-dom";
import AbstractAdminEdit from "./AbstractAdminEdit";

function AbstractAdminVer(props) {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [empresasList, setEmpresasList] = useState();
  const [idEmpresa, setIdEmpresa] = useState();
  const [nombreEmpresa, setNombreEmpresa] = useState();
  //nombre de la administracion
  const [objectName] = useState({ ...props.objectName });
  //nombre de la variable que contiene el nombre del objeto
  const [itemName] = useState(props.itemName);
  const [query] = useState(props.query);
  //data de la administracion
  const [objectData, setObjectData] = useState(props.objectData);
  //objetos buscados
  const [searchedObjects, setSearchedObjects] = useState();
  //item de objeto seleccionado
  const [selItem, setSelItem] = useState();
  // ide del item del objeto seleccionado
  const [itemId, setItemId] = useState();

  const [itemNombre, setItemNombre] = useState();
  const [itemActivo, setItemActivo] = useState();
  const [role, setRole] = useState();
  const [selections, setSelections] = useState();
  //determina el rol al que pertenece el usuario
  const determineRole = () => {
    console.log("rol del usuario" + auth.roles[0]);
    setRole(auth.roles[0]);
  };
  //obtiene los datos de la empresa
  const getEmpresa = async () => {
    try {
      console.log("obtenmiuendo empresa");
      const response = await getEmpresaAdmin(auth);
      setIdEmpresa(response?.empresa?.id);
      setNombreEmpresa(response?.empresa.nombre);
    } catch (error) {}
  };
  //En caso de ser superadmin obtiene la lista de empresas
  const getEmpresasList = async () => {
    if (role === "superadmin") {
      try {
        const response = await makeSafeGetRequest("empresas", auth.accessToken);
        if (response && response.status === 200) {
          console.log(
            "empresas obtenidas" + JSON.stringify(response.data.data)
          );
          setEmpresasList(response?.data.data);
        } else {
          console.log("error al obtener las empresas");
          setEmpresasList();
        }
      } catch (error) {}
    }
  };
  //Obtiene la lista de objetos de la empresa
  const getObjectData = async () => {
    try {
      const response = await makeSafeGetRequest(
        query + "/" + idEmpresa,
        auth.accessToken
      );
      if (response && response.status === 200) {
        setObjectData(response.data);
      } else {
        console.log("error al obtener los datos");
        setObjectData([]);
      }
    } catch (error) {}
  };
  //realiza la busqueda de los elementos del objeto
  const onsearch = (searchTerm) => {
    console.log("termino :" + searchTerm);
    if (searchTerm === "" || searchTerm === undefined) {
      setSearchedObjects();
    } else {
      const filteredItems = objectData?.filter((prod) => {
        return prod?.[itemName]
          .toUpperCase()
          .includes(searchTerm.toUpperCase());
      });
      setSearchedObjects(filteredItems?.slice(0, 10));
    }
  };
  //Determina las acciones a  realizar al seleccionar un item
  const onSelectedItem = (e) => {
    const selItem = searchedObjects.filter(
      (i) => i.id === parseInt(e.target.value)
    );
    console.table(selItem);
    setSearchedObjects([...selItem]);
  };
  //obtiene el item que tiene el id indicado
  const getItem = (idItem) => {
    const item = objectData.find((obj) => obj.id === idItem);
    console.log("item encontrado:" + JSON.stringify(item));
    setSelItem({ ...item });
  };
  //actiualiza el estado del item a activo o inactivo
  const changeActivo = async () => {
    const notify = notifyPendingNotify();
    try {
      const response = await makeSafePutRequest(
        objectName.formal + "/" + itemId,

        { data: { activo: itemActivo ? !itemActivo : true } },
        auth.accessToken
      );
      if (response && response?.status === 200) {
        popNotification(
          notify,
          "success",
          objectName.informal +
            " " +
            itemNombre +
            (itemActivo && !Object.is(itemActivo, null)
              ? " desactivado "
              : " activado ") +
            "correctamente"
        );
        await props.getData;
      } else {
        popNotification(
          notify,
          "error",
          objectName.informal + " no actualizado.Intente de nuevo"
        );
      }
    } catch (error) {
      console.log(error);
      popNotification(
        notify,
        "error",
        "Ha ocurrido un error al actualizar " + objectName.informal
      );
    }
  };
  //realiza el borrado logico de la data
  const deleteData = async () => {
    const notify = notifyPendingNotify();
    try {
      const response = await makeSafePutRequest(
        objectName.formal + "/" + itemId,
        { data: { eliminado: true } },
        auth
      );
      if (response && response.status === 200) {
        popNotification(
          notify,
          "success",
          objectName.informal + " eliminado correctamente"
        );
        await getObjectData();
      } else {
        popNotification(
          notify,
          "error",
          "No se pudo eliminar el " + objectName.informal + " seleccionado."
        );
      }
    } catch (error) {
      console.log("error al eliminar:" + error);
      popNotification(
        notify,
        "error",
        "Error al eliminar el " + objectName.informal + " seleccionado."
      );
    }
  };
  //obtiene los items de tipo seleccion
  const getSelectionsItems = () => {
    console.log("config" + JSON.stringify(config[objectName.singular]));
    return Object.entries(config[objectName.singular]).reduce(
      (acc, [key, value]) => {
        console.log("iterando value" + JSON.stringify(value));
        if (value["type"] === "selection") {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );
  };
  //consulta los valores de tipo seleccion
  const fetchSelections = async () => {
    const sel = getSelectionsItems();
    console.log("Imprimiendo selecciones" + JSON.stringify(sel));
    for (const [key, value] of Object.entries(sel)) {
      //verificar si el campo depende de otro campo
      if (value.dependant === true && value.dependantField === "empresa") {
        const newQuery = value.query + "/" + idEmpresa;
        const response = await makeSafeGetRequest(newQuery, auth.accessToken);
        if (response && response.status === 200) {
          //code to prove
          const newSelections = { [key]: response.data };
          setSelections((prevSelections) => ({
            ...prevSelections,
            ...newSelections,
          }));
        }
      }
    }
  };
  //cambia los datos basicos del objeto para activar o inactivar
  const setObjCompData = (item) => {
    console.log("aaaaaaaaaaaaaaaaaaaaa");
    setItemId(item.id);
    setItemNombre(item.nombre_corto || item.nombre);
    setItemActivo(item.activo);
  };
  //genera la redireccion a la pagina de creacion
  const handleRedirect = () => {
    let propsToPass;
    if (role === "superadmin") {
      propsToPass = {
        role: role,
        objectName: objectName,
        empresasList: empresasList,
        selections: selections,
        config: config[objectName.singular],
      };
    } else if (role === "administrador") {
      propsToPass = {
        role: role,
        objectName: objectName,
        idEmpresa: idEmpresa,
        nombreEmpresa: nombreEmpresa,
        selections: selections,
      };
    }
    console.log("props to pass" + JSON.stringify(propsToPass));
    navigate("/create", { state: propsToPass });
  };
  // useeffect obtener clientes
  useEffect(() => {
    if (idEmpresa) {
      getObjectData();
    }
  }, [idEmpresa]);

  //useeffect cambio de empresa
  useEffect(() => {
    console.log("cambio de empresa: " + idEmpresa);
    if (idEmpresa !== "0") {
      console.log("entra");
      fetchSelections();
    } else {
      console.log("no entra");
    }
  }, [idEmpresa]);
  //useeffect cambio en rol
  useEffect(() => {
    if (role === "administrador") {
      getEmpresa();
    } else if (role === "superadmin") {
      setIdEmpresa();

      getEmpresasList();
    }
  }, [role]);
  //useeffect inicial

  useEffect(() => {
    determineRole();
    console.log(role);
  }, []);
  useEffect(() => {
    console.log("selecciones" + JSON.stringify(selections));
  }, [selections]);
  return (
    <div className="grid grid-cols-1 ">
      <h1 className=" text text-3xl">Ver{" " + objectName.plural}</h1>

      {/* Boton para redireccionar a la pagina de creacion */}
      <div className="flex justify-start mt-5">
        <button
          className={"btn btn-success btn-outline"}
          onClick={handleRedirect}
        >
          Crear {objectName.informal}
        </button>
      </div>

      {role === "administrador" ? (
        <label className="label">Empresa: {nombreEmpresa}</label>
      ) : role === "superadmin" && empresasList && empresasList.length > 0 ? (
        <div className="grid grid-cols-1">
          <label className="label">Para empezar, seleccione una empresa</label>
          <select
            className="select select-bordered"
            onChange={(e) => setIdEmpresa(e.target.value)}
          >
            <option key={0} value={0}>
              Seleccione una empresa
            </option>
            {empresasList.map((empresa) => (
              <option key={empresa?.id} value={empresa?.id}>
                {empresa?.attributes?.nombre}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <></>
      )}

      <label className="label">{"Rol:" + role}</label>
      <div className="dropdown dropdown-bottom w-full">
        <label className="input-group max-w-max">
          <input
            type={"search"}
            className="input input-bordered "
            onChange={(e) => onsearch(e.target.value)}
          ></input>
          <span>Buscar</span>
        </label>
        {searchedObjects && searchedObjects.length > 0 ? (
          <ul
            tabIndex={0}
            className="dropdown-content menu p-2 shadow bg-base-100 w-52 "
          >
            {searchedObjects?.map((obj) => (
              <li
                className="text-left"
                key={obj.id}
                value={obj.id}
                tabIndex={0}
                onClick={(e) => {
                  onSelectedItem(e);
                }}
              >
                {obj[itemName]}
              </li>
            ))}
          </ul>
        ) : (
          <div className="alert alert-info my-3">
            <div className="">
              Realice una búsqueda, o intente con un nuevo término
            </div>
          </div>
        )}

        {searchedObjects && searchedObjects.length > 0 && config ? (
          <div className="overflow-x-auto my-2">
            <table className="table table-compact">
              <thead>
                <tr>
                  <th></th>
                  {/* //IMPRIMIR LOS NOMBRES DEL ARCHIVO DE CONFIGURACION */}
                  {Object.entries(config[objectName.singular])?.map(
                    ([attribute, value]) =>
                      value?.visible !== false ? (
                        <th key={attribute}>{value.label}</th>
                      ) : (
                        <></>
                      )
                  )}
                  <th colSpan={3}>Opciones</th>
                </tr>
              </thead>
              <tbody>
                {/* GENERAR LOS ATRIBUTOS DE CADA ITEM */}
                {searchedObjects?.map((item) => (
                  <tr key={item?.id} value={item.id}>
                    <td></td>

                    {Object.keys(config[objectName.singular])?.map(
                      (itemKey) => {
                        if (
                          config[objectName.singular][itemKey]?.visible === true
                        ) {
                          if (
                            config[objectName.singular][itemKey]?.type !==
                            "selection"
                          ) {
                            return <td key={itemKey}>{item[itemKey]}</td>;
                          } else {
                            return (
                              <td key={itemKey}>
                                {item[itemKey]?.nombre ||
                                  item[itemKey]?.nombre_corto ||
                                  item[itemKey]?.nombre_banco}
                              </td>
                            );
                          }
                        }else{
                          return <></>
                        }
                      }
                    )}
                    <th>
                      {/* INACTIVAR */}
                      {item?.activo === true ? (
                        <label
                          className="btn btn-ghost"
                          htmlFor="modal-activate"
                          onClick={() => {
                            setObjCompData(item);
                          }}
                        >
                          Inactivar
                        </label>
                      ) : (
                        //ACTIVAR
                        <label
                          className="btn btn-primary"
                          htmlFor="modal-activate"
                          onClick={() => {
                            setObjCompData(item);
                          }}
                        >
                          Activar
                        </label>
                      )}
                    </th>
                    {/* EDITAR| */}
                    <td>
                      <label
                        className="btn btn-warning"
                        onClick={() => {
                          getItem(item.id);
                          setObjCompData(item);
                        }}
                      >
                        Editar
                      </label>
                    </td>
                    {/* ELIMINAR */}
                    <td>
                      <label
                        className="btn btn-error"
                        htmlFor="modal-delete"
                        onClick={() => {
                          getItem(item.id);
                          setObjCompData(item);
                        }}
                      >
                        Eliminar
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div></div>
        )}
        {selItem ? (
          <AbstractAdminEdit
            idEmpresa={idEmpresa}
            nombreEmpresa={nombreEmpresa}
            empresasList={empresasList}
            rol={role}
            item={selItem}
            itemName={itemName}
            query={query}
            updateQuery={props.updateQuery}
            objectName={objectName}
            config={config[objectName.singular]}
            selections={selections}
          ></AbstractAdminEdit>
        ) : (
          <></>
        )}
      </div>

      {/* Modal de confirmar edicion*/}
      <input type="checkbox" id="modal-activate" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Aviso</h3>
          <p className="py-4">
            Realmente desea {itemActivo === true ? "INACTIVAR" : "ACTIVAR"} el
            siguiente {objectName.informal}?
          </p>
          <p>
            <span className="font-semibold">Nombre: </span>
            {itemNombre}
          </p>
          <div className="modal-action">
            <label
              htmlFor="modal-activate"
              className="btn btn-accent"
              onClick={changeActivo}
            >
              Actualizar {objectName.informal}
            </label>
            <label htmlFor="modal-activate" className="btn">
              Aún no
            </label>
          </div>
        </div>
      </div>

      {/* Modal de confirmar eliminacion*/}
      <input type="checkbox" id="modal-delete" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Aviso</h3>
          <p className="py-4">
            Realmente desea ELIMINAR el siguiente {objectName.informal}? .
            <br></br>Esta acción NO se puede deshacer.
          </p>
          <p>
            <span className="font-semibold">Nombre: </span>
            {itemNombre}
          </p>
          <div className="modal-action">
            <label
              htmlFor="modal-delete"
              className="btn btn-error"
              onClick={deleteData}
            >
              Eliminar {objectName.informal}
            </label>
            <label htmlFor="modal-delete" className="btn">
              Aún no
            </label>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default AbstractAdminVer;
