import React, { useEffect, useRef, useState } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import { makeGetRequest, makeSafeGetRequest } from "./utils/Requests";
import useAuth from "../hooks/useAuth";
import { getEmpresaAdmin } from "../api/RoleInfo";

function ReporteVentasCalidad() {
  //state de reporte
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState();
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [ventasClienteRango, setVentasClienteRango] = useState();
  const [selectedVenta, setSelectedVenta] = useState();

  const [clientes, SetClientes] = useState();
  const [searchedClientes, setSearchedClientes] = useState();
  const [selectedCliente, setSelectedClient] = useState();
  const [selClienteNombre, setSelClientNombre] = useState();
  const [acopios, setAcopios] = useState();
  const [selAcopio, setSelAcopio] = useState();
  const [fechaInicial, setFechaInicial] = useState();
  const [fechaFinal, setFechaFinal] = useState();
  const tableRef = useRef(null);

  //Obtiene la empresa
  const getEmpresa = async () => {
    try {
      const response = await getEmpresaAdmin(auth);
      if (response) {
        console.log("encontro datos de la empresa" + JSON.stringify(response));
        setIdEmpresa(response.empresa?.id);
        setNombreEmpresa(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };
  //obtiene la lista de acopios de la empresa
  const getAcopiosEmpresa = async () => {
    try {
      const response = await makeSafeGetRequest(
        "acopiosempresa/" + idEmpresa,
        auth.accessToken
      );
      if (response && response.status === 200) {
        setAcopios(response.data);
      }
    } catch (error) {
      console.error("error al obtener los acopios");
    }
  };
  //obtiene la lista de clientes de la empresa a la que pertenece el administrador
  const getClientes = async () => {
    try {
      const response = await makeSafeGetRequest(
        "clientesempresa/" + idEmpresa,
        auth.accessToken
      );
      if (response) {
        SetClientes(response.data);
      } else {
        SetClientes();
      }
    } catch (error) {
      console.log("Error al obtener los clientes" + error);
    }
  };
  //Obtiena la lista de ventas que pertenecen a un cliente determinado en un rango de tiempo
  const getVentasClienteRango = async () => {
    try {
      const response = await makeSafeGetRequest(
        "ventasclientecalidad?idcliente=" +
          selectedCliente +
          "&idacopio=" +
          selAcopio +
          "&fechainicial=" +
          fechaInicial +
          "&fechafinal=" +
          fechaFinal,
        auth.accessToken
      );
      if (response) {
        setVentasClienteRango(response.data);
      } else {
        setVentasClienteRango();
      }
    } catch (error) {}
  };
  //Obtiene la venta seleccionada entre las obtenidas según el rango de fechas
  const getSelectedVenta = (idVenta) => {
    console.log("id de la venta" + idVenta);
    const selVenta = ventasClienteRango.find((venta) => {
      return venta.id == idVenta;
    });
    console.log("venta seleccionada:" + JSON.stringify(selVenta));
    setSelectedVenta(selVenta);
  };
  //realiza la descarga del elemento table
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Ventas_"+selClienteNombre+"_"+fechaInicial+"_"+fechaFinal+"_calidad",
    sheet: "ventasrango",
  });
  //on search
  const onsearch = (searchTerm) => {
    if (searchTerm === "" || searchTerm === undefined) {
      console.log("el input esta en blanco");
      setSearchedClientes();
    } else {
      const filteredItems = clientes.filter((cliente) => {
        return cliente.nombre.toUpperCase().includes(searchTerm.toUpperCase());
      });
      console.log("clientes encontrados: " + JSON.stringify(filteredItems));
      setSearchedClientes(filteredItems.slice(0, 10));
    }
  };
  //pasos a realizar al seleccionar un item buscado
  const onSelectedItem = (e) => {
    
    setSelectedClient(e.target.value);
    setSelClientNombre(e.target.innerText);
  };
  const onAcopioChange = (e) => {
    setSelAcopio(parseInt(e.target.value));
  };

  useEffect(() => {
    if (idEmpresa) {
      getClientes();
      getAcopiosEmpresa();
    }
  }, [idEmpresa]);
  //pasos a realizar
  //useeffect inicial
  useEffect(() => {
    getEmpresa();
    if (idEmpresa) {
      getClientes();
      getAcopiosEmpresa();
    }
  }, []);
  return (
    <div className="h-full  py-5 md:w-3/4">
      <div className=" grid grid-cols-1">
        <h1 className="text-3xl">Ventas con datos de Calidad</h1>
        <label className="label">Empresa: {nombreEmpresa}</label>
        <label className="label">Cliente</label>
        <div className="dropdown dropdown-bottom w-full">
          <label className="input-group w-max">
            <input
              type={"search"}
              className="input input-bordered  "
              onChange={(e) => onsearch(e.target.value)}
            ></input>
            <span>Buscar</span>
          </label>

          {searchedClientes ? (
            <ul
              tabIndex={0}
              className="dropdown-content menu p-2 shadow bg-base-100 w-52 "
            >
              {searchedClientes.map((cliente) => (
                <li
                  className="text-left"
                  key={cliente.id}
                  value={cliente.id}
                  tabIndex={0}
                  onClick={(e) => {
                    onSelectedItem(e);
                  }}
                >
                  {cliente.nombre}
                </li>
              ))}
            </ul>
          ) : (
            <></>
          )}
          {selClienteNombre ? (
            <div className="badge badge-lg my-1 py-1">
              {selClienteNombre}
              <span
                onClick={() => {
                  setSelClientNombre();
                  setSelectedClient();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </span>
            </div>
          ) : (
            <>Seleccione un cliente</>
          )}
        </div>
        <label className="label">Acopio</label>
        <select
          className="select select-bordered"
          onChange={(e) => onAcopioChange(e)}
        >
          <option key="-1" value={"-1"}>
            Seleccione un acopio
          </option>
          {acopios ? (
            acopios.map((acopio) => (
              <option key={acopio.id} value={acopio.id}>
                {acopio.nombre}
              </option>
            ))
          ) : (
            <></>
          )}
        </select>
        <label className="label">Fecha Inicial</label>
        <input
          type={"date"}
          className="input input-bordered"
          onChange={(e) => setFechaInicial(e.target.value)}
        ></input>
        <label className="label">Fecha Final</label>
        <input
          type={"date"}
          className="input input-bordered"
          onChange={(e) => setFechaFinal(e.target.value)}
        ></input>
        <button
          className="btn btn-accent py-3 my-3"
          onClick={getVentasClienteRango}
        >
          Generar Reporte
        </button>
        {ventasClienteRango && ventasClienteRango.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="table table-compact" ref={tableRef}>
              <thead>
                <tr>
                  <th></th>
                  <th>Fecha</th>
                  <th>Acopio</th>
                  <th>Cliente</th>
                  <th>Es Novedad</th>
                  <th>Cantidad Total</th>
                  <th>Cantidad C1</th>
                  <th>Temp</th>
                  <th>Observaciones</th>
                  <th>Acidez</th>
                  <th>pH</th>
                  <th>Agua</th>
                  <th>Antibiótico</th>
                  <th>Betalactámicos</th>
                  <th>Tetraciclinas</th>
                  <th>Sulfamidas</th>
                  <th>Proteína</th>
                  <th>Grasa</th>
                  <th>Sólidos</th>
                  <th>Suero</th>
                  <th>Alcohol</th>
                  <th>Neutralizantes</th>
                  <th>Adulterantes</th>
                  
                  <th>Cantidad C2</th>
                  <th>Temp</th>
                  <th>Observaciones</th>
                  <th>Acidez</th>
                  <th>pH</th>
                  <th>Agua</th>
                  <th>Antibiótico</th>
                  <th>Betalactámicos</th>
                  <th>Tetraciclinas</th>
                  <th>Sulfamidas</th>
                  <th>Proteína</th>
                  <th>Grasa</th>
                  <th>Sólidos</th>
                  <th>Suero</th>
                  <th>Alcohol</th>
                  <th>Neutralizantes</th>
                  <th>Adulterantes</th>
                 
                  <th>Cantidad C3</th>
                  <th>Temp</th>
                  <th>Observaciones</th>
                  <th>Acidez</th>
                  <th>pH</th>
                  <th>Agua</th>
                  <th>Antibiótico</th>
                  <th>Betalactámicos</th>
                  <th>Tetraciclinas</th>
                  <th>Sulfamidas</th>
                  <th>Proteína</th>
                  <th>Grasa</th>
                  <th>Sólidos</th>
                  <th>Suero</th>
                  <th>Alcohol</th>
                  <th>Neutralizantes</th>
                  <th>Adulterantes</th>
                </tr>
              </thead>
              <tbody>
                {ventasClienteRango?.map((venta) => (
                  <>
                    <tr key={venta.id} value={venta.id}>
                      <td></td>
                      <td>{venta.fecha}</td>
                      <td>{venta?.acopio?.nombre}</td>
                      <td>
                        {venta.cliente?.nombre + venta.compartimentos.length}
                      </td>
                      <td>{venta.venta_novedad?<span className="text-error">Sí</span>:<span className="text-success">No</span>}</td>
                      <td>{venta.cantidad_leche}</td>
                      {venta?.compartimentos?.map((comp) => (
                        <React.Fragment key={comp.id} value={comp.id}>
                          <td>{comp.cantidad_leche}</td>
                          <td>{comp.temperatura}</td>
                          <td>{comp.observaciones}</td>
                          <td>{comp?.prueba?.acidez}</td>
                          <td>{comp?.prueba?.ph}</td>
                          <td>{comp?.prueba?.agua}</td>
                          <td>
                            {comp?.prueba?.antibiotico === true ? (
                              <span className="text-error">Positivo</span>
                            ) : comp?.prueba?.antibiotico === false ? (
                              <span className="text-success">Negativo</span>
                            ) : (
                              "No marcado"
                            )}
                          </td>
                          <td>
                            {comp?.prueba?.betalactamicos === true ? (
                              <span className="text-error">Positivo</span>
                            ) : comp?.prueba?.betalactamicos === false ? (
                              <span className="text-success">Negativo</span>
                            ) : (
                              "No marcado"
                            )}
                          </td>
                          <td>
                            {comp?.prueba?.tetraciclinas === true ? (
                              <span className="text-error">Positivo</span>
                            ) : comp?.prueba?.tetraciclinas === false ? (
                              <span className="text-success">Negativo</span>
                            ) : (
                              "No marcado"
                            )}
                          </td>
                          <td>
                            {comp?.prueba?.sulfamidas === true ? (
                              <span className="text-error">Positivo</span>
                            ) : comp?.prueba?.sulfamidas === false ? (
                              <span className="text-success">Negativo</span>
                            ) : (
                              "No marcado"
                            )}
                          </td>
                          
                          <td>{comp?.prueba?.proteina}</td>
                          <td>{comp?.prueba?.grasa}</td>
                          <td>{comp?.prueba?.solidos}</td>
                          <td>{comp?.prueba?.suero}</td>
                          <td>
                            {comp?.prueba?.alcohol === true ? (
                              <span className="text-error">Positivo</span>
                            ) : comp?.prueba?.alcohol === false ? (
                              <span className="text-success">Negativo</span>
                            ) : (
                              "No marcado"
                            )}
                          </td>
                          <td>
                            {comp?.prueba?.neutralizantes === true ? (
                              <span className="text-error">Positivo</span>
                            ) : comp?.prueba?.neutralizantes === false ? (
                              <span className="text-success">Negativo</span>
                            ) : (
                              "No marcado"
                            )}
                          </td>
                          <td>
                            {comp?.prueba?.adulterantes === true ? (
                              <span className="text-error">Positivo</span>
                            ) : comp?.prueba?.adulterantes === false ? (
                              <span className="text-success">Negativo</span>
                            ) : (
                              "No marcado"
                            )}
                          </td>
                        </React.Fragment>
                      ))}
                    </tr>
                  </>
                ))}
              </tbody>
            </table>

            <button className="btn w-40" onClick={onDownload}>
              Exportar Excel
            </button>
          </div>
        ) : (
          <div className="alert alert-success">
            No hay ventas realizadas en el rango seleccionado
          </div>
        )}
      </div>
      
    </div>
  );
}

export default ReporteVentasCalidad;
