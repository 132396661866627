import React, { useEffect, useRef, useState } from "react";
import useAuth from "../hooks/useAuth";
import { getEmpresaAdmin } from "../api/RoleInfo";
import { makeSafeGetRequest } from "./utils/Requests";
import { useDownloadExcel } from "react-export-table-to-excel";

function ReportePedidosProductor() {
  const { auth } = useAuth();
  const [pedidos, setPedidos] = useState();
  const [fechaInicial, setFechaInicial] = useState();
  const [fechaFinal, setFechaFinal] = useState();
  const [role, setRole] = useState();
  const [empresasList, setEmpresasList] = useState();
  const [idEmpresa, setIdEmpresa] = useState();
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [acopios, setAcopios] = useState();
  const [selAcopio, setSelAcopio] = useState();
  const [rutas, setRutas] = useState();
  const [selRuta, setSelRuta] = useState();
  const [productores, setProductores] = useState();
  const [selProductor, setSelProductor] = useState(null);
  //userefs tablas
  const tableRef = useRef(null);
  //determina el rol al que pertenece el usuario
  const determineRole = () => {
    console.log("rol del usuario" + auth.roles[0]);
    setRole(auth.roles[0]);
  };
  //obtiene los datos de la empresa
  const getEmpresa = async () => {
    try {
      console.log("obtenmiuendo empresa");
      const response = await getEmpresaAdmin(auth);
      setIdEmpresa(response?.empresa?.id);
      setNombreEmpresa(response?.empresa.nombre);
    } catch (error) {}
  };
  //En caso de ser superadmin obtiene la lista de empresas
  const getEmpresasList = async () => {
    if (role === "superadmin") {
      try {
        const response = await makeSafeGetRequest("empresas", auth.accessToken);
        if (response && response.status === 200) {
          console.log(
            "empresas obtenidas" + JSON.stringify(response.data.data)
          );
          setEmpresasList(response?.data.data);
        } else {
          console.log("error al obtener las empresas");
          setEmpresasList();
        }
      } catch (error) {}
    }
  };
  //
  //obtiene lista de acopios
  const getAcopios = async () => {
    try {
      const response = await makeSafeGetRequest(
        "acopiosempresa/" + idEmpresa,
        auth.accessToken,
        idEmpresa
      );
      if (response && response.status === 200) {
        console.log("acopios obtenidos" + JSON.stringify(response.data));
        setAcopios(response?.data);
      } else {
        console.log("error al obtener acopios");
        setAcopios();
      }
    } catch (error) {
      console.log("error al obtwenr acopios");
    }
  };
  //obtiene la lista de rutas del acopio
  const getRutas = async () => {
    try {
      const response = await makeSafeGetRequest(
        "rutasacopio/" + selAcopio,
        auth.accessToken
      );
      if (response && response.status === 200) {
        console.log("rutas obtenidas" + JSON.stringify(response.data));
        setRutas(response?.data);
      }
    } catch (error) {
      console.log("error al obtener rutas");
    }
  };
  //obtiene los productores de la ruta
  const getProductores = async () => {
    const response = await makeSafeGetRequest(
      "productoresruta/" + selRuta,
      auth.accessToken
    );
    if (response && response.status === 200) {
      setProductores(response?.data);
    } else {
      console.log("error al obtener los productores");
      setProductores();
    }
  };
  //cambo de acopio
  const onAcopioChange = async (e) => {
    console.log("cambio de acopio: " + e.target.value);
    setSelAcopio(e.target.value);
  };
  //cambio de ruta
  const onRutaChange = (e) => {
    console.log("cambio de ruta: " + e.target.value);
    setSelRuta(e.target.value);
  };

  //obtiene la lista de pedidos del productor
  const getPedidosProductor = async () => {
    try {
      const response = await makeSafeGetRequest(
        "pedidosproductor?" +
          "productor=" +
          selProductor +
          "&" +
          "fechainicial=" +
          fechaInicial +
          "&fechafinal=" +
          fechaFinal,
        auth.accessToken,
        selAcopio
      );
      if (response && response.status === 200) {
        console.log("pedidos obtenidos" + JSON.stringify(response.data));
        setPedidos(response?.data);
      } else {
        console.log("error al obtener los pedidos");
        setPedidos();
      }
    } catch (error) {}
  };
  //realiza la descarga del elemento table
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "pedidos" + "_" + fechaInicial + "_" + fechaFinal,
    sheet: "Pedidos",
  });

  //useeffect cambio en ruta
  useEffect(() => {
    console.log("cambio de ruta: " + selRuta);
    getProductores();
  }, [selRuta]);
  //useeffect cambio de acopio
  useEffect(() => {
    console.log("cambio de acopio: " + selAcopio);
    getRutas();
  }, [selAcopio]);
  //useeffect cambio de empresa
  useEffect(() => {
    console.log("cambio de empresa: " + idEmpresa);
    if (idEmpresa !== "0") {
      getAcopios();
    } else {
    }
  }, [idEmpresa]);
  //useeffect cambio en rol
  useEffect(() => {
    if (role === "administrador") {
      getEmpresa();
    } else if (role === "superadmin") {
      setIdEmpresa();

      getEmpresasList();
    }
  }, [role]);
  //useeffect inicial

  useEffect(() => {
    determineRole();
    console.log(role);
  }, []);
  return (
    <div className="mx-4 my-5 h-full w-full  md:w-10/12  ">
      <div className="grid grid-cols-1 mx-2">
        <h3 className="text text-2xl">Reporte de Pedidos por Productor </h3>
        {role === "administrador" ? (
          <label className="label">Empresa: {nombreEmpresa}</label>
        ) : role === "superadmin" && empresasList && empresasList.length > 0 ? (
          <div className="grid grid-cols-1">
            <label className="label">
              Para empezar, seleccione una empresa
            </label>
            <select
              className="select select-bordered"
              onChange={(e) => setIdEmpresa(e.target.value)}
            >
              <option key={0} value={0}>
                Seleccione una empresa
              </option>
              {empresasList.map((empresa) => (
                <option key={empresa?.id} value={empresa?.id}>
                  {empresa?.attributes?.nombre}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <></>
        )}
        {idEmpresa && idEmpresa > 0 ? (
          <div className="grid grid-cols-1">
            <label className="label">Seleccione un acopio</label>

            <select
              className="select select-bordered"
              onChange={(e) => {
                onAcopioChange(e);
              }}
            >
              <option key={-1} value={-1}>
                Seleccione un acopio
              </option>
              {acopios?.map((acopio) => (
                <option key={acopio.id} value={acopio.id}>
                  {acopio.nombre}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <></>
        )}
        {selAcopio && selAcopio !== -1 ? (
          <div className="grid grid-cols-1">
            <label className="label">Seleccione una ruta</label>
            <select
              className="select select-bordered"
              onChange={(e) => onRutaChange(e)}
            >
              <option key={-1} value={-1}>
                Seleccione una ruta
              </option>
              {rutas?.map((ruta) => (
                <option key={ruta.id} value={ruta.id}>
                  {ruta.nombre}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <></>
        )}
        {selRuta && selRuta !== -1 ? (
          <div className="grid grid-cols-1">
            <label className="label">Seleccione un productor</label>
            <select
              className="select select-bordered"
              onChange={(e) => setSelProductor(e.target.value)}
            >
              <option key={-1} value={-1}>
                Seleccione una ruta
              </option>
              {productores?.map((productor) => (
                <option key={productor.id} value={productor.id}>
                  {productor.nombre_corto}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <></>
        )}
        {selRuta && selRuta !== -1 ? (
          <div className="grid grid-cols-1">
            <label className="label">Fecha inicial</label>
            <input
              type="date"
              className="input input-bordered"
              onChange={(e) => setFechaInicial(e.target.value)}
            ></input>
            <label className="label">Fecha final</label>
            <input
              type="date"
              className="input input-bordered"
              onChange={(e) => setFechaFinal(e.target.value)}
            ></input>
          </div>
        ) : (
          <></>
        )}
        {}

        {selRuta && selRuta !== -1 ? (
          <div className="grid grid-cols-1">
            <button
              className="btn btn-active"
              onClick={() => getPedidosProductor()}
            >
              Generar reporte
            </button>
            {pedidos && pedidos.length > 0 ? (
              <div>
                <div className="overflow-x-auto">
                  <table className="table table-compact" ref={tableRef}>
                    <thead>
                      <tr>
                        <th colSpan={7}>
                          Pedidos de productor{" "}
                          {pedidos[0].productor?.nombre_corto}
                        </th>
                      </tr>
                      <tr>
                        <th></th>
                        <th>Hora</th>
                        <th>Fecha</th>
                        <th>Estado</th>
                        <th>Producto</th>
                        <th>Cantidad</th>
                        <th>Observaciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pedidos.map((pedido) => (
                        <>
                          {pedido.productos_pedidos?.map((producto_pedido) => (
                            <tr key={producto_pedido.id}>
                              <td></td>
                              <td>{pedido?.hora.split(".")[0]}</td>
                              <td>{pedido?.fecha}</td>
                              <td>{pedido?.estado_pedido}</td>
                              <td>
                                {producto_pedido.producto_agricola?.nombre}
                              </td>
                              <td>{producto_pedido?.cantidad}</td>
                              <td>{producto_pedido?.observaciones}</td>
                            </tr>
                          ))}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
                <button className="btn btn-accent" onClick={onDownload}>
                  Exportar Excel
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default ReportePedidosProductor;
