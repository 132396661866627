import React, { useEffect, useState } from "react";
import { makeSafeGetRequest, makeSafePostRequest } from "./Requests";
import useAuth from "../../hooks/useAuth";
import { notifyPendingNotify, popNotification } from "./Notify";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import { attributeIsValid } from "./Validators";
import config from "./configFiles/config.json";

function AbstractAdminCreate() {
  const location = useLocation();
  const { state } = location;
  const { objectName, empresasList, role } = state;
  const [idEmpresa, setIdEmpresa] = useState(state.idEmpresa);
  const [nombreEmpresa, setNombreEmpresa] = useState(state.nombreEmpresa);
  // console.log("Received state:", location.state); // Debugging line
  const [createdItem, setCreatedItem] = useState({});
  const { auth } = useAuth();
  const selectionIndexes = useState();
  const [selections, setSelections] = useState(state.selections);
  const [validator, setValidator] = useState({});

  //determina lo que ocurre con un cambio de empresa
  const onEmpresaChange = (e) => {
    setIdEmpresa(e.target.value);
    setNombreEmpresa(e.target.selectedOptions[0].text);
  };
  //obtiene la lista de objetos de tipo seleccion
  const getSelectionsLists = async () => {
    let selectionsList;
    console.log("seleccionIndexes: " + JSON.stringify(selectionIndexes));
    if (selectionIndexes.length > 0) {
      console.log("hay selecciones");
      for (const selIndex in selectionIndexes) {
        const response = await makeSafeGetRequest(
          selIndex.nombreSeleccion,
          auth.accessToken
        );
        if (response.status === 200) {
          selectionsList.push(response.data);
        }
      }
      setSelections(selectionsList);
    } else {
      console.log("no hay selecciones");
    }
  };

  //Inicializa el objeto a crear
  const initializeItem = () => {
    let initializedItem = {};
    const properties = config[objectName?.singular];
    console.log(JSON.stringify(config[objectName.singular]));
    for (const key in properties) {
      console.log(JSON.stringify(key));
      const property = properties[key];
      if (property.type === "boolean") {
        initializedItem[key] = false;
      } else if (
        property.type === "string" ||
        property.type === "largeString" ||
        property.type === "phone" ||
        property.type === "email" ||
        property.type === "selection"
      ) {
        initializedItem[key] = "";
      } else if (property.type === "number") {
        initializedItem[key] = 0;
      }
    }
    console.log("item inicializado::" + JSON.stringify(initializedItem));
    //agrega el objeto iniciado
    setCreatedItem(initializedItem);
  };
  //registra los cambios en el objeto a crear.Recibe la llave del atributo y el cambioi en los datos
  const onItemChange = (key, newValue) => {
    let changedItem = { ...createdItem };
    changedItem[key] = newValue;
    setCreatedItem(changedItem);
  };
  //Guarda el item creado en la base de datos
  const postItem = async () => {
    const notify = notifyPendingNotify();
    validateCompleteItem();
    const invalidObbject= hasInvalidAttributes()
    console.log("valor invalido"+ invalidObbject)
    try {
      if (!invalidObbject) {
        const response = await makeSafePostRequest(
          objectName.formal,
          { data: { ...createdItem, empresa: idEmpresa } },
          auth.accessToken
        );
        if (response && response.status === 200) {
          popNotification(
            notify,
            "success",
            objectName?.singular + " creado correctamente."
          );
        } else {
          console.log("error al postear la daa:");
          popNotification(
            notify,
            "error",
            "Verifique los datos de " +
              objectName.singular +
              " e intente de nuevo"
          );
        }
      } else {
        popNotification(
          notify,
          "error",
          "Datos incompletos o inválidos. Revise la información e intente de nuevo."
        );
      }
    } catch (error) {
      console.log("error al postear la daa:" + error);
      popNotification(
        notify,
        "error",
        "No se pudo crear " + objectName.singular + ". Intente de nuevo"
      );
    }
  };

  // ----------------VALIDADORES
  //Valida el dato de un item
  const validateAttribute = (key, value) => {
    
    let valid = attributeIsValid(key, value, config[objectName.singular]);
    return valid;
  };
  //valida todo el item editado para verificar que los campos obligatorios estén diligenciados
  const validateCompleteItem = () => {
    let valid = true;
    let newValidator = {...validator};
    for (const [key, value] of Object.entries(config[objectName.singular])) {
      
      valid = validateAttribute(key, createdItem[key]);
      newValidator[key] = valid;
      
    }
    setValidator(newValidator);
  };
  //verifica si el item tiene atributos invalidos
  const hasInvalidAttributes = () => {
    console.table(JSON.stringify(validator));
    if(Object.keys(validator).length === 0) return true;
    return Object.values(validator).some((value) => value === false);
  };
  //valida el atributo de un item para verificar si está diligenciado correctamente
  const validateItemAttribute = (key, value) => {
    console.log("onblur called" + key + value);
    let newValidator = { ...validator };
    newValidator[key] = validateAttribute(key, value);
    
    setValidator(newValidator);
  };
  //consulta y carga a las selecciones dependientes
  const loadDependentSelections = async (dependantkey, value) => {
    console.log(
      "cargando selecciones dependientes" +
        dependantkey +
        JSON.stringify(selections)
    );
    const dependentAttributes = [];
    for (const [key, value] of Object.entries(config[objectName.singular])) {
      if (
        value.type === "selection" &&
        value.dependant === true &&
        value.dependantField === dependantkey
      ) {
        console.log("cargando selecciones dependientes");
        dependentAttributes.push({ key, ...value });
      } else {
        console.log("atributo" + JSON.stringify(value));
      }
    }
    console.log("atributos dependientes" + JSON.stringify(dependentAttributes));
    await fetchSelections(dependentAttributes, value);
  };
  //trae las opociones dependientes de un input de tipo seleccion superior
  const fetchSelections = async (dependentAttributes, value) => {
    try {
      for (const attribute of dependentAttributes) {
        console.log("fetching key" + JSON.stringify(attribute));
        const response = await makeSafeGetRequest(
          attribute.query + "/" + value,
          auth.accessToken
        );
        if (response && response.status === 200) {
          console.log("peticion exitosa" + JSON.stringify(response.data));
          console.log(
            "cargando data en selections" + JSON.stringify(selections)
          );
          const newSelection = { [attribute.key]: response.data };
          console.log("nueva seleccion" + JSON.stringify(newSelection));
          setSelections((prevSelections) => ({
            ...prevSelections,
            ...newSelection,
          }));
        }
      }
    } catch (error) {}
  };

useEffect(() => {
    console.log("validator changed" + JSON.stringify(validator));
}, [validator]);
  //USEEFFECT INICIAL
  useEffect(() => {
    // getSelectionsLists();
    initializeItem();
    console.log("rol:" + role);
  }, []);

  return (
    <div className="h-full w-full md:w-10/12 my-5 py-5">
      <h1 className="text text-2xl">Crear {objectName.informal}</h1>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-4 p-5">
        {idEmpresa && role === "administrador" ? (
          <label className="label md:col-span-2">
            Empresa: {nombreEmpresa + "rol" + role}
          </label>
        ) : empresasList ? (
          <div className="flex flex-col">
            <label className="label">Empresa</label>
            <select
              className="select select-bordered"
              onChange={(e) => onEmpresaChange(e)}
              onBlur={(e) => {
                        
                        loadDependentSelections("empresa", e.target.value);
                      }}
            >
              <option value="-1">Seleccione una empresa</option>
              {empresasList.map((empresa) => (
                <option key={empresa?.id} value={empresa?.id}>
                  {empresa?.attributes.nombre}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <></>
        )}
        {config  ? (
          <>
            {Object.entries(config[objectName.singular]).map(([key, value]) => (
              <div className="flex flex-col" key={key}>
                <label className="label">
                  {value?.label}
                  {value.mandatory === true ? (
                    <span className="text text-error"> * Obligatorio</span>
                  ) : (
                    <></>
                  )}
                </label>
                {
                  /* Cuando es booleano */
                  value.type === "boolean" ? (
                    <div className="grid grid-cols-1">
                      <div className="flex justify-around">
                        <span>No</span>
                        <input
                          type="checkbox"
                          className="toggle toggle-accent"
                          checked={createdItem[key] || false}
                          onChange={(e) => {
                            onItemChange(key, e.target.checked);
                          }}
                          onBlur={(e) =>
                            validateItemAttribute(key, e.target.checked)
                          }
                        ></input>
                        <span>Si</span>
                      </div>
                        {value?.mandatory === true && validator && validator[key] === false ? (
                        <label className="label label-text-alt text-error">
                          Digite un {value.label}
                        </label>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : /*cuando es un numero entero */
                  value.type === "int" ? (
                    <div className="grid grid-cols-1">
                      <input
                        type="number"
                        min={0}
                        step={1}
                        className="input input-bordered"
                        value={createdItem[key] || ""}
                        onChange={(e) => onItemChange(key, e.target.value)}
                        onBlur={(e) =>
                          validateItemAttribute(key, e.target.value)
                        }
                      ></input>
                      {value?.mandatory === true && validator && validator[key] === false ? (
                        <label className="label label-text-alt text-error">
                          Digite un {value.label}
                        </label>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : /*cuando es un numero decimal */
                  value.type === "double" ? (
                    <div className="grid grid-cols-1">
                      <input
                        type="number"
                        min={0}
                        step={0.1}
                        className="input input-bordered"
                        value={createdItem[key] || ""}
                        onChange={(e) => onItemChange(key, e.target.value)}
                        onBlur={(e) =>
                          validateItemAttribute(key, e.target.value)
                        }
                      ></input>
                      {value?.mandatory === true && validator && validator[key] === false ? (
                        <label className="label label-text-alt text-error">
                          Digite un {value.label}
                        </label>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : /*cuando es un telefono */
                  value.type === "phone" ? (
                    <div className="grid grid-cols-1">
                      <input
                        type="tel"
                        className="input input-bordered"
                        pattern="(\+\d{1,2}\s?)?((\(\d{3}\)|\d{3})(\s|-)?)?\d{3}(\s|-)?\d{4}"
                        value={createdItem[key] || ""}
                        onChange={(e) => onItemChange(key, e.target.value)}
                        onBlur={(e) =>
                          validateItemAttribute(key, e.target.value)
                        }
                      ></input>
                      {value?.mandatory === true && validator && validator[key] === false ? (
                        <label className="label label-text-alt text-error">
                          Digite un {value.label}
                        </label>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : /*cuando es un email */
                  value.type === "email" ? (
                    <div className="grid grid-cols-1">
                      <input
                        type="email"
                        className="input input-bordered"
                        value={createdItem[key] || ""}
                        onChange={(e) => onItemChange(key, e.target.value)}
                        onBlur={(e) =>
                          validateItemAttribute(key, e.target.value)
                        }
                      ></input>
                      {value.mandatory === true && validator[key] === false ? (
                        <label className="label label-text-alt text-error">
                          Digite un {value.label}
                        </label>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : /*cuando es un texto */
                  value.type === "string" ? (
                    <div className="grid grid-cols-1">
                      <input
                        type="text"
                        className="input input-bordered"
                        value={createdItem[key] || ""}
                        onChange={(e) => onItemChange(key, e.target.value?.toUpperCase())}
                        onBlur={(e) =>
                          validateItemAttribute(key, e.target.value)
                        }
                      ></input>
                      {value?.mandatory === true && validator && validator[key] === false ? (
                        <label className="label label-text-alt text-error">
                          Digite un {value.label}
                        </label>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : /*cuando es un texto */
                  value.type === "largeString" ? (
                    <div className="grid grid-cols-1">
                      <textarea
                        type="text"
                        className="textarea textarea-bordered"
                        value={createdItem[key] || ""}
                        onChange={(e) => onItemChange(key, e.target.value?.toUpperCase)}
                        onBlur={(e) =>
                          validateItemAttribute(key, e.target.value)
                        }
                      ></textarea>
                      {value.mandatory === true && validator[key] === false ? (
                        <label className="label label-text-alt text-error">
                          Digite un {value.label}
                        </label>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : // cuando es una seleccion
                  value.type === "selection" ? (
                    <div className="grid grid-cols-1">
                      <select
                        className="select select-bordered"
                        onChange={(e) => {
                          onItemChange(key, e.target.value);
                        }}
                        onBlur={(e) => {
                        validateItemAttribute(key, e.target.value);
                        loadDependentSelections(key, e.target.value);
                      }}
                      >
                        <option value={""}>Seleccione una opción</option>
                        {selections[key] ? (
                          selections[key]?.map((selection) => (
                            <option key={selection.id} value={selection.id}>
                              {selection.nombre}
                            </option>
                          ))
                        ) : (
                          <option value={""}>Cargando...</option>
                        )}
                      </select>
                      {value.mandatory === true && validator[key] === false ? (
                        <label className="label label-text-alt text-error">
                          Digite un {value.label}
                        </label>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )
                }
              </div>
            ))}
          </>
        ) : (
          <></>
        )}
      </div>
      <label className="btn btn-primary" htmlFor="modal-save">
        Guardar {objectName.informal}
      </label>
      {/* Modal de confirmar posteo de la informacion*/}
      <input type="checkbox" id="modal-save" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Aviso</h3>
          <p className="py-4">
            Realmente desea CREAR el siguiente {objectName.informal}? .
          </p>
          <p>
            <span className="font-semibold">Empresa: </span>
            {nombreEmpresa}
          </p>
          {createdItem && createdItem !== undefined ? (
            <>
              {Object.entries(config[objectName.singular]).map(([key, value]) =>
                value.type === "boolean" ? (
                  <p key={key}>
                    <span className="font-semibold">{value.label + "?: "}</span>
                    {createdItem[key] === true ? (
                      <span className="text-success">SI</span>
                    ) : (
                      <span className="text-error">NO</span>
                    )}
                  </p>
                ) : (
                  <p key={key}>
                    <span className="font-semibold">{value.label + ": "}</span>
                    {createdItem[key]}
                  </p>
                )
              )}
            </>
          ) : (
            <></>
          )}
          <div className="modal-action">
            <label
              htmlFor="modal-save"
              className="btn btn-success"
              onClick={postItem}
            >
              Crear {objectName.informal}
            </label>
            <label htmlFor="modal-save" className="btn">
              Aún no
            </label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default AbstractAdminCreate;
