import React, { useEffect, useState } from "react";
import { makeGetRequest } from "./utils/Requests";

function ReporteDevolucionesCliente() {
  const [clientes, setClientes] = useState();
  const [selectedCliente, setSelectedCliente] = useState();
  const [fechaInicial, setFechaInicial] = useState();
  const [fechaFinal, setFechaFinal] = useState();
  const [devoluciones, setDevoluciones] = useState();
  //obtiene la lista de clientes de la empresa
  const getClientes = async () => {
    try {
      const response = await makeGetRequest("clientesempresa/1");
      if (response) {
        setClientes(response.data);
      } else {
        setClientes();
      }
    } catch (error) {
      console.log("Error al obtener los clientes:" + error);
    }
  };
  //obtiene la lista de devoluciones del cliente en el rango de fechas seleccionado
  const getDevoluciones = async () => {
    try {
      const response = await makeGetRequest(
        "devolucionesclienterango?idcliente=" +
          selectedCliente +
          "&fechainicial=" +
          fechaInicial +
          "&fechafinal=" +
          fechaFinal
      );
      if (response) {
        setDevoluciones(response.data);
      } else {
        setDevoluciones();
      }
    } catch (error) {
      console.log(error);
    }
  };
  //useEffect inicial
  useEffect(() => {
    getClientes();
  }, []);
  
  return (
    <div className="h-full max-w-max ">
      <div className="grid grid-cols-1">
        <h1 className="text-3xl">Reporte de devoluciones por cliente</h1>
        {clientes ? (
          <div className="form-control">
            <label className="label">Seleccione un cliente</label>
            <select
              className="select select-bordered"
              onChange={(e) => setSelectedCliente(e.target.value)}
            >
              <option key={-1} value={-1}>
                Seleccione un cliente
              </option>
              {clientes.map((cliente) => (
                <option key={cliente?.id} value={cliente?.id}>
                  {cliente?.nombre}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <>cargando clientes</>
        )}
        <label className="label">Fecha inicial</label>
        <input
          type={"date"}
          className="input input-bordered"
          onChange={(e) => {
            setFechaInicial(e.target.value);
          }}
        ></input>
        <label className="label">Fecha final</label>
        <input
          type={"date"}
          className="input input-bordered"
          onChange={(e) => {
            setFechaFinal(e.target.value);
          }}
        ></input>
        <button className="btn btn-accent" onClick={getDevoluciones}>
          Generar reporte
        </button>
        {devoluciones ? (
          <div className="overflow-x-auto">
            <table className="table table-compact">
              <thead>
                <tr>
                  <th colSpan={4}>
                    Cliente:{devoluciones[0]?.cliente?.nombre}
                  </th>
                </tr>
                <tr>
                  <th>FECHA</th>
                  <th>ACOPIO</th>
                  <th>CANT. DEVOLUCION</th>
                  <th>DETALLES</th>
                </tr>
              </thead>
              <tbody>
                {devoluciones?.map((devolucion) => (
                  <tr>
                    <td>{devolucion?.fecha}</td>
                    <td>{devolucion?.acopio?.nombre}</td>
                    <td>{devolucion?.cantidad}</td>
                    <td>
                      <button className="btn btn-link">Detalles prueba</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button className="btn">Exportar Excel</button>
          </div>
        ) : (
          <>
            No existen devoluciones para el cliente y rango de fecha
            seleccionados
          </>
        )}
      </div>
    </div>
  );
}

export default ReporteDevolucionesCliente;
