import React, { useEffect, useRef, useState } from "react";
import { makeSafeGetRequest } from "./utils/Requests";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import { ToastContainer } from "react-toastify";
import {
  dateToColombianZoneRq,
  timeToColombianZonerq,
} from "./utils/timezoneRequests";
import useAuth from "../hooks/useAuth";
import * as XLSX from "xlsx";
function ReporteProductoresEmpresa(props) {
  const { auth } = useAuth();
  const { empresa, nombre } = props;
  const [fecha, setFecha] = useState();
  const [hora, sethora] = useState();
  const [productores, setProductores] = useState([]);
  const [fechaInicial, setFechaInicial] = useState();
  const [fechaFinal, setFechaFinal] = useState();
  //userefs tablas
  const tableRef = useRef(null);
  const [deshabilitado, setDeshabilitado] = useState(false);

  const getProductores = async () => {
    setDeshabilitado(true);
    const notify = notifyPendingNotify();
    setFecha(await dateToColombianZoneRq());
    sethora(await timeToColombianZonerq());
    try {
      const response = await makeSafeGetRequest(
        "recoleccionesempresa?idempresa=" +
          empresa +
          "&fechainicial=" +
          fechaInicial +
          "&fechafinal=" +
          fechaFinal,
        auth.accessToken
      );
      if (response && response?.status === 200 && response?.data) {
        setProductores(response?.data);

        popNotification(notify, "success", "Consulta terminada");
      } else {
        setProductores();
        popNotification(notify, "error", "No se pudo realizar la consulta");
      }
    } catch (error) {
      console.log("Error al obtener detos de productores: " + error);
      popNotification(notify, "error", "Error. Consulta no terminada.");
    }
    setDeshabilitado(false);
  };
  const onFechaInicial = (e) => {
    setFechaInicial(e.target.value);
  };
  const onFechaFinal = (e) => {
    const selectedFechaFinal = new Date(e.target.value);
    const selectedFechaInicial = new Date(fechaInicial);

    // Calculate the difference in months
    const diffInMonths =
      (selectedFechaFinal.getFullYear() - selectedFechaInicial.getFullYear()) *
        12 +
      selectedFechaFinal.getMonth() -
      selectedFechaInicial.getMonth();

    if (diffInMonths <= 2) {
      setFechaFinal(e.target.value);
    } else {
      alert(
        "La fecha final no puede ser más de 2 meses después de la fecha inicial."
      );
      setFechaFinal();
    }
  };
  const exportToExcel = () => {
    console.log("exprotando")
    const ws = XLSX.utils.json_to_sheet(
      productores.map((prod) => ({
        DOCUMENTO: prod.numero_documento,
        PRODUCTOR: prod.nombre_corto,
        TELEFONO: prod.telefono,
        ACOPIO: prod.ruta?.acopio?.nombre,
        RUTA: prod.ruta?.nombre,
        ACTIVO: prod.activo?"SI":"NO",
        ...prod.recolecciones.reduce((acc, recoleccion) => {
          acc[recoleccion.fecha] = recoleccion.cantidad_leche;
          return acc;
        }, {}),
      }))
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Productores");
    XLSX.writeFile(wb, "ReporteProductores.xlsx");
  };

  //useEffect inicial
  useEffect(() => {
    console.log("cambio productores" + JSON.stringify(productores));
  }, [productores]);

  useEffect(() => {
    if (empresa) {
    }
  }, []);

  return (
    <div className="h-full w-full ">
      <div className="grid grid-cols-1 w-full">
        <label className="label">Fecha inicial</label>
        <input
          type={"date"}
          className="input input-bordered"
          onChange={(e) => onFechaInicial(e)}
        ></input>
        <label className="label">Fecha Final</label>
        <input
          type={"date"}
          className="input input-bordered"
          onChange={(e) => onFechaFinal(e)}
        ></input>
        <div className="flex flex-row m-1 p-1 justify-around">
          <button
            className="btn btn-accent w-2/5"
            disabled={deshabilitado}
            onClick={getProductores}
          >
            Generar reporte
          </button>
          {productores ? (
            <button className="btn btn-active w-2/5" onClick={exportToExcel}>
              Exportar Excel
            </button>
          ) : (
            <></>
          )}
        </div>
        {productores && productores.length > 0 && (
          <>
            <div className="overflow-x-auto w-full">
              <table className="table table-compact " ref={tableRef}>
                <thead>
                  <tr>
                    <th></th>
                    <th>Empresa: {nombre}</th>
                    <th>Fecha: {fecha}</th>
                    <th>Hora: {hora}</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th>DOCUMENTO</th>
                    <th>PRODUCTOR</th>
                    <th>TELEFONO</th>
                    <th>ACOPIO</th>
                    <th>RUTA</th>
                    <th>ACTIVO</th>
                    {productores[0]?.recolecciones?.map((r) => (
                      <th>{r.fecha}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {productores?.map((prod) => (
                    <tr>
                      <td></td>
                      <td>{prod.numero_documento}</td>
                      <td>{prod.nombre_corto}</td>
                      <td>{prod.telefono}</td>
                      <td>{prod.ruta?.acopio?.nombre}</td>
                      <td>{prod.ruta?.nombre}</td>
                      <td>{prod.activo?"SI":"NO"}</td>
                      {prod?.recolecciones?.map((recoleccion) => (
                        <td key={recoleccion?.fecha}>
                          {recoleccion.cantidad_leche}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default ReporteProductoresEmpresa;
