import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { getEmpresaAdmin } from "../api/RoleInfo";
import useAuth from "../hooks/useAuth";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import { makeSafeGetRequest, makeSafePostRequest } from "./utils/Requests";
import {
  dateToColombianZoneRq,
  timeToColombianZonerq,
} from "./utils/timezoneRequests";

function CreatePedido(props) {
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState();
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [productos, setProductos] = useState();
  const [searchedProductos, setSearchedProductos] = useState();
  const [selProducto, setSelProducto] = useState();
  const [cartItems, setCartItems] = useState([]);
  const [selCartItem, setSelCartItem] = useState();
  const [idProd]= useState(props.idProd);
  const [idRecolector]=useState(props.idRecolector)
  const [prodNombre]= useState(props.prodNombre);
  const [cantidad, setCantidad] = useState(0);
  const [observaciones, setObservaciones] = useState("");
  const [obsPedido, setObsPedido] = useState("");
  //obtiene los datos de la empresa
  const getEmpresa=async()=>{
    const response= await getEmpresaAdmin(auth);
    if(response){
      setIdEmpresa(response?.empresa?.id)
      setNombreEmpresa(response?.empresa.nombre)
    }
  }
  //Obtiene la lista de productores
  const getProductos = async () => {
    try {
      const response = await makeSafeGetRequest(
        "productosempresa/" + idEmpresa,
        auth
      );
      if (response) {
        setProductos(response.data);
      } else {
        setProductos(null);
      }
    } catch (error) {
      console.log("Error al obtener los productores;" + error);
    }
  };
  //Realiza lla busqueda entre los productos, usando el termino de busqueda digitado
  const onSearch = (e) => {
    const term = e.target.value;
    if (term === "" || !term) {
      setSearchedProductos();
    } else {
      const filteredItems = productos.filter((prod) => {
        return prod.nombre.toUpperCase().includes(term.toUpperCase());
      });
      setSearchedProductos(filteredItems.slice(0, 10));
    }
  };
  //Realiza el cambio de producto seleccionado
  const onProductoChange = (idProducto) => {
    console.log(typeof idProducto);
    const selProd = productos.filter((p) => {
      return p.id === parseInt(idProducto);
    });
    console.log(JSON.stringify(...selProd));
    setSelProducto(selProd);
    setSearchedProductos();
  };
  //Limpia el objeto de producto seleccionado y los inputs de cantidad y observaciones
  const clearSelectedItemAdded = () => {
    setSelProducto();
    setObservaciones("");
    setCantidad(0);
  };
  //Determina lo que ocurre cuando se agrega un item al stage de pedido
  const onItemAdded = () => {
    const itemExiste = itemExisteCart();
    console.log(itemExiste);
    if (!itemExiste) {
      const idProducto = selProducto[0].id;
      const nombreProd = selProducto[0].nombre;
      const item = {
        producto_agricola: { id: idProducto },
        nombre: nombreProd,
        cantidad: cantidad,
        observaciones: observaciones,
        pedido: null,
      };
      const prevItems = [...cartItems, item];

      setCartItems(prevItems);
      clearSelectedItemAdded();
    } else {
      console.log("ya existe en el carrito");
    }
  };
  //verifica si un item existe en el carrito
  const itemExisteCart = () => {
    const existe = cartItems.some(
      (item) => item?.producto_agricola.id === selProducto[0].id
    );
    console.log(existe);
    return existe;
  };
  //ACTUALIZA UN ITEM DEL CARRITO DE PEDIDO
  const editItemPedido = () => {
    console.log("item seleccionado:" + JSON.stringify(selCartItem));
    console.log("states" + cantidad + " ," + "observaciones" + observaciones);
    let cart;
    console.log(JSON.stringify(cart));
    cart = cartItems.map((item) => {
      if (item.producto_agricola.id === selCartItem.producto_agricola.id) {
        item.cantidad = cantidad;
        item.observaciones = observaciones;
      }
    });
    setCantidad(0);
    setObservaciones("");
    console.log("Actualizaoo" + JSON.stringify(cart[0]));
  };
  //Controla las acciones a seguir cuando se da clic en el boton editar de los pedidos
  const onClickEditItem = (item) => {
    console.log("Probando onclick" + JSON.stringify(item));
    setSelCartItem(item);
    setCantidad(item["cantidad"]);
    setObservaciones(item["observaciones"]);
    console.log("item obs" + item.observaciones);
  };
  //Borra el item seleccionado
  const deleteItemPedido = () => {
    console.log("borrando intem");
    let cart = cartItems.filter(
      (item) => item.producto_agricola.id !== selCartItem.producto_agricola.id
    );
    console.log(JSON.stringify(cart));
    setCartItems(cart);
    clearSelectedItemAdded();
  };
  //Guarda el pedido, compuesto de un pedido asociado a multiples productos pedidos con sus cantidades
  const savePedido = async () => {
    try {
      const notify = notifyPendingNotify();
      console.log("probando boton");
      const idPedido = await postPedido(notify);
      console.log(idPedido);
      if (idPedido) {
        await postItemsPedido(idPedido, notify);
        clearCart();
      }
    } catch (error) {}
  };
  //postea el encabezado del pedido y retorn su id
  const postPedido = async (notify) => {
    try {
      const fecha = await dateToColombianZoneRq();
      const hora = await timeToColombianZonerq();
      console.log("id del productor"+idProd);
      console.log("HORA" +hora+ "FECHA" + fecha);
      const data = {
        data: {
          fecha: fecha,
          hora: hora,
          observaciones: obsPedido,
          estado: "ACTIVO",
          estado_pedido:"ACTIVADO",
          productor: idProd,
          recolector:idRecolector
        },
      }; // reemplazar las observaciones en blanco y el id del productor
      const response = await makeSafePostRequest(
        "pedidos",
        data,
        auth.accessToken
      );
      if (response) {
        popNotification(
          notify,
          "success",
          "Pedido creado. Agregando productos..."
        );
        console.log(response.data.data.id);
        return response.data.data.id;
      } else {
        popNotification(notify, "error", "No se pudo realiza el pedido");
        console.log("No hay respuesta");
        return null;
      }
    } catch (error) {
      console.log("error al postear el pedido:" + error);
    }
  };
  //Postea los items individuales del pedido
  const postItemsPedido = async (idPedido, notify) => {
    console.log("Probando items pedido");
    console.log("items cart:" + JSON.stringify(cartItems));
    let ci = cartItems;
    for (const item of ci) {
      try {
        console.log("item: .." + JSON.stringify(item));
        item.pedido = idPedido;
        const data = { data: { ...item } };
        console.log(JSON.stringify(data));
        const response = await makeSafePostRequest(
          "producto-pedidos",
          data,
          auth.accessToken
        );
        if (response) {
          popNotification(
            notify,
            "success",
            "Item " + item.nombre + " agregado correctamente"
          );
        } else {
          popNotification(
            notify,
            "error",
            "Item " + item.nombre + " no agregado"
          );
        }
      } catch (error) {
        console.log("Error al postear el item:" + error);
        popNotification(
          notify,
          "error",
          "Error: Item " + item.nombre + " no agregado"
        );
      }
    }
  };
  //Limpia los items del carrito de compras al hacer una venta
  const clearCart = () => {
    setCartItems([]);
  };
  useEffect(()=>{
    console.log("cambio en productor:"+prodNombre+idProd);
  },[idProd, prodNombre]);

//useeffect inicial  
useEffect(()=>{
  getEmpresa()
},[])
useEffect(() => {
  console.log("cambio en el carrito de compras:" + JSON.stringify(cartItems));
}, [cartItems]);
//UseEffect obtener productos
  useEffect(() => {
    if (idEmpresa) {
      getProductos();
    }
  }, [idEmpresa]);
  useEffect(() => {
    console.log(
      "Cambio en producto selccionado:" + JSON.stringify(selProducto)
    );
  }, [selProducto]);
  useEffect(() => {
    console.log("cambio en obs y/o cant");
  }, [observaciones, cantidad]);
  return (
    <div className="section h-full py-5 mx-2  w-full">
      <div className="grid grid-cols-1">
        <h1 className="text-3xl">Generar Pedido</h1>
        <label className="label">Seleccione un producto</label>
        <input
          type={"search"}
          className="input input-bordered"
          onChange={(e) => onSearch(e)}
        ></input>
        {searchedProductos ? (
          <ul tabIndex={0} className="dropdown-content menu p-2 shadow w-full ">
            {searchedProductos.map((p) => (
              <li
                key={p.id}
                value={p.id}
                onClick={(e) => {
                  onProductoChange(p.id);
                }}
              >
                <div className="flex justify-between text-left shadow-sm">
                  <div>
                    <p className=" text text-left">
                      {p.id + " -"}
                      {p.nombre}
                    </p>
                  </div>
                  <div>
                    <p className="text-xs text-left">
                      {p.presentacion}
                      <br /> <span>Marca: </span>
                      {p.marca}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <></>
        )}
        {/* Modulo de producto seleccionado */}
        {selProducto ? (
          <div className="card card-compact my-3 shadow-xl">
            <div className="card-body text-left" key={selProducto[0].id}>
              <h2 className="card-title">Producto Seleccionado</h2>

              <p>
                <span className="font-bold">{selProducto[0].nombre}</span>
              </p>
              <p>
                <span className="font-bold">Presentación: </span>
                {selProducto[0].presentacion}
              </p>
              <p>
                <span className="font-bold">Marca: </span>
                {selProducto[0].marca}
              </p>
              <p>
                <span className="font-bold">Categoría: </span>
                {selProducto[0].grupo}
              </p>
              <p>
                <span className="font-bold">Observaciones: </span>
                {selProducto[0].observaciones}
              </p>
            </div>
            <div className="grid grid-cols-1 m-3">
              <label className="label">Cantidad</label>
              <input
                type={"number"}
                className="input input-bordered"
                min={0}
                step={1}
                value={cantidad}
                onChange={(e) => setCantidad(e.target.value)}
              ></input>
              <label className="label">Observaciones</label>
              <input
                type={"text"}
                className="input input-bordered"
                value={observaciones}
                onChange={(e) => setObservaciones(e.target.value)}
              ></input>
              <button
                className="btn btn-accent my-2"
                onClick={() => onItemAdded()}
              >
                Agregar
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="divider"></div>
        <h1 className="text-xl font-bold">Detalles del pedido</h1>
        <div>
          {cartItems && cartItems.length > 0 ? (
            <>
            <div className="overflow-x-auto overflow-y-auto justify-center">
              <table className="table table-compact w-full">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Cantidad</th>
                    <th>Obs. pedido</th>
                    <th>Acciones</th>
                  </tr>
                </thead>

                <tbody>
                  {cartItems?.map((item) => (
                    <tr
                      key={item?.producto_agricola.id}
                      value={item?.producto_agricola.id}
                    >
                      <th title={item.nombre}>
                        {item.nombre.length > 20 ? (
                          <>{item.nombre.slice(0, 20) + "..."}</>
                        ) : (
                          <>{item.nombre}</>
                        )}
                      </th>
                      <td>{item.cantidad}</td>
                      <td title={item.observaciones}>
                        {item.observaciones.length > 20 ? (
                          <>{item.observaciones.slice(0, 20) + "..."}</>
                        ) : (
                          <>{item.observaciones}</>
                        )}
                      </td>
                      <td>
                        <div className="flex justify-around">
                          <label
                            className="btn btn-sm  btn-warning"
                            htmlFor="modalEdit"
                            onClick={() => {
                              onClickEditItem(item);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                              />
                            </svg>
                          </label>
                          <label
                            className="btn  btn-sm btn-error"
                            htmlFor="modalDelete"
                            onClick={() => onClickEditItem(item)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                              />
                            </svg>
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex flex-col">
                <label className="label">Observaciones del Pedido</label>
                <textarea
                  className="textarea textarea-bordered"
                  value={obsPedido}
                  onChange={(e) => setObsPedido(e.target.value)}
                ></textarea>
              <label className="btn btn-active my-3" htmlFor="modalSave">
                Crear pedido
              </label>
              </div>
            </>
          ) : (
            <div className="alert alert-success shadow-md">
              Agregue un producto de la barra de Búsqueda
            </div>
          )}
        </div>
        {/* modal editar */}
        <input type="checkbox" id="modalEdit" className="modal-toggle" />
        <div className="modal">
          <div className="modal-box relative">
            <label
              htmlFor="modalEdit"
              className="btn btn-sm btn-circle absolute right-2 top-2"
            >
              ✕
            </label>
            <h3 className="text-lg font-bold">Editar Item de Pedido</h3>
            <p className="py-4">Ingrese los valores que desea cambiar</p>
            <div className="grid grid-cols-1">
              <p>
                <span className="font-bold">Nombre del producto: </span>
                {selCartItem?.nombre}
              </p>
              <label className="label">Cantidad</label>
              <input
                type={"number"}
                className="input input-bordered"
                min={0}
                step={1}
                value={cantidad}
                onChange={(e) => setCantidad(e.target.value)}
              ></input>
              <label className="label">Observaciones</label>
              <textarea
                className="textarea textarea-bordered"
                value={observaciones}
                onChange={(e) => setObservaciones(e.target.value)}
              ></textarea>
            </div>
            <div className="modal-action">
              <label
                className="btn btn-accent"
                htmlFor="modalEdit"
                onClick={(e) => editItemPedido()}
              >
                Editar
              </label>
              <label className="btn btn-active" htmlFor="modalEdit">
                Cancelar
              </label>
            </div>
          </div>
        </div>

        {/* modal eliminar */}
        <input type="checkbox" id="modalDelete" className="modal-toggle" />
        <div className="modal">
          <div className="modal-box relative">
            <label
              htmlFor="modalDelete"
              className="btn btn-sm btn-circle absolute right-2 top-2"
            >
              ✕
            </label>
            <h3 className="text-lg font-bold">Aviso!</h3>
            <p className="py-4">
              ¿Realmente desea borrar el siguiente producto del pedido?
            </p>
            <div className="grid grid-cols-1">
              <p>
                <span className="font-bold">Nombre del producto: </span>
                {selCartItem?.nombre}
              </p>
              <p>
                <span className="font-bold">Cantidad: </span>
                {selCartItem?.cantidad}
              </p>
              <p>
                <span className="font-bold">Nombre del producto: </span>
                {selCartItem?.observaciones}
              </p>
            </div>
            <div className="modal-action">
              <label
                className="btn btn-error"
                htmlFor="modalDelete"
                onClick={(e) => deleteItemPedido()}
              >
                Eliminar
              </label>
              <label className="btn btn-active" htmlFor="modalDelete">
                Cancelar
              </label>
            </div>
          </div>
        </div>
        {/* Modal guardar */}
        <input type="checkbox" id="modalSave" className="modal-toggle" />
        <div className="modal">
          <div className="modal-box relative">
            <label
              htmlFor="modalSave"
              className="btn btn-sm btn-circle absolute right-2 top-2"
            >
              ✕
            </label>
            <h3 className="text-lg font-bold">Aviso!</h3>
            <p className="py-4">
              ¿Realmente desea generar el siguiente pedido?
            </p>
            <p className=""><span className="font-bold">Nombre del productor: </span>{prodNombre}</p>
            <p>
              Verifique los productos pedidos y su cantidad antes de realizarlo.
            </p>
            <div className="grid grid-cols-1">
              <table className="table table-compact">
                <thead></thead>
                <tbody></tbody>
              </table>
            </div>
            <div className="modal-action">
              <label
                className="btn btn-accent"
                htmlFor="modalSave"
                onClick={() => savePedido()}
              >
                Sí
              </label>
              <label className="btn btn-active" htmlFor="modalSave">
                Aún no
              </label>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default CreatePedido;
