import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { ToastContainer } from "react-toastify";
import { makeSafeGetRequest, makeSafePutRequest } from "./Requests";
import { notifyPendingNotify, popNotification } from "./Notify";
import { attributeIsValid } from ".//Validators";

function AbstractAdminEdit(props) {
  const [idEmpresa, setIdEmpresa] = useState(props.idEmpresa);
  const [empresasList] = useState(props.empresasList);
  const [nombreEmpresa] = useState(props.nombreEmpresa);
  const [validator, setValidator] = useState({});
  const [role] = useState(props.rol);
  const { auth } = useAuth();
  const [objectName] = useState(props.objectName);
  //nombre de la variable que contiene el nombre del objeto

  const [config] = useState({ ...props.config });
  const [query] = useState(props.query);
  const [updateQuery]=useState(props.updateQuery);
  const [selections, setSelections] = useState(props.selections);
  //item a editar
  const [item] = useState(props.item);
  const [editedItem, setEditedItem] = useState();
  const [isPosteable, setIsPosteable] = useState(false);

  //FUNCIONES

  //cambio en la propiedad de un item
  const onItemChange = (key, newValue) => {
    console.log("cambio en una propiedad: " + key + " " + newValue);
    console.log("cambio" + JSON.stringify(config[key]));
    const newItem = { ...editedItem };
    newItem[key] = newValue;
    setEditedItem(newItem);
  };

  //Valida el dato de un item
  const validateAttribute = (key, value) => {
    let valid = attributeIsValid(key, value, config);
    return valid;
  };
  //valida todo el item editado para verificar que los campos obligatorios estén diligenciados
  const validateCompleteItem = () => {
    let valid = true;
    let newValidator = {};
    for (const [key, value] of Object.entries(config)) {
      valid = validateAttribute(key, editedItem[key]);
      newValidator[key] = valid;
      console.log("validando items" + JSON.stringify(newValidator));
    }
    setValidator(newValidator);
  };
  //verifica si el item tiene atributos invalidos
  const hasInvalidAttributes = () => {
    return Object.values(validator).some((value) => value === false);
  };
  //valida el atributo de un item para verificar si está diligenciado correctamente
  const validateItemAttribute = (key, value) => {
    console.log("onblur called" + key + value);
    let newValidator = { ...validator };
    newValidator[key] = validateAttribute(key, value);
    console.log("new validator" + JSON.stringify(newValidator));
    setValidator(newValidator);
  };

  //Actualiza el item seleccionado
  const updateData = async () => {
    const notify = notifyPendingNotify();
    //valida el item antes de actualizar
    validateCompleteItem();
    //vewrifica si el item NO tiene atributos invalidos
    if (!hasInvalidAttributes()) {
      try {
        const data = { data: { ...editedItem, empresa: idEmpresa } };
        console.log(JSON.stringify(data));
        console.log("verificando updateQuery" + updateQuery);
        const response = await makeSafePutRequest(
          (updateQuery || objectName.formal)+ "/" + editedItem.id,
          data,
          auth.accessToken
        );
        if (response.status === 200) {
          popNotification(
            notify,
            "success",
            objectName.informal + " actualizado correctamente."
          );
        } else {
          popNotification(
            notify,
            "error",
            "No se pudo actualizar el " +
              objectName.informal +
              ". Verifique los datos e intente de nuevo."
          );
        }
      } catch (error) {
        popNotification(
          notify,
          "error",
          "Error al actualizar " + objectName.informal
        );
      }
    } else {
      popNotification(
        notify,
        "error",
        "Datos incompletos del " +
          objectName.informal +
          ". Verifique e intente de nuevo."
      );
    }
  };
  //consulta y carga a las selecciones dependientes
  const loadDependentSelections = async (dependantkey, value) => {
    console.log(
      "cargando selecciones dependientes" +
        dependantkey +
        JSON.stringify(selections)
    );
    const dependentAttributes = [];
    for (const [key, value] of Object.entries(config)) {
      if (
        value.type === "selection" &&
        value.dependant === true &&
        value.dependantField === dependantkey
      ) {
        console.log("cargando selecciones dependientes");
        dependentAttributes.push({ key, ...value });
      } else {
        console.log("atributo" + JSON.stringify(value));
      }
    }
    console.log("atributos dependientes" + JSON.stringify(dependentAttributes));
    await fetchSelections(dependentAttributes, value);
  };
  const fetchSelections = async (dependentAttributes, value) => {
    try {
      for (const attribute of dependentAttributes) {
        console.log("fetching key" + JSON.stringify(attribute));
        const response = await makeSafeGetRequest(
          attribute.query + "/" + value,
          auth.accessToken
        );
        if (response && response.status === 200) {
          console.log("peticion exitosa" + JSON.stringify(response.data));
          console.log(
            "cargando data en selections" + JSON.stringify(selections)
          );
          const newSelection = { [attribute.key]: response.data };
          console.log("nueva seleccion" + JSON.stringify(newSelection));
          setSelections((prevSelections) => ({
            ...prevSelections,
            ...newSelection,
          }));
        }
      }
    } catch (error) {}
  };

  //useeffect cambio objeto
  useEffect(() => {
    console.log("cambio de item");
  }, [editedItem]);
  //useeffect recarga de productor y verifica si los valores boolean del item a editar son nulos o indefinidos
  useEffect(() => {
    let updatedItem = { ...props.item };
    for (const [key, value] of Object.entries(config)) {
      if (
        value.type === "boolean" &&
        (updatedItem[key] === undefined || updatedItem[key] === null)
      ) {
        console.log(
          "actualizando estados boolean:" +
            updatedItem +
            " " +
            key +
            " " +
            value
        );
        updatedItem[key] = false;
      }
    }
    setEditedItem(updatedItem);
  }, [props.item]);
  //useEffect validador
  useEffect(() => {
    console.log("validador" + JSON.stringify(validator));
  }, [validator]);
  //useeffect inicial
  useEffect(() => {}, []);

  return (
    <div className="grid grid-cols-1  py-3">
      <h3 className="text-xl text-left font-bold">
        Editar {objectName.informal}
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-5">
        {item && editedItem && config ? (
          <>
            {role === "superadministrador" && empresasList ? (
              <div className="grid grid-cols-1">
                <label className="label">Empresa</label>
                <select
                  className="select select-bordered"
                  value={idEmpresa}
                  onChange={(e) => setIdEmpresa(e.target.value)}
                >
                  <option value={"-1"}>{"Seleccione una empresa"}</option>
                  {empresasList.map((empresa) => (
                    <option key={empresa.id} value={empresa.id}>
                      {empresa?.attributes?.nombre}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <></>
            )}
            {Object.entries(config).map(([key, value]) => (
              <div className="flex flex-col" key={key}>
                <label className="label">
                  {value.label + " "}
                  {value.mandatory === true ? (
                    <span className="text-error">* Obligatorio</span>
                  ) : (
                    <></>
                  )}{" "}
                </label>
                {
                  /* Cuando es booleano */
                  value.type === "boolean" ? (
                    <div className="flex justify-around">
                      <span>No</span>
                      <input
                        type="checkbox"
                        className="toggle toggle-accent"
                        checked={editedItem[key] || false}
                        onChange={(e) => {
                          onItemChange(key, e.target.checked);
                        }}
                        onBlur={(e) =>
                          validateItemAttribute(key, e.target.checked)
                        }
                      ></input>
                      <span>Si</span>
                      {value.mandatory === true && validator[key] === false ? (
                        <label className="label label-text-alt text-warning">
                          Seleccione una opción
                        </label>
                      ) : (
                        <></>
                      )}
                      <label className="label label-text-alt"></label>
                    </div>
                  ) : /*cuando es un numero entero */
                  value.type === "int" ? (
                    <>
                      <input
                        type="number"
                        min={0}
                        step={1}
                        className="input input-bordered"
                        value={editedItem[key] || ""}
                        onChange={(e) => onItemChange(key, e.target.value)}
                        onBlur={(e) =>
                          validateItemAttribute(key, e.target.value)
                        }
                      ></input>
                      {value.mandatory === true && validator[key] === false ? (
                        <label className="label label-text-alt text-warning">
                          Digite un número
                        </label>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : /*cuando es un numero decimal */
                  value.type === "double" ? (
                    <>
                      <input
                        type="number"
                        min={0}
                        step={0.1}
                        className="input input-bordered"
                        value={editedItem[key] || ""}
                        onChange={(e) => onItemChange(key, e.target.value)}
                        onBlur={(e) =>
                          validateItemAttribute(key, e.target.value)
                        }
                      ></input>
                      {value.mandatory === true && validator[key] === false ? (
                        <label className="label label-text-alt text-warning">
                          Digite un número
                        </label>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : /*cuando es un telefono */
                  value.type === "phone" ? (
                    <>
                      <input
                        type="tel"
                        className="input input-bordered"
                        pattern="(\+\d{1,2}\s?)?((\(\d{3}\)|\d{3})(\s|-)?)?\d{3}(\s|-)?\d{4}"
                        value={editedItem[key] || ""}
                        onChange={(e) => onItemChange(key, e.target.value)}
                        onBlur={(e) =>
                          validateItemAttribute(key, e.target.value)
                        }
                      ></input>
                      {value.mandatory === true && validator[key] === false ? (
                        <label className="label label-text-alt text-warning">
                          Digite un {value.label}
                        </label>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : /*cuando es un email */
                  value.type === "email" ? (
                    <>
                      <input
                        type="email"
                        className="input input-bordered"
                        value={editedItem[key] || ""}
                        onChange={(e) => onItemChange(key, e.target.value)}
                        onBlur={(e) =>
                          validateItemAttribute(key, e.target.value)
                        }
                      ></input>
                      {value.mandatory === true && validator[key] === false ? (
                        <label className="label label-text-alt text-warning">
                          Digite un correo electrónico
                        </label>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : /*cuando es un texto */
                  value.type === "string" ? (
                    <>
                      <input
                        type="text"
                        className="input input-bordered"
                        value={editedItem[key] || ""}
                        onChange={(e) => onItemChange(key, e.target.value)}
                        onBlur={(e) =>
                          validateItemAttribute(key, e.target.value)
                        }
                      ></input>
                      {value.mandatory === true && validator[key] === false ? (
                        <label className="label label-text-alt text-warning">
                          Digite un {value.label}
                        </label>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : /*cuando es un texto */
                  value.type === "largeString" ? (
                    <>
                      <textarea
                        type="text"
                        className="textarea textarea-bordered"
                        value={editedItem[key] || ""}
                        onChange={(e) => onItemChange(key, e.target.value)}
                        onBlur={(e) =>
                          validateItemAttribute(key, e.target.value)
                        }
                      ></textarea>
                      {value.mandatory === true && validator[key] === false ? (
                        <label className="label label-text-alt text-warning">
                          Digite un {value.label}
                        </label>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : /*cuando es una fecha */
                  value.type === "date" ? (
                    <>
                      <input
                        type="date"
                        className="input input-bordered"
                        value={editedItem[key] || ""}
                        onChange={(e) => onItemChange(key, e.target.value)}
                        onBlur={(e) =>
                          validateItemAttribute(key, e.target.value)
                        }
                      ></input>
                      {value.mandatory === true && validator[key] === false ? (
                        <label className="label label-text-alt text-warning">
                          Digite un {value.label}
                        </label>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : /*cuando es una contraseña */
                  value.type === "password" ? (
                    <>
                      <input
                        type="password"
                        className="input input-bordered"
                        value={editedItem[key] || ""}
                        onChange={(e) => onItemChange(key, e.target.value)}
                        onBlur={(e) =>
                          validateItemAttribute(key, e.target.value)
                        }
                      ></input>
                      {value.mandatory === true && validator[key] === false ? (
                        <label className="label label-text-alt text-warning">
                          Digite un {value.label}
                        </label>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : 
                  // cuando es una seleccion
                  value.type === "selection" ? (<>
                  
                    <select
                      className="select select-bordered"
                      onChange={(e) => {
                        onItemChange(key, e.target.value);
                      }}
                      onBlur={(e) => {
                        validateItemAttribute(key, e.target.value);
                        loadDependentSelections(key, e.target.value);
                      }}
                      value={editedItem[key]?.id ? editedItem[key].id.toString() : editedItem[key] || ""}
                    >
                      <option value={""}>Seleccione una opción</option>
                      {/* Determina si las opciones son fijas o dinamicas */}
                      {value.query === null ? (<>
                        {Object?.entries(value.options)?.map(([key, value]) => (
                          <option key={value} value={value}>
                            {key }
                          </option>
                        ))}
                      </>
                      ) : (
                        <>
                          {selections[key] ? (
                          selections[key]?.map((selection) => (
                            <option key={selection.id} value={selection.id}>
                              {selection.nombre|| selection.nombre_corto||selection.nombre_banco}
                            </option>
                          ))
                        ) : (
                            <option value={""}>Cargando...</option>
                          )}
                        </>
                      )}
                    </select>
                      {value.mandatory === true && validator[key] === false ? (
                        <label className="label label-text-alt text-warning">
                          Seleccione una opción
                        </label>
                      ) : (
                        <></>
                      )}
                    <div className="flex mx-2">
                      
                    </div>
                  </>
                  ) : (
                    <></>
                  )
                }
              </div>
            ))}
            <label
              className="btn btn-accent md:col-span-2"
              htmlFor="modal-confirm"
            >
              Guardar {objectName.informal}
            </label>
          </>
        ) : (
          <></>
        )}
        {/* {config ? <>{Object.entries(config).map(([key, value]) => (<div className='bg-primary'>{key + value}</div>))}</> : <div className='bg-error'>no hay que mapear</div>} */}
      </div>
      {/* Modal de confirmar */}
      <input type="checkbox" id="modal-confirm" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Aviso</h3>
          <p className="py-4">
            Realmente desea actualizar el {objectName.informal} con los
            siguientes datos?
          </p>
          <p>
            <span className="font-semibold">Empresa:</span>
            {nombreEmpresa}
          </p>
          {editedItem ? (
            <>
              {Object.entries(config).map(([key, value]) =>
                value.type === "boolean" ? (
                  <p key={key}>
                    <span className="font-semibold">{value.label + ": "}</span>
                    {editedItem[key] === true ? (
                      <span className="text-success">SI</span>
                    ) : (
                      <span className="text-error">NO</span>
                    )}
                  </p>
                ) : value.type === "selection" ? (
                  <p key={key}>
                    <span className="font-semibold">{value.label + ": "}</span>
                    {Array.isArray(selections[key])
                      ? (() => {
                          const selectedItem = selections[key].find(
                            (item) => item.id === Number(editedItem[key]) // Convert editedItem[key] to a number
                          );
                          return selectedItem ? selectedItem.nombre : "N/A";
                        })()
                      : "N/A"}
                  </p>
                ) : (
                  <p key={key}>
                    <span className="font-semibold">{value.label + ": "}</span>
                    {editedItem[key]}
                  </p>
                )
              )}
            </>
          ) : (
            <div className="alert alert-warning">
              No encontrado. Configurar vista
            </div>
          )}

          <div className="modal-action">
            <label
              htmlFor="modal-confirm"
              className="btn btn-accent"
              onClick={updateData}
            >
              Actualizar productor
            </label>
            <label htmlFor="modal-confirm" className="btn">
              Aún no
            </label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default AbstractAdminEdit;
