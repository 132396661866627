import React, { useEffect, useRef, useState } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import { makeGetRequest, makeSafeGetRequest } from "./utils/Requests";
import { getEmpresaAdmin } from "../api/RoleInfo";
import useAuth from "../hooks/useAuth";

function ReporteVentasCliente() {
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState();
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [ventasClienteRango, setVentasClienteRango] = useState();
  const [selectedVenta, setSelectedVenta] = useState();

  const [clientes, SetClientes] = useState();
  const [searchedClientes, setSearchedClientes] = useState();
  const [selectedCliente, setSelectedClient] = useState();
  const [selClienteNombre, setSelClientNombre] = useState();
  const [fechaInicial, setFechaInicial] = useState();
  const [fechaFinal, setFechaFinal] = useState();
  const tableRef = useRef(null);

  const getEmpresa = async () => {
    try {

      const response = await getEmpresaAdmin(auth);
      if (response) {
        console.log("encontro datos de la empresa" + JSON.stringify(response));
        setIdEmpresa(response.empresa?.id);
        setNombreEmpresa(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };
  //obtiene la lista de clientes de la empresa a la que pertenece el administrador
  const getClientes = async () => {
    try {
      const response = await makeSafeGetRequest(
        "clientesempresa/" + idEmpresa,
        auth.accessToken
      );
      if (response) {
        SetClientes(response.data);
      } else {
        SetClientes();
      }
    } catch (error) {
      console.log("Error al obtener los clientes" + error);
    }
  };
  //Obtiena la lista de ventas que pertenecen a un cliente determinado en un rango de tiempo
  const getVentasClienteRango = async () => {
    try {
      const response = await makeGetRequest(
        "ventasclienterango?idcliente=" +
          selectedCliente +
          "&fechainicial=" +
          fechaInicial +
          "&fechafinal=" +
          fechaFinal
      );
      if (response) {
        setVentasClienteRango(response.data);
      } else {
        setVentasClienteRango();
      }
    } catch (error) {}
  };
  //Obtiene la venta seleccionada entre las obtenidas según el rango de fechas
  const getSelectedVenta = (idVenta) => {
    console.log("id de la venta" + idVenta);
    const selVenta = ventasClienteRango.find((venta) => {
      return venta.id === parseInt(idVenta);
    });
    console.log("venta seleccionada:" + JSON.stringify(selVenta));
    setSelectedVenta(selVenta);
  };
  //realiza la descarga del elemento table
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename:
      "ventas_" + selClienteNombre + "_" + fechaInicial + "_" + fechaFinal,
    sheet: "ventas",
  });
  //on search
  const onsearch = (searchTerm) => {
    if (searchTerm === "" || searchTerm === undefined) {
      setSearchedClientes();
    } else {
      const filteredItems = clientes.filter((cliente) => {
        return cliente.nombre.toUpperCase().includes(searchTerm.toUpperCase());
      });
      setSearchedClientes(filteredItems.slice(0, 10));
    }
  };
  //pasos a realizar al seleccionar un item buscado
  const onSelectedItem = (e) => {
    setSelectedClient(e.target.value);
    setSelClientNombre(e.target.innerText);
  };

  //useeffect inicial
  useEffect(() => {
    getEmpresa();
  }, []);
  //useeffect aco
  useEffect(() => {
    if (idEmpresa) {
      getClientes();
    }
  }, [idEmpresa]);

  return (
    <div className="h-full w-3/4 py-5">
      <div className="grid grid-cols-1">
        <h1 className="text-3xl">Ventas por rango de fechas (cliente)</h1>
        <label className="label">Empresa:{nombreEmpresa}</label>
        <label className="label">Cliente</label>
        <div className="dropdown dropdown-bottom w-full">
          <label className="input-group w-max">
            <input
              type={"search"}
              className="input input-bordered  "
              onChange={(e) => onsearch(e.target.value)}
            ></input>
            <span>Buscar</span>
          </label>

          {searchedClientes ? (
            <ul
              tabIndex={0}
              className="dropdown-content menu p-2 shadow bg-base-100 w-52 "
            >
              {searchedClientes.map((cliente) => (
                <li
                  className="text-left"
                  key={cliente.id}
                  value={cliente.id}
                  tabIndex={0}
                  onClick={(e) => {
                    onSelectedItem(e);
                  }}
                >
                  {cliente.nombre}
                </li>
              ))}
            </ul>
          ) : (
            <></>
          )}
          {selClienteNombre ? (
            <div className="badge badge-lg my-1 py-1">
              {selClienteNombre}
              <span
                onClick={() => {
                  setSelClientNombre();
                  setSelectedClient();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </span>
            </div>
          ) : (
            <>seleccione un cliente</>
          )}
        </div>
        <label className="label">Fecha Inicial</label>
        <input
          type={"date"}
          className="input input-bordered"
          onChange={(e) => setFechaInicial(e.target.value)}
        ></input>
        <label className="label">Fecha Final</label>
        <input
          type={"date"}
          className="input input-bordered"
          onChange={(e) => setFechaFinal(e.target.value)}
        ></input>
        <button
          className="btn btn-accent py-3 my-3"
          onClick={getVentasClienteRango}
        >
          Generar Reporte
        </button>
        {ventasClienteRango && ventasClienteRango.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="table table-compact" ref={tableRef}>
              <thead>
                <tr>
                  <th></th>
                  <th>Fecha</th>
                  <th>Acopio</th>
                  <th>Cliente</th>
                  <th>Documento</th>
                  <th>Placa</th>
                  <th>Cantidad</th>
                  <th>Novedad</th>
                  <th>opciones</th>
                </tr>
              </thead>
              <tbody>
                {ventasClienteRango?.map((venta) => (
                  <tr key={venta.id} value={venta.id}>
                    <td></td>
                    <td>{venta?.fecha}</td>
                    <td>{venta?.acopio?.nombre}</td>
                    <td>{venta?.cliente?.nombre}</td>
                    <td>{venta?.cliente?.cedula_nit}</td>
                    <td>{venta?.placa_vehiculo}</td>
                    <td>{venta?.cantidad_leche}</td>
                    <td>
                      {venta?.venta_novedad === true ? (
                        <span className="text-warning">Sí</span>
                      ) : (
                        <span className="text-success">No</span>
                      )}
                    </td>
                    <td>
                      <label
                        className="btn btn-link"
                        htmlFor="modal-details"
                        onClick={() => getSelectedVenta(venta.id)}
                      >
                        Detalles
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button className="btn w-40" onClick={onDownload}>
              Exportar Excel
            </button>
          </div>
        ) : (
          <div className="alert alert-success">
            No hay ventas realizadas en el rango seleccionado
          </div>
        )}
      </div>

      {/* Modal de detalles */}
      <input
        type={"checkbox"}
        className="modal-toggle"
        id="modal-details"
      ></input>
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-xl mb-1">Detalles de la Venta</h3>
          {selectedVenta ? (
            <>
              <div className="grid grid-cols-2 gap-x-2 gap-y-1 text-left">
                <p>
                  <span className="font-bold">Fecha de venta: </span>
                  {selectedVenta?.fecha}
                </p>
                <p>
                  <span className="font-bold">Hora: </span>
                  {selectedVenta?.hora}
                </p>
                <p>
                  <span className="font-bold">Nombre del cliente: </span>
                  {selectedVenta?.cliente?.nombre}
                </p>
                <p>
                  <span className="font-bold">Placa del Vehículo: </span>
                  {selectedVenta?.placa_vehiculo}
                </p>
                <p>
                  <span className="font-bold">Observaciones: </span>
                  {selectedVenta?.observaciones}
                </p>
                <p>
                  <span className="font-bold">
                    Es novedad?:{" "}
                    {selectedVenta?.venta_novedad === true ? (
                      <span className="text-warning">Sí</span>
                    ) : (
                      <span className="text-success">No</span>
                    )}
                  </span>
                </p>
                <p>
                  <span className="font-bold">Total venta: </span>
                  {selectedVenta.cantidad_leche} litros
                </p>
              </div>
              <div className="divider "></div>
              <p className="font-bold">Compartimentos</p>
              <div className="stats stats-vertical shadow">
                {selectedVenta?.compartimentos?.map((comp) => (
                  <div className="stat" key={comp.id}>
                    <div className="stat-title">
                      Número de compartimento:{comp?.numero_compartimento}
                    </div>
                    <div className="stat-title">Cantidad de leche</div>
                    <div className="stat-value">
                      {comp?.cantidad_leche} litros.
                    </div>
                    <div className="stat-figure text-error">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                        />
                      </svg>
                      Temperatura:{comp.temperatura} °C
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="modal-action">
            <label htmlFor="modal-details" className="btn">
              Cerrar
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReporteVentasCliente;
