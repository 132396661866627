import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/axios";
import {
  dateToColombianZoneRq,
  timeToColombianZonerq,
} from "./utils/timezoneRequests";
import useAuth from "../hooks/useAuth";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import { makePostRequest, makeSafePostRequest } from "./utils/Requests";
import { checkLitros } from "./utils/CheckLitros";
import CreatePedido from "./CreatePedido";
import RecoleccionesCounter from "./RecoleccionesCounter";

function CreateRecollectionSafe() {
  const URL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { auth } = useAuth();

  //aqui va el state de rutasrecolector
  const [rutas, setRutas] = useState();
  const [productores, setProductores] = useState();

  //use states para hacer el submit
  const [selectedProd, setSelectProd] = useState();
  const [selectedProdNombre, setSelectProdNombre] = useState();
  const [selectedRuta, setSelectRuta] = useState();
  const [cantidadLeche, setCantidadLeche] = useState(0);
  const [observaciones, setObservaciones] = useState("");
  const [recorridoRuta, setRecorridoRuta] = useState();
  const [idRecolector, setIdRecolector] = useState();
  // const [disabled, setDisabled]= useState(false);
  const [btnsDisabled, setBtnsDisabled] = useState(false);

  //Obtiene el id del recolector del usuario logueado
  const getIdRecolector = async () => {
    try {
      console.log(
        "Id del usuario: " + auth.id + "\n nombre del usuario:" + auth.username
      );
      const url = URL + "recolectorusuario/" + auth.id;
      const response = await axios.get(`${url}`);
      console.log("DATOS DEL RECOLECTOR: " + JSON.stringify(response.data));
      setIdRecolector(response.data.id);
    } catch (error) {
      console.log(error);
    }
  };

  //Metodo para capturar las rutas del recolector
  const getRutas = async () => {
    try {
      console.log(
        "tengo sue;o pero estos son los datos de auth",
        JSON.stringify(auth)
      );
      const url = URL + "rutasrecolector/" + idRecolector;
      const response = await axios.get(`${url}`);
      console.log(
        "Rutas del recolector xdxd: \n" + JSON.stringify(response.data[0].rutas)
      );
      setRutas(response?.data[0].rutas);
    } catch (error) {
      console.log("Error al obtener las rutas:" + error);
    }
  };
  //LISTA DE LOS PRODUCTORES DE UNA RUTA CON RECOLECCIONES PENDIENTES
  const getProductores = async (idRuta) => {
    try {
      console.log("el id de la ruta es" + idRuta);
      console.log("Probando");
      const url = URL + "recoleccionespendientes/" + idRuta;
      const response = await axios.get(`${url}`);

      console.log(JSON.stringify(response.data));
      setProductores(response.data);
    } catch (error) {
      console.log("Error al obtener los productores de una ruta:" + error);
    }
  };

  //metodo para escribir la data en la api
  const postData = async () => {
    setBtnsDisabled(true);
    const colombianDate = await dateToColombianZoneRq();
    const colombianTime = await timeToColombianZonerq();
    const verifiedCantidad = checkLitros(cantidadLeche);
    const notify = notifyPendingNotify();
    if (verifiedCantidad === true) {
      try {
        if (selectedProd !== -1 && selectedProd) {
          console.log("Productor" + selectedProd);
          const resp = await makeSafePostRequest(
            `postrecoleccion`,
            {
              data: {
                fecha: colombianDate,
                hora: colombianTime,
                cantidad_leche: cantidadLeche,
                observaciones: observaciones,
                ruta: {
                  id: selectedRuta,
                },
                recolector: {
                  id: idRecolector,
                },
                productor: {
                  id: selectedProd,
                },
                recorrido: {
                  id: recorridoRuta,
                },
              },
            },
            auth.accessToken
          );
          console.log(resp);
          if (resp !== null && resp?.status === 200) {
            popNotification(notify, "success", "Recolección creada con éxito.");
            console.log("probando notificacion");
          } else {
            popNotification(
              notify,
              "error",
              "Recoleccion no realizada. Intente de nuevo"
            );
          }
          // unselect productor
          setSelectProd(-1);
          setSelectProdNombre("");
          //limpiar campos
        } else {
          popNotification(notify, "error", "Seleccione un productor.");
        }
      } catch (error) {
        console.log(error);
        popNotification(
          notify,
          "error",
          "Error: No se pudo crear la recolección"
        );
      }
    } else {
      popNotification(
        notify,
        "error",
        "Error: No se aceptan valores negativos."
      );
    }
    setBtnsDisabled(false);
  };
  //postea las recolecciones de los usuarios restantes en ceros
  const postMassiveData = async () => {
    const notify = notifyPendingNotify();
    try {
      const fecha = await dateToColombianZoneRq();
      const hora = timeToColombianZonerq();
      var success = 0,
        fail = 0;
      for (const p of productores) {
        const data = {
          data: {
            fecha: fecha,
            hora: hora,
            cantidad_leche: 0,
            observaciones: "Automáticamente puesto en ceros",
            ruta: { id: selectedRuta },
            recolector: { id: idRecolector },
            productor: { id: p.id },
            recorrido: { id: recorridoRuta },
          },
        };
        const response = await makePostRequest("recolecciones", data);
        if (response) {
          success++;
        } else {
          fail++;
        }
        popNotification(
          notify,
          "success",
          "recolecciones a cero. Exitosas:" + success + "; fallidas:" + fail
        );
      }
    } catch (error) {
      console.error("Error al guardar datos masivos:" + error);
      popNotification(notify, "error", "Error al guardar. Intente de nuevo.");
    }
    // unselect productor
    setSelectProd(-1);
  };
  //limpia los canmpos despues de una transacción exitosa
  const clearFields = () => {
    console.log("limpiando campos");
    const c = 0;
    const obs = "";
    setCantidadLeche(c);
    setObservaciones(obs);
    console.log(cantidadLeche + " blaaa " + observaciones);
  };

  //Cambia la lista de productores segun la ruta que se seleccione
  const changeProductoresRutas = async (idRuta) => {
    setBtnsDisabled(true);
    await getProductores(idRuta);

    console.log(selectedRuta + ": Ruta seleccionada");

    //obtenemos el recorrido de hoy o lo creamos
    await getRecorridoHoy();
    setBtnsDisabled(false);
  };
  //busca si hay un recorrido con la ruta indicada y fecha de hoy, y si no, lo crea
  const getRecorridoHoy = async () => {
    const url =
      URL +
      "recorridohoyporid?idrecolector=" +
      idRecolector +
      "&idruta=" +
      selectedRuta;
    const response = await axios.get(`${url}`);
    let idRecorridoHoy;
    //verificamos que la respuesta no esté vacia
    console.log(JSON.stringify(response.data));
    if (response.data === "") {
      console.log("No existe un recorrido con la ruta seleccionada");
      //crear nuevo recorrido con la ruta seleccionada y el recolector
      const resp = await createRecorridoRuta();
      await setRecorridoRuta(resp.data.id);
    } else {
      idRecorridoHoy = response.data.id;
      console.log("id del recorrido seleccionado: " + idRecorridoHoy);
      await setRecorridoRuta(idRecorridoHoy);
    }
  };
  //crea el recorrido de la ruta en caso de no existir y lo retorna
  const createRecorridoRuta = async () => {
    console.log("Entrando en la creacion de recorrido");
    try {
      const colombianDate = await dateToColombianZoneRq();
      const colombianTime = await timeToColombianZonerq();
      console.log(selectedRuta + "Esta es la ruta para el recorrido");
      const resp = await axios.post(URL + `recorridos`, {
        data: {
          fecha: colombianDate,
          hora_inicio: colombianTime,
          observaciones: "Recorrido hecho desde React " + colombianDate,
          recolector: {
            id: idRecolector,
          },
          ruta: {
            id: selectedRuta,
          },
        },
      });
      console.log(JSON.stringify(resp) + "data del recorrido creado");
      setRecorridoRuta(resp.data.id);
      return resp;
    } catch (error) {
      console.log(error);
    }
  };

  //useEffects de la aplicacion

  useEffect(() => {
    console.log("ingresando al useefect");
    getIdRecolector();
    // getRutas();
  }, []);

  useEffect(() => {
    console.log(
      "Cambio en el id del recolector.Procedemos a obtener las rutas"
    );
    if (idRecolector) {
      getRutas();
    }
  }, [idRecolector]);

  useEffect(() => {
    console.log(selectedRuta + "Este es el id ruta en el useeffect");
    const idRuta = selectedRuta;
    if (idRuta) {
      changeProductoresRutas(idRuta);
    }
  }, [selectedRuta, selectedProd]);

  useEffect(() => {
    if (selectedProd && selectedProd === -1) {
      getProductores(selectedRuta);
    }
  }, [selectedProd, selectedProdNombre]);

  //limpia los campos al crear una recoleccion exitosamente
  // ----------inicio del render----------------------------------------
  //_______________________________________________________________
  return (
    <section className="h-full">
      <h2 className="text-4xl py-4">Recolección de Rutas</h2>

      <div className="justify-items-center ">
        {!rutas ? (
          <p>No tiene ninguna ruta asociada. Consulte al administrador</p>
        ) : (
          rutas.map((ruta) => (
            <button
              onClick={(e) => {
                e.preventDefault();
                setSelectRuta(ruta.id);
              }}
              type="button"
              className={
                ruta.id === selectedRuta ? "btn btn-accent m-1" : "btn m-1"
              }
              key={ruta.id}
              value={ruta.nombre}
              disabled={btnsDisabled}
            >
              {ruta.nombre}
            </button>
          ))
        )}
      </div>
      {productores ? (
        productores.length > 0 ? (
          <>
            <div className="flex flex-col md:mx-60 px-10 my-1">
              <label htmlFor="productor" className="label">
                Productor
              </label>
              <select
                className="select select-bordered w-full max-w-xs"
                id="productor"
                defaultValue={""}
                onChange={(ev) => {
                  setSelectProd(ev.target.value);
                  setSelectProdNombre(ev.target.selectedOptions[0].text);
                }}
              >
                <option value={""}>Seleccione un Productor</option>
                {!productores ? (
                  <option value={"n/a"}>{"Cargando prods"}</option>
                ) : (
                  productores.map((productor) => (
                    <option key={productor.id} value={productor.id}>
                      {productor.nombre_corto}
                    </option>
                  ))
                )}
              </select>

              <label htmlFor="cantidad" className="label">
                Cantidad
              </label>
              <span className="  input-group ">
                <input
                  className="input input-bordered"
                  type="number"
                  id="cantidad"
                  min={0}
                  required
                  onChange={(e) => setCantidadLeche(e.target.value)}
                  value={cantidadLeche}
                />
                <span>Litros</span>
              </span>

              <label htmlFor="observaciones" className="label">
                Observaciones
              </label>
              <textarea
                className="input input-bordered max-w-xs "
                placeholder="Registrar anotaciones"
                id="observaciones"
                onChange={(e) => setObservaciones(e.target.value)}
                value={observaciones}
              />
            </div>
            {/* INACTIVADO TEMPORALMENTE */}

            {/* <div className="py-5" visible="false">
              <label htmlFor="modal-zero" className="btn btn-error">
                Cerrar recolección en ceros
              </label>
            </div> */}

            <div className="flex flex-col md:mx-60 px-10 my-1">
              <label
                htmlFor="modal-confirm"
                className="btn modal-button  my-2 "
              >
                Registrar Recolección
              </label>

              {/* Inicio componente de toma de pedidos */}
              {/* PEDIDOS.Suspendido hasta nuevo aviso */}

              <RecoleccionesCounter
                ruta={selectedRuta}
                cant={productores.length}
              ></RecoleccionesCounter>
              {selectedProd ? (
                <div className="collapse collapse-arrow ">
                  <input type={"checkbox"}></input>
                  <div className="collapse-title btn btn-ghost ">Pedidos</div>
                  <div className="collapse-content ">
                    <div className="flex flex-col ">
                      <CreatePedido
                        idProd={selectedProd}
                        prodNombre={selectedProdNombre}
                        idRecolector={idRecolector}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <div className="alert alert-success">
            No quedan productores pendientes en la ruta seleccionada
          </div>
        )
      ) : (
        <div className="alert alert-success">
          Para empezar, seleccione una ruta{" "}
        </div>
      )}

      <input type="checkbox" id="modal-confirm" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box ">
          <label
            htmlFor="modal-confirm"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <h3 className="text-lg font-bold">Aviso</h3>
          <p className="py-4">Desea guardar la información digitada?</p>
          <p className="">
            <span className="font-bold">Nombre del productor: </span>
            {selectedProdNombre}
          </p>
          <p className="">
            <span className="font-bold">Cantidad: </span>
            {cantidadLeche} litros
          </p>
          <p className="">
            <span className="font-bold">Observaciones: </span>
            {observaciones}
          </p>

          <div className="modal-action">
            <label
              htmlFor="modal-confirm"
              onClick={(e) => {
                postData();
                clearFields();
              }}
              className="btn btn-accent"
              type="submit"
              disabled={btnsDisabled}
            >
              Si
            </label>
            <label htmlFor="modal-confirm" className="btn">
              Aún no
            </label>
          </div>
          <div className="modal-action"></div>
        </div>
      </div>
      {/* Modal de recolecciones en ceros */}
      <input type={"checkbox"} id="modal-zero" className="modal-toggle"></input>
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Aviso!</h3>
          <p>
            Está a punto de poner las recolecciones de los productores restantes
            en ceros, ¿desea continuar?
          </p>
          <div className="modal-action">
            <label
              htmlFor="modal-zero"
              className="btn btn-error"
              onClick={() => postMassiveData()}
            >
              Si
            </label>
            <label htmlFor="modal-zero" className="btn">
              Aún no
            </label>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>
  );
}

export default CreateRecollectionSafe;
