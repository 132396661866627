import React from 'react'
import AbstractAdminVer from '../utils/AbstractAdminVer'

function VerBancos() {
  return (
    <div className="section h-full mx-2 my-5 md:w-10/12">
    <AbstractAdminVer
        objectName={{
          plural: "Bancos",
          singular: "banco",
          formal: "bancos",
          informal:"banco"
        }}
        itemName={"nombre_banco"}
        query={"bancosempresa"}
        configPath={"utils/configFiles/config.json"}
      ></AbstractAdminVer>{" "}
    </div>
  )
}

export default VerBancos