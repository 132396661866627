import React, { useEffect, useRef, useState } from "react";
import useAuth from "../hooks/useAuth";
import { getEmpresaAdmin } from "../api/RoleInfo";
import { makeSafeGetRequest } from "./utils/Requests";
import { useDownloadExcel } from "react-export-table-to-excel";

function ReportePedidosAcopio() {
  const { auth } = useAuth();
  const [pedidos, setPedidos] = useState();
  const [fechaInicial, setFechaInicial] = useState();
  const [fechaFinal, setFechaFinal] = useState();
  const [role, setRole] = useState();
  const [empresasList, setEmpresasList] = useState();
  const [idEmpresa, setIdEmpresa] = useState();
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [acopios, setAcopios] = useState();
  const [selAcopio, setSelAcopio] = useState();
  //userefs tablas
  const tableRef = useRef(null);
  //determina el rol al que pertenece el usuario
  const determineRole = () => {
    console.log("rol del usuario" + auth.roles[0]);
    setRole(auth.roles[0]);
  };
  //obtiene los datos de la empresa
  const getEmpresa = async () => {
    try {
      console.log("obtenmiuendo empresa");
      const response = await getEmpresaAdmin(auth);
      setIdEmpresa(response?.empresa?.id);
      setNombreEmpresa(response?.empresa.nombre);
    } catch (error) {}
  };
  //En caso de ser superadmin obtiene la lista de empresas
  const getEmpresasList = async () => {
    if (role === "superadmin") {
      try {
        const response = await makeSafeGetRequest("empresas", auth.accessToken);
        if (response && response.status === 200) {
          console.log(
            "empresas obtenidas" + JSON.stringify(response.data.data)
          );
          setEmpresasList(response?.data.data);
        } else {
          console.log("error al obtener las empresas");
          setEmpresasList();
        }
      } catch (error) {}
    }
  };
  //
  //obtiene lista de acopios
  const getAcopios = async () => {
    try {
      const response = await makeSafeGetRequest(
        "acopiosempresa/" + idEmpresa,
        auth.accessToken,
        idEmpresa
      );
      if (response && response.status === 200) {
        console.log("acopios obtenidos" + JSON.stringify(response.data));
        setAcopios(response?.data);
      } else {
        console.log("error al obtener acopios");
        setAcopios();
      }
    } catch (error) {
      console.log("error al obtwenr acopios");
    }
  };
  const onAcopioChange = async (e) => {
    console.log("cambio de acopio: " + e.target.value);
    setSelAcopio(e.target.value);
  };
  //obtiene la lista de pedidos del acopio
  const getPedidosAcopio = async () => {
    try {
      const response = await makeSafeGetRequest(
        "pedidosacopio?" +
          "acopio=" +
          selAcopio +
          "&" +
          "fechainicial=" +
          fechaInicial +
          "&fechafinal=" +
          fechaFinal,
        auth.accessToken,
        selAcopio
      );
      if (response && response.status === 200) {
        console.log("pedidos obtenidos" + JSON.stringify(response.data));
        setPedidos(response?.data);
      } else {
        console.log("error al obtener los pedidos");
        setPedidos();
      }
    } catch (error) {}
  };
  //realiza la descarga del elemento table
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename:
      "pedidos"  + "_" + fechaInicial + "_" + fechaFinal,
    sheet: "Pedidos",
  });
  //useeffect cambio de acopio
  useEffect(() => {
    console.log("cambio de acopio: " + selAcopio);
  }, [selAcopio]);
  //useeffect cambio de empresa
  useEffect(() => {
    console.log("cambio de empresa: " + idEmpresa);
    if (idEmpresa !== "0") {
      getAcopios();
    } else {
    }
  }, [idEmpresa]);
  //useeffect cambio en rol
  useEffect(() => {
    if (role === "administrador") {
      getEmpresa();
    } else if (role === "superadmin") {
      setIdEmpresa();

      getEmpresasList();
    }
  }, [role]);
  //useeffect inicial

  useEffect(() => {
    determineRole();
    console.log(role);
  }, []);
  return (
    <div className="mx-2 my-5 h-full w-full  md:w-10/12  ">
      <div className="grid grid-cols-1 mx-2 ">
        <h3 className="text text-2xl">Reporte de pedidos por Acopio </h3>
        {role === "administrador" ? (
          <label className="label">Empresa: {nombreEmpresa}</label>
        ) : role === "superadmin" && empresasList && empresasList.length > 0 ? (
          <div className="grid grid-cols-1">
            <label className="label">
              Para empezar, seleccione una empresa
            </label>
            <select
              className="select select-bordered"
              onChange={(e) => setIdEmpresa(e.target.value)}
            >
              <option key={0} value={0}>
                Seleccione una empresa
              </option>
              {empresasList.map((empresa) => (
                <option key={empresa?.id} value={empresa?.id}>
                  {empresa?.attributes?.nombre}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <></>
        )}
        {idEmpresa && idEmpresa > 0 ? (
          <div className="grid grid-cols-1">
            <label className="label">Seleccione un acopio</label>

            <select
              className="select select-bordered"
              onChange={(e) => {
                onAcopioChange(e);
              }}
            >
              <option key={0} value={0}>
                Seleccione un acopio
              </option>
              {acopios?.map((acopio) => (
                <option key={acopio.id} value={acopio.id}>
                  {acopio.nombre}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <></>
        )}
        {selAcopio && selAcopio !== -1 ? (
          <div className="grid grid-cols-1">
            <label className="label">Fecha inicial</label>
            <input
              type="date"
              className="input input-bordered"
              onChange={(e) => setFechaInicial(e.target.value)}
            ></input>
            <label className="label">Fecha final</label>
            <input
              type="date"
              className="input input-bordered"
              onChange={(e) => setFechaFinal(e.target.value)}
            ></input>
          </div>
        ) : (
          <></>
        )}
        {selAcopio && selAcopio !== -1 ? (
          <div className="grid grid-cols-1">
            <button
              className="btn btn-active"
              onClick={() => getPedidosAcopio()}
            >
              Generar reporte
            </button>
            {pedidos && pedidos.length > 0 ? (
              <div>
                <div className="overflow-x-auto">
                  <table className="table table-compact h-full" ref={tableRef}>
                    <thead>
                      <tr>
                        <th colSpan={3}>
                          Pedidos de acopio {pedidos[0].productor?.ruta?.nombre}
                        </th>
                      </tr>
                    </thead>
                    {pedidos.map((pedido) => (
                      <tbody>
                        <tr>
                          <th>Fecha: {pedido?.fecha}</th>
                          <th>Hora: {pedido?.hora.split(".")[0]}</th>
                          <th>Nombre: {pedido?.productor?.nombre_corto}</th>
                        </tr>
                        <tr>
                          <th>Acopio: {pedido?.acopio}</th>
                          <th>Ruta: {pedido?.productor?.ruta?.nombre}</th>
                          <th>Estado: {pedido?.estado_pedido}</th>
                        </tr>
                        <tr>
                          <th>Nombre</th>
                          <th>Cantidad</th>
                          <th>Observaciones</th>
                        </tr>
                        {pedido.productos_pedidos?.map((producto_pedido) => (
                          <tr key={producto_pedido.id}>
                            <td>
                              {producto_pedido?.producto_agricola?.nombre}
                            </td>
                            <td>{producto_pedido.cantidad}</td>
                            <td>{producto_pedido?.observaciones}</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={3}>
                            <span className="font-semibold">
                              Observaciones:{" "}
                            </span>
                            {pedido.observaciones}
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </div>
                <button className="btn btn-accent" onClick={onDownload}>
                  Exportar Excel
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default ReportePedidosAcopio;
