import React, { useState, useEffect, useRef  } from 'react';
import useAuth from '../../hooks/useAuth';
import { getEmpresaAdmin } from '../../api/RoleInfo';
import { makeSafeGetRequest, makeGetRequest } from './Requests';
import AlertPing from '../AlertPing';
const ValuesDeep = {
    "emmpresa": 0,
    "acopio": 1,
    "ruta": 2
}

const SelectorEmpresa = ({ onChangeSet, profundidad, valorSeleccion, setIdEmpresaPadre }) => {
    const { auth } = useAuth();
    const [idEmpresa, setIdEmpresa] = useState();
    const [role, setRole] = useState(auth?.roles[0]);
    const [nombreEmpresa, setNombreEmpresa] = useState();
    const [esSuperAdmin, setEsSuperAdmin] = useState(role === 'superadmin');
    const [empresasList, setEmpresasList] = useState([]);
    const [selAcopio, setSelAcopio] = useState();
    const [acopios, setAcopios] = useState();
    const [rutasAcopio, setRutasAcopio] = useState();
    const [selRuta, setSelRuta] = useState();
    const [valorProfundidad, setValorProfundidad] = useState(ValuesDeep[profundidad] ? ValuesDeep[profundidad] : 0);
    const selectorEmpresa = useRef(null);
    const selectorAcopio = useRef(null);
    const selectorRuta = useRef(null);

    useEffect(() => {
        if (esSuperAdmin) {
            getEmpresasList();
        } else {
            getEmpresa();
        }
    }, [role]);

    //obtiene la empresa en caso de ser admin
    const getEmpresa = async () => {
        const response = await getEmpresaAdmin(auth);
        setIdEmpresa(response?.empresa?.id);
        setNombreEmpresa(response?.empresa?.nombre);
    };
    //Obtiene la lista de empresas en caso de ser superadmin
    const getEmpresasList = async () => {
        try {
            const response = await makeSafeGetRequest("empresas", auth.accessToken);
            if (response && response.status === 200) {
                setEmpresasList(response?.data.data);
            }
        } catch (error) {
            console.log("error al obtener la lista de empresas" + error);
        }
    };
    const handleChange = (event) => {
        const selectedId = event.target.value;
        setIdEmpresa(selectedId);
    };

    const getAcopios = async () => {
        try {
            const response = await makeGetRequest("acopiosempresa/" + idEmpresa);
            if (response) {
                setAcopios(response.data);
            }
        } catch (error) {
            console.log("Error al obtener los acopios de la empresa: " * error);
        }
    };

    const onSelectedAcopio = (e) => {
        setSelRuta(null);
        setSelAcopio(e.target.value);
        getRutasAcopio(e.target.value);
    };

    const getRutasAcopio = async (idAcopio) => {
        try {
            const response = await makeGetRequest("rutasacopio/" + idAcopio);
            if (response) {
                setRutasAcopio(response.data);
            } else {
                setRutasAcopio();
            }
        } catch (error) {
            console.log("Error al obtener las rutas del acopio: " + error);
        }
    };

    const onSelectedRuta = (e) => {
        setSelRuta(e.target.value);
        if (valorProfundidad == 1) {
            onChangeSet(selAcopio);
        }
    };
    useEffect(() => {
        if (valorProfundidad >= 0 && idEmpresa) {
            if(valorProfundidad == 0) {
                onChangeSet(idEmpresa);
            }else {
                getAcopios();
                if(setIdEmpresaPadre) {
                    setIdEmpresaPadre(idEmpresa);
                }
            }
        }
    }, [idEmpresa]);

    useEffect(() => {
        if (valorProfundidad == 1) {
            onChangeSet(selAcopio);
        }
    }, [selAcopio]);

    useEffect(() => {
        if (valorProfundidad == 2) {
            onChangeSet(selRuta);
        }
    }, [selRuta]);

    useEffect(() => {
        if (valorSeleccion == null || "") {
            switch (valorProfundidad) {
                case 0:
                    if(selectorEmpresa.current) {
                        setIdEmpresa(null);
                        selectorEmpresa.current.value = "";
                    }

                    if(selectorAcopio.current) {
                        setSelAcopio(null);
                        selectorAcopio.current.value = "";
                    }
                    if(selectorRuta.current) {
                        setSelRuta(null);
                        selectorRuta.current.value = "";
                    }
                    break;
                case 1:
                    if(selectorAcopio.current) {
                        setSelAcopio(null);
                        selectorAcopio.current.value = "";
                    }
                    if(selectorRuta.current) {
                        setSelRuta(null);
                        selectorRuta.current.value = "";
                    }
                    
                    break;
                case 2:
                    if(selectorRuta.current) {
                        setSelRuta(null);
                        selectorRuta.current.value = "";
                    }
                default:
                    break;
            }
        }
    }, [valorSeleccion])
    return (
        <div className="grid grid-cols-1">
            {esSuperAdmin && valorProfundidad >= 0 ? (
                <>
                    {!idEmpresa &&
                        (
                            <AlertPing
                                message="Para empezar, seleccione una empresa"
                                type="info"
                            ></AlertPing>
                        )
                    }
                    <label className='label' htmlFor="empresa-selector">Empresa:</label>
                    <select
                        ref={selectorEmpresa}
                        className="select select-bordered"
                        onChange={(e) => handleChange(e)}
                    >
                        <option key="default" value="">
                            Seleccione una empresa
                        </option>
                        {empresasList?.map((empresa) => (
                            <option key={empresa?.id} value={empresa?.id}>
                                {empresa?.attributes?.nombre}
                            </option>
                        ))}
                    </select>

                </>
            ) : (
                <>
                    <div className="p-3 bg-blue-100 text-gray-600  text-justify shadow-inner">
                        <span className="text-md font-medium">Empresa: <span className="font-bold text-gray-900"> {nombreEmpresa}</span></span>
                    </div>
                </>
            )
            }

            {idEmpresa && valorProfundidad >= 1 &&
                (
                    <>
                        <label className="label">Acopio: </label>
                        <select
                            ref={selectorAcopio}
                            className="select select-bordered"
                            onChange={(e) => {
                                onSelectedAcopio(e);
                            }}
                        >
                            <option key={"-1"} value={""}>
                                Seleccione un centro de acopio
                            </option>
                            {acopios?.map((acopio) => (
                                <option key={acopio.id} value={acopio.id}>
                                    {acopio?.nombre}
                                </option>
                            ))}
                        </select>
                    </>
                )
            }
            {selAcopio && valorProfundidad >= 2 &&
                (
                    <>
                        <label className="label">Ruta: </label>
                        <select
                            ref={selectorRuta}
                            className="select select-bordered"
                            onChange={(e) => onSelectedRuta(e)}
                        >
                            <option key={"-1"} value={""}>
                                Seleccione una ruta
                            </option>
                            {rutasAcopio?.map((ruta) => (
                                <option key={ruta.id} value={ruta.id}>
                                    {ruta?.nombre}
                                </option>
                            ))}
                        </select>
                    </>
                )
            }
        </div>
    );
};

export default SelectorEmpresa;