import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { getEmpresaAdmin } from "../api/RoleInfo";
import useAuth from "../hooks/useAuth";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import {
  makeGetRequest,
  makeSafePostRequest,
  makeSafePutRequest,
  makeSafeGetRequest
} from "./utils/Requests";
import { dateToColombianZoneRq, timeToColombianZonerq } from "./utils/timezoneRequests";
import AlertPing from "./AlertPing";
import InputNumeroEntero from "./utils/InputNumeroEntero";
import SelectorEmpresa from "./utils/SelectorEmpresa";

function EditRecolecciones() {
  const { auth } = useAuth();
  const [selRuta, setSelRuta] = useState();
  const [productoresRuta, setProductoresRuta] = useState();
  const [selProductor, setSelProductor] = useState();
  const [selProductorNombre, setSelProductorNombre] = useState();
  const [recoleccion, setRecoleccion] = useState();
  //States valores recoleccion
  const [editedCantidad, setEditedCantidad] = useState(0);
  const [editedObs, setEditedObs] = useState("");

  
  
  //obtiene los productores de la ruta seleccionada
  const getProductores = async (idRuta) => {
    setRecoleccion(null);
    setSelProductor(null);
    try {
      const response = await makeGetRequest("productoresruta/" + idRuta);
      if (response) {
        setProductoresRuta(response.data);
      } else {
        setProductoresRuta();
      }
    } catch (error) {
      console.log("Error al obtener productores: " + error);
    }
  };
  //Obtiene la recoleccion del productor seleccionado en el option
  const getRecoleccionProductor = async (idProductor) => {
    setRecoleccion(null);
    try {
      const response = await makeGetRequest(
        "recoleccionproductorhoy/" + idProductor
      );
      if (response && response.data?.length > 0) {
        setRecoleccion(response.data[0]);
        setEditedCantidad(response.data[0].cantidad_leche);
        setEditedObs(response.data[0].observaciones);
      } else {
        setRecoleccion();
        setEditedCantidad();
        setEditedObs();
      }
    } catch (error) {
      console.log(error);
    }
  };
  //realiza tanto la actualizacion como la generacion del log
  const makeUpdateTransaction = async () => {
    try {
      await updateRecoleccion();
      await postRecoleccionLog();
      setEditedCantidad(0);
      setEditedObs("");
    } catch (error) {
      console.log("Error al realizar transaccion de actualizacion" + error)
    }
  }
  //Actualiza la recolección
  const updateRecoleccion = async () => {
    const notify = notifyPendingNotify();
    try {
      const data = {
        data: { cantidad_leche: editedCantidad, observaciones: editedObs },
      };
      const response = await makeSafePutRequest(
        "recolecciones/" + recoleccion?.id,
        data,
        auth.accessToken
      );
      if (response && response?.status == 200) {
        popNotification(notify, "success", "Recolección editada con éxito");
      } else {
        popNotification(notify, "error", "No se pudo editar la recolección");
      }
    } catch (error) {
      console.log(error);
      popNotification(notify, "error", "No se pudo editar la recolección");
    }
  };
  //guarda el valor actual y el anterior en la recoleccion
  const postRecoleccionLog = async () => {
    try {
      const hora = timeToColombianZonerq();
      const fecha = dateToColombianZoneRq();

      const data = {
        data: {
          cantidad_original: recoleccion.cantidad_leche,
          cantidad_editada: editedCantidad,
          observaciones_original: recoleccion?.observaciones,
          observaciones_editado: editedObs,
          recoleccion: { id: recoleccion.id },

        },
      };
      const response = makeSafePostRequest("recoleccion-logs", data, auth.accessToken);
    } catch (error) {
      console.log("Error al postear el log de recoleccion" + error)
    }
  };
  
  //cambio al obtener el acopio seleccionado
  const onSelectedProductor = (e) => {
    setSelProductor(e.target.value);
    getRecoleccionProductor(e.target.value);
  };

  useEffect(() => {
    if (selRuta) {
        getProductores(selRuta);
    }
}, [selRuta]);
  return (
    <div className="section w-2/3 h-full">
      <div className="max-w-full p-4">
        <div className="card w-full bg-white shadow-2xl rounded-lg">
          <div className="card-title border-b border-gray-300 p-3 rounded-t-lg">
            <i className="fa fa-cubes text-gray-600 mr-2"></i>
            <h2 className="text-lg font-semibold text-gray-800 text-center"> Edición de recolecciones</h2>
          </div>
          <div className="p-2">
            <div className="grid grid-cols-1 mx-2 my-5">
              <SelectorEmpresa
                onChangeSet={setSelRuta}
                profundidad={"ruta"}
              />
              {selRuta &&
                (
                  <>
                    <label className="label">Productor</label>
                    <select
                      className="select select-bordered"
                      onChange={(e) => onSelectedProductor(e)}
                    >
                      <option key={"-1"} value={""}>
                        Seleccione un productor
                      </option>
                      {productoresRuta?.map((prod) => (
                        <option key={prod.id} value={prod.id}>
                          {prod?.nombre_corto}
                        </option>
                      ))}
                    </select>
                  </>
                )
              }
              <div className="divider mb-0"> </div>
              {selProductor &&
                (
                  <>
                    {recoleccion ?
                      (
                        <div className="grid grid-cols-1">
                          <label className="text-xl font-bold text-justify mb-1">Datos de la recolección:</label>
                          <div className="p-3 bg-blue-100 text-gray-600  text-justify shadow-inner">
                            <span className="text-md font-medium">Nombre del productor: <span className="font-bold text-gray-900"> {recoleccion?.productor?.nombre_corto}</span></span>
                          </div>
                          <InputNumeroEntero
                            label={"Cantidad de leche: "}
                            labelSpanFinal={"Litros"}
                            onValorChange={setEditedCantidad}
                            valorInicial={editedCantidad}
                          />
                          <label className="label">Observaciones</label>
                          <input
                            className="input input-bordered"
                            type={"text"}
                            value={editedObs}
                            onChange={(e) => {
                              setEditedObs(e.target.value);
                            }}
                          ></input>
                          <label className="btn btn-accent my-3" htmlFor="modal-edit">
                            Editar Recolección
                          </label>
                        </div>
                      )
                      :
                      (
                        <AlertPing
                          message="No se encontraron recolecciones para el productor seleccionado"
                          type="warning"
                        ></AlertPing>
                      )
                    }
                  </>
                )
              }
          </div>
        </div>
      </div>
      {/* Modal editar recolecciones */}
      <input type="checkbox" id="modal-edit" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box relative">
          <label
            htmlFor="modal-edit"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <h3 className="text-lg font-bold">Aviso!</h3>
          <p className="py-4">
            Realmente desea editar la siguiente recolección?
          </p>
          <p>
            <span className="font-bold">Productor: </span>
            {recoleccion?.productor.nombre_corto}
          </p>
          <p>
            <span className="font-bold">Cantidad: </span>
            {editedCantidad + " litros"}
          </p>
          <p>
            <span className="font-bold">Observaciones: </span>
            {editedObs}
          </p>
          <div className="modal-action">
            <label
              htmlFor="modal-edit"
              onClick={makeUpdateTransaction}
              className="btn btn-accent"
            >
              Sí
            </label>
            <label htmlFor="modal-edit" className="btn ">
              Aún no
            </label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
    </div >
  );
}

export default EditRecolecciones;
