import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";

import useAuth from "../hooks/useAuth";
import { makeSafeGetRequest } from "./utils/Requests";
import { getAcopiosEmpresa } from "./utils/getAcopiosinfo";
import { getRutasAcopio } from "./utils/getRutasInfo";

function ReporteIngresosFecha(props) {
  const { auth } = useAuth();
  const { empresa } = props;
  const [acopios, setAcopios] = useState();
  const [selAcopio, setSelAcopio] = useState();
  const [rutas, setRutas] = useState();
  const [selectedRuta, setSelectedRuta] = useState();
  const [selectedRutaNombre, setSelectedRutaNombre] = useState();
  const [fechaInicial, setFechaInicial] = useState();
  const [fechaFinal, setFechaFinal] = useState();
  const [ingresos, setIngresos] = useState();
  //useRef tabla
  const tableRef = useRef(null);
  //obtiene los acopios de la empresa
  const getAcopios = async () => {
    console.log("OBTENIENDO acos");
    const aco = await getAcopiosEmpresa(empresa, auth.accessToken);
    setAcopios(aco);
  };
  //obtiene las rutas de la empresa, por ahora agronit
  const getRutas = async () => {
    const rutas = await getRutasAcopio(selAcopio, auth.accessToken);
    setRutas(rutas);
  };
  //obtiene los ingresos de una ruta en un rango de fechas
  const getIngresosFechaRuta = async () => {
    try {
      console.log("Ingresando al try");
      const myUrl =
        "ingresosporfecha?idruta=" +
        selectedRuta +
        "&fechainicial=" +
        fechaInicial +
        "&fechafinal=" +
        fechaFinal;
      const response = await makeSafeGetRequest(myUrl, auth.accessToken);

      if (response.status === 200) {
        if (response.data.length > 0) {
          console.log(
            "Ingresos en rango de fechas" + JSON.stringify(response.data)
          );
          setIngresos(response.data);
        } else {
          setIngresos(undefined);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  //Cambio en ruta seleccionada
  const onRutaChange = (e) => {
    setSelectedRuta(e.target.value);
    setSelectedRutaNombre(e.target.selectedOptions[0].text);
  };
  //use effect para traer las rutas
  useEffect(() => {
    if (selAcopio) {
      getRutas();
    }
  }, [selAcopio]);
  //Effect al iniciar
  useEffect(() => {
    if (empresa) {
      console.log("REPORTE INGRESOS===>");
      getAcopios();
    }
  }, []);
  //Effect al iniciar
  useEffect(() => {
    if (empresa) {
      console.log("REPORTE INGRESOS===>");
      getAcopios();
    }
  }, [empresa]);
 
  return (
    <div className="flex flex-col">
      <label className="label" htmlFor="rutas">
        Seleccione un acopio
      </label>
      <select
        className="input input-bordered"
        id="rutas"
        onChange={(e) => setSelAcopio(e.target.value)}
      >
        <option value={""}>Seleccione un acopio</option>
        {!acopios ? (
          <option value={-1}>Cargando acopios</option>
        ) : (
          acopios?.map((acopio) => (
            <option value={acopio.id} key={acopio.id}>
              {acopio.nombre}
            </option>
          ))
        )}
      </select>
      <label className="label" htmlFor="rutas">
        Seleccione una ruta
      </label>
      <select
        className="input input-bordered"
        id="rutas"
        onChange={(e) => onRutaChange(e)}
      >
        <option value={""}>Seleccione una ruta</option>
        {!rutas ? (
          <option value={-1}>Cargando rutas</option>
        ) : (
          rutas?.map((ruta) => (
            <option value={ruta.id} key={ruta.id}>
              {ruta.nombre}
            </option>
          ))
        )}
      </select>
      <label className="label" htmlFor="fechainicial">
        Fecha Inicial
      </label>
      <input
        type={"date"}
        className={"input input-bordered"}
        id="fechainicial"
        onChange={(e) => {
          setFechaInicial(e.target.value);
        }}
      ></input>
      <label className="label" htmlFor="fechafinal  ">
        Fecha final
      </label>
      <input
        type={"date"}
        className={"input input-bordered"}
        id="fechafinal"
        onChange={(e) => {
          setFechaFinal(e.target.value);
        }}
      ></input>
      <button
        className="btn btn-accent my-2"
        onClick={(e) => {
          getIngresosFechaRuta();
          console.log("probando boton");
        }}
      >
        Generar reporte
      </button>
      <div className="overflow-x-auto">
        <table className="table table-compact" ref={tableRef}>
          {!ingresos ? (
            <tbody>
              <tr>
                <td>No hay Ingresos en el rango de fecha seleccionado</td>
              </tr>
            </tbody>
          ) : (
            <>
              <thead>
                <tr>
                  <th></th>
                  <th colSpan={6}>
                    Ruta:{ingresos[0]?.recorrido?.ruta?.nombre}
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th>FECHA</th>
                  <th>LITROS ACOPIO</th>
                  <th>LITROS NOVEDAD</th>
                  <th>LITROS RUTA</th>
                  <th>DIFERENCIA</th>
                  <th>OBSERVACIONES</th>
                </tr>
              </thead>
              <tbody>
                {ingresos?.map((ingreso, index) => (
                  <tr key={ingreso.id}>
                    <th>{index + 1}</th>
                    <td>{ingreso.fecha.split("T", [1])}</td>
                    <td>{ingreso.cantidad_medida}</td>
                    <td>{ingreso?.cantidad_rechazada}</td>
                    <td>{ingreso?.cantidad_leche}</td>                    
                    <td>
                      {ingreso.diferencia >= 0 ? (
                        <span className="text-success">
                          {ingreso.diferencia}
                        </span>
                      ) : (
                        <span className="text-error">{ingreso.diferencia}</span>
                      )}
                    </td>
                    <td>{ingreso?.observaciones}</td>
                  </tr>
                ))}
                <tr>
                  <th></th>
                  <th>Totales: </th>
                  <td>
                    {ingresos?.reduce(
                      (c, ingreso) => c + ingreso?.cantidad_medida,
                      0
                    ) + " litros"}
                  </td>

                  <td>
                    {ingresos?.reduce(
                      (c, ingreso) => c + ingreso?.cantidad_rechazada,
                      0
                    ) + " litros"}
                  </td>
                  <td>
                    {ingresos?.reduce(
                      (c, ingreso) => c + ingreso?.cantidad_leche,
                      0
                    ) + " litros"}
                  </td>
                  <td colSpan={2}>
                    {ingresos?.reduce(
                      (c, ingreso) => c + ingreso?.diferencia,
                      0
                    ) + " litros"}
                  </td>
                </tr>
              </tbody>
            </>
          )}
        </table>
      </div>
      {ingresos ? (
        <DownloadTableExcel
          filename={
            "ingresos_" +
            ingresos[0]?.recorrido?.ruta?.nombre +
            fechaInicial +
            "_" +
            fechaFinal
          }
          sheet="ingreso por dias"
          currentTableRef={tableRef.current}
        >
          <button className="btn btn-active">Exportar excel</button>
        </DownloadTableExcel>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ReporteIngresosFecha;
