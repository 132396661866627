import React from "react";
import AbstractAdminVer from "../utils/AbstractAdminVer";
function VerClientes() {
  return (
    <div className="section h-full mx-2 my-5 md:w-10/12">
      <AbstractAdminVer
        objectName={{
          plural: "clientes",
          singular: "cliente",
          formal: "clientes",
          informal:"Cliente"
        }}
        itemName={"nombre"}
        query={"clientesempresa"}
        configPath={"utils/configFiles/config.json"}
      ></AbstractAdminVer>{" "}
    </div>
  );
}

export default VerClientes;
