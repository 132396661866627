import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";

import useAuth from "../hooks/useAuth";
import { makeGetRequest } from "./utils/Requests";


function ReporteLitrosPorRutas() {
  const URL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { auth } = useAuth();
  //useRef tabla
  const tableRef = useRef(null);
  //constante centro de acopio
  const [acopios, setAcopios] = useState();
  const [selectedAcopio, setSelectedAcopio] = useState();

  const [ingresos, setIngresos] = useState();
  const [fechaInicial, setFechaInicial] = useState();
  const [fechaFinal, setFechaFinal] = useState();

  //obtiene los ingresos por ruta hechos al centro de acopio en un rango de fechas
  const getIngresos = async () => {
    try {
      const url = "ingresosacopiorutas?idacopio=" + selectedAcopio + "&fechainicial=" + fechaInicial + "&fechafinal=" + fechaFinal;
      const response = await makeGetRequest(url);
      setIngresos(response.data);
    } catch (error) {
      console.log("Error al traer ingresos: " + error)
    }
  };


  //obtiene la lista de los centros de acopio de la empresa
  const getAcopiosEmpresa = async () => {
    try {
      const url = "acopiosempresa/1";
      const response = await makeGetRequest(url);
      if (response?.status === 200) {
        setAcopios(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //maneja el cambio en fecha Inicial
  const onFechaInicialChange = (date) => {
    setFechaInicial(date);
  };
  //maneja el cambio en fecha Final
  const onFechaFinalChange = (date) => {
    setFechaFinal(date);
  };
  //______________________________________________________________________________
  //_____________EFFECTS DEL RENDER____________________
  //useeffect para actualizar setIngresos
  
  useEffect(() => {
    getAcopiosEmpresa();
  }, []);

  return (
    <div>
      <label htmlFor="acopio" className="label">
        Seleccione un centro de Acopio
      </label>
      <select
        id="acopio"
        className="select select-bordered"
        onChange={(e) => {
          setSelectedAcopio(e.target.value);
        }}
      >
        <option key={-1} value={-1}>
          Seleccione un centro de Acopio
        </option>
        {!acopios ? (
          <option key={""}>Cargando centros de acopio</option>
        ) : (
          acopios.map((acopio) => (
            <option key={acopio?.id} value={acopio?.id}>
              {acopio.nombre}
            </option>
          ))
        )}
      </select>
      <label>acopio seleccionado {selectedAcopio}</label>
      <label className="label" htmlFor="fechaInicial">
        Fecha inicial
      </label>
      <input
        type={"date"}
        className="input input-bordered"
        onChange={(e) => onFechaInicialChange(e.target.value)}
      ></input>
      <label className="label" htmlFor="fechaFinal">
        Fecha final
      </label>
      <input
        type={"date"}
        className="input input-bordered"
        onChange={(e) => onFechaFinalChange(e.target.value)}
      ></input>
      <button
        className="btn btn-accent my-2"
        onClick={() => {
          getIngresos();
        }}
      >
        Generar reporte
      </button>
      {!ingresos ? (
        <div className="alert alert-success">
          No hay ingresos en el rango de fechas indicado
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="table table-compact" ref={tableRef}>
            <thead>
              <tr key={-1}>
                <th>RUTA \ FECHA</th>
                {ingresos[0]?.ingresos?.map((i) => (
                  <th key={i?.id}>{i.fecha}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {
                ingresos?.map((ing) => (
                  <tr key={ing.id}>
                    <td>{ing.nombre}</td>
                    {ing?.ingresos?.map((i) => (
                      <td key={i.id}>{i?.cantidad}</td>
                    ))}
                  </tr>
                ))
              }
            </tbody>
          </table>
          {ingresos ? (<DownloadTableExcel
            filename={"Ingresos" + acopios[selectedAcopio]?.nombre + fechaInicial + "_" + fechaFinal}
            sheet={"Ingreso días rutas"}
            currentTableRef={tableRef.current}
          >
            <button className="btn btn-active">Exportar excel</button>
          </DownloadTableExcel>) : (<></>)}
        </div>
      )}
    </div>
  );
}

export default ReporteLitrosPorRutas;
